import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import Footer from "../../components/footer/Footer";
import { Result as Results, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import history from "../../utils/history";

const Result = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Results
        status="success"
        title={location && location?.state?.message}
        subTitle="We are ready to serve you again in future!"
        extra={[
          <Link to={"/"}>
            <Button
              type="primary"
              key="console"
              style={{ background: "#40b566", border: "none" }}
            >
              Go Home
            </Button>
          </Link>,
          <Button
            key="buy"
            onClick={() => {
              history.go(-1);
            }}
          >
            Buy Again
          </Button>,
        ]}
      />
      <FeatureContainer />

      <Footer />
    </div>
  );
};

export default Result;
