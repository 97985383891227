import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import ReactStars from "react-rating-stars-component";
import AOS from "aos";
import "aos/dist/aos.css";
import Spinner2 from "../spinner/Spinner2";
import Moment from "react-moment";
import { Pagination, Badge } from "antd";
import { stringTrim } from "../../utils/stringTrim";
import NewProductSkeleton from "../skeleton/newProductSkeleton";
import { useDispatch, connect } from "react-redux";

const mapStateToProps = (store) => {
  const { recentProduct, isRecentProductLoading, totalRecentPage } =
    store.dashboardProduct;
  return { recentProduct, isRecentProductLoading, totalRecentPage };
};

const RecentProducts = ({
  recentProduct,
  isRecentProductLoading,
  totalRecentPage,
}) => {
  const [visible, setVisible] = useState(4);
  const [buttonToggle, setButtonToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(totalRecentPage);
  const dispatch = useDispatch();

  const handleSetProductStatus = () => {
    localStorage.setItem("productStatus", "normalProduct");
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_RECENT_PRODUCT_REQUEST",
      payload: { page: currentPage },
    });
  }, [currentPage]);

  useEffect(() => {}, [recentProduct]);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //   });
  //   const getFeaturedProducts = async () => {
  //     const response = await axios
  //       .get(
  //         `https://classibazaar.com.au/api/deal/products?page=${currentPage}`
  //       )
  //       .catch((err) => {
  //         "";
  //       });
  //     setTotalPages(response?.data?.total_pages);
  //     setPopularProducts(response?.data?.products);
  //   };
  //   getFeaturedProducts();
  // }, [currentPage]);

  if (isRecentProductLoading) {
    return (
      <div className="product--container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "100%",
            margin: "0 1.5rem",
          }}
        >
          <div className="product--load--header">
            <span>Recent Products</span>
          </div>
          <NewProductSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      {recentProduct?.length > 0 ? (
        <>
          <div className="product--container">
            <div className="product--header">
              <span>Recent Products</span>
            </div>
          </div>
          <div className="product--container">
            <div className="popular--products">
              {recentProduct?.map((product) => (
                <div
                  className="mini--product--cart"
                  key={product.id}
                  // data-aos="fade-up"
                  onClick={handleSetProductStatus}
                >
                  <Link
                    to={`/details/${product.deal_slug}/${product.rating}`}
                    className="link"
                  >
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <p
                        className="discount"
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          left: 0,
                          zIndex: 2,
                        }}
                      >
                        {product.discount}% Off
                      </p>
                      <div className="product--badge">
                        {product.is_featured === "1" && (
                          <Badge.Ribbon
                            color={"Blue"}
                            text="Featured"
                          ></Badge.Ribbon>
                        )}
                        {product.is_popular === "1" && (
                          <Badge.Ribbon
                            text="Popular"
                            color={"#ff1493"}
                          ></Badge.Ribbon>
                        )}
                        {product.pre_order === "Yes" && (
                          <Badge.Ribbon
                            text="Pre-order"
                            color={"red"}
                          ></Badge.Ribbon>
                        )}
                      </div>

                      <div className="image--animate">
                        <img src={product?.image?.thumbnail} alt="" />
                      </div>
                      <div className="container-below">
                        <div className="product--cart--details">
                          <div className="product--description">
                            {product?.dealstitle &&
                              stringTrim(product.dealstitle, 35)}
                            {product?.dealstitle?.length > 50 ? "..." : ""}
                          </div>
                          <div className="product--price">
                            <span style={{ flex: 1 }}>
                              <span className="base--offer--price">
                                ${product?.actual_price}
                              </span>{" "}
                              {"-"}{" "}
                              <span className="base--product--price">
                                ${product?.product_price}
                              </span>
                            </span>
                            <ReactStars
                              count={product?.rating}
                              color={"#ffd700"}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                          <div className="product--desc">
                            {product?.subtitle &&
                              stringTrim(product?.subtitle, 50)}
                            {product?.subtitle?.length > 50 ? "..." : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="product--container">
            <div className="pagination--icons--container">
              <div className="btns" style={{ marginTop: "1rem" }}>
                <Pagination
                  style={{ marginRight: "-2.5rem" }}
                  current={currentPage}
                  defaultCurrent={currentPage}
                  total={total_pages * 10}
                  onChange={(e) => setCurrentPage(e)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(mapStateToProps)(RecentProducts);
