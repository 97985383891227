import React, { useEffect } from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Spinner.css";

import { ThreeDots } from "react-loader-spinner";

const Spinner2 = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={props.navbar ? "loading another" : "loading"}>
      Loading&#8230;
    </div>
  );
};

export default Spinner2;
