import React from "react";
import "./Feature.css";

const Feature = ({ title, subtitle, imageLink }) => {
  return (
    // <div className="fcontainer">
    //   <img src={imageLink} alt="" />
    //   <div className="second-container" style={{ width: "100%" }}>
    //     <span>{title}</span>
    //     <p>{subtitle}</p>
    //   </div>
    // </div>
    <div className="feature--container">
      <div className="feature--header">
        <img className="feature--image" src={imageLink} alt="" />
        <span>{title}</span>
      </div>
      <div className="feature--body">{subtitle}</div>
    </div>
  );
};

export default Feature;
