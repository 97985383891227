import { toast } from "react-toastify";
import { setLocalStorage } from "../../FrontendHelper";
import history from "../../utils/history";

const initialState = {
  response: {},
  isLoading: false,
  error: {},
  giftId: "",
  promoPayload: {},
  isGiftSendSuccess: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SEND_GIFT_DATA_SEND_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEND_GIFT_DATA_SEND_SUCCESS":
      toast.success(payload.data.message, { toastId: 26 });
      setLocalStorage("giftId", payload.data.giftid); 

      setTimeout(() => {
        if (payload.variant_id) {
          history.push(
            `/payment/${payload.id}/${payload.PRODUCT_ID}/${payload.variant_id}`
          );
        } else {
          history.push(`/payment/${payload.id}/${payload.PRODUCT_ID}`);
        }
      }, [2000]);

      return {
        ...state,
        isGiftSendSuccess: true,
        isLoading: false,
        response: payload.data,
        giftId: payload.data.giftid,
      };

    case "SEND_GIFT_DATA_SEND_FAILURE":
      toast.error(payload.data.message, { toastId: 27 });
      return {
        ...state,
        isLoading: false,
        isGiftSendSuccess: false,
        giftId: "",
      };
    case "APPLY_PROMO_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "APPLY_PROMO_SUCCESS":
      toast.success(payload.message, { toastId: 28 });
      return {
        ...state,
        isLoading: false,
        promoPayload: payload.payload,
      };
    case "APPLY_PROMO_FAILURE":
      toast.error(payload.data.message, { toastId: 1111111111111111 });
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_PROMO":
      return {
        ...state,
        promoPayload: {},
      };
    default:
      return state;
  }
}
