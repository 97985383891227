import { toast } from "react-toastify";
import history from "../../utils/history";
import { setLocalStorage } from "../../FrontendHelper";
const initialState = {
  isLoading: false,
  verificationCode: null,
  isVerified: false,
  isPasswordChanged: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SEND_CODE_TO_EMAIL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEND_CODE_TO_EMAIL_SUCCESS":
      setLocalStorage("verifyEmail", payload.email);
      if (payload.data.message === "Validation Failed") {
        return toast.error(payload.data.message, { toastId: 11 });
      }

      toast.success(payload.data.message, { toastId: 2 });
      setTimeout(() => {
        history.push("/verify-code");
      }, [2000]);

      return {
        ...state,
        isLoading: false,
        verificationCode: payload.data.pincode,
      };
    case "SEND_CODE_TO_EMAIL_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "VERIFY_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "VERIFY_USER_SUCCESS":
      toast.success(payload.data.message + "Please Change Your Password", {
        toastId: 1,
      });
      setLocalStorage("verifyCode", payload.code);
      setTimeout(() => {
        history.push("/change-password");
      }, [2000]);
      return {
        ...state,
        isLoading: false,
        isVerified: true,
      };

    case "VERIFY_USER_FAILURE":
      toast.error(payload.data.message, { toastId: 14789 });
      return {
        ...state,
        isLoading: false,
        isVerified: false,
      };

    case "CHANGE_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CHANGE_PASSWORD_SUCCESS":
      toast.success(payload.message, { toastId: 3 });
      localStorage.removeItem("verifyEmail");
      localStorage.removeItem("verifyCode");
      setTimeout(() => {
        window.location.replace("/login");
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "CHANGE_PASSWORD_FAILURE":
      toast.error(payload.data.message, { toastId: 14 });
      return {
        ...state,
        isLoading: false,
        isPasswordChanged: false,
      };
    default:
      return state;
  }
}
