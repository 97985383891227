import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import Spinner2 from "../../components/spinner/Spinner2";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Drawer } from "antd";
import axios from "axios";
import { Descriptions, Badge } from "antd";
import { useNavigate } from "react-router-dom";

const PreOrders = ({ profile }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
  const [currentPurchase, setCurrentPurchase] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [deal, setDeal] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_MY_PREORDER_REQUEST",
      payload: { page: currentPage },
    });
    window.scrollTo(0, 0);
    const getProductDetails = async () => {
      const response = await axios
        .get(
          // `https://classibazaar.com.au/api/deal/detail/${currentPurchase.deal_slug}`
          `/deal/detail/${currentPurchase.deal_slug}`
        )
        .catch((err) => {
          "";
        });

      setDeal(response.data.deal);
    };

    getProductDetails();
  }, [currentPage, currentPurchase.deal_slug]);
  const viewHandler = (purchase) => {
    setIsOrderDetailsOpen(true);
    setCurrentPurchase(purchase);
    setTimeout(() => {
      setVisible(true);
    }, [500]);
  };

  const onClose = () => {
    setVisible(false);
  };
  const navigateHandler = (deal_slug, rating) => {
    navigate(`/details/${deal_slug}/${rating}`);
  };

  if (profile.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "-webkit-fill-available",
        }}
      >
        <Spinner />;
      </div>
    );
  }

  return (
    <div className="purchase-container">
      <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>My Pre-Orders</h1>
      <Drawer
        title="Pre order details"
        placement={"right"}
        width={"100vw"}
        closable={true}
        onClose={onClose}
        visible={visible}
        height={"100vh"}
        key={"top"}
      >
        <div
          className="order-detail-container"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            padding: "20px",
            background: "white",

            margin: "auto",
          }}
        >
          <Descriptions
            style={{ marginTop: "1rem" }}
            layout="vertical"
            bordered
          >
            <Descriptions.Item label="Product Name" span={1}>
              {currentPurchase && currentPurchase.product_name}
            </Descriptions.Item>
            <Descriptions.Item label="Company Name" span={1}>
              {currentPurchase && currentPurchase.companyName}
            </Descriptions.Item>
            <Descriptions.Item label="Deal Type" span={1}>
              {currentPurchase && currentPurchase.deal_type}
            </Descriptions.Item>
            <Descriptions.Item label="Transaction Id" span={1}>
              {currentPurchase && currentPurchase.transaction_id}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Method">
              {currentPurchase && currentPurchase.payment_method}
            </Descriptions.Item>
            <Descriptions.Item label="Paid Amount">
              $
              {currentPurchase &&
                parseFloat(currentPurchase.paid_amount).toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Due Amount">
              {" "}
              $
              {currentPurchase &&
                parseFloat(currentPurchase.due_amount).toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Ends On" span={2}>
              {currentPurchase && currentPurchase.endDate}
            </Descriptions.Item>
            {deal && (
              <>
                <Descriptions.Item label="Highlights" span={2}>
                  <div
                    dangerouslySetInnerHTML={{ __html: deal.highlights }}
                  ></div>
                </Descriptions.Item>
                <Descriptions.Item label="Fine print" span={3}>
                  <div
                    dangerouslySetInnerHTML={{ __html: deal.find_print }}
                  ></div>
                </Descriptions.Item>
                <Descriptions.Item label="How to Redeem" span={3}>
                  <div
                    dangerouslySetInnerHTML={{ __html: deal.how_to_redeem }}
                  ></div>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </div>
      </Drawer>
      <>
        {profile.myPreOrders ? (
          profile.myPreOrders.length > 0 ? (
            <div className="orders-container">
              {profile.myPreOrders.map((purchase) => {
                return (
                  <>
                    <div class="layout">
                      <div class="profile">
                        <div class="profile__picture">
                          <img
                            onClick={() => {
                              navigateHandler(
                                purchase.deal_slug,
                                purchase.rating
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            src={purchase.image_name}
                            alt="ananddavis"
                          />
                        </div>
                        <div class="profile__header">
                          <div class="profile__account">
                            <h4 class="profile__username">
                              {purchase.product_name}
                            </h4>
                          </div>
                          <div className="order--view--detail">
                            {moment(purchase.endDate) < moment() && (
                              <Link
                                to={
                                  purchase.deal_variant_id
                                    ? `/payment/${purchase.deal_slug}/${purchase.deal_product_id}/${purchase.deal_variant_id}`
                                    : `/payment/${purchase.deal_slug}/${purchase.deal_product_id}`
                                }
                              >
                                {purchase.due_amount !== "0" && (
                                  <div
                                    class="profile__edit"
                                    onClick={() =>
                                      dispatch({
                                        type: "SAVE_PREORDER_PAYMENT_INFO",
                                        payload: {
                                          id: purchase.id,
                                          paid_amount: purchase.due_amount,
                                        },
                                      })
                                    }
                                  >
                                    <a class="profile__button" href="#">
                                      Make Payment
                                    </a>
                                  </div>
                                )}
                              </Link>
                            )}
                            <div
                              class="profile__edit"
                              onClick={() => viewHandler(purchase)}
                            >
                              <a class="profile__button" href="#">
                                View Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="profile__stats">
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--gold">
                              <i class="fas fa-wallet"></i>
                            </div>
                            <div class="profile__value">
                              <span style={{ color: "red", cursor: "pointer" }}>
                                {purchase.due_amount === "0" &&
                                  "Full Payment Completed"}
                                {moment(purchase.endDate) > moment() &&
                                  purchase.due_amount !== "0" &&
                                  "Pending"}
                                {moment(purchase.endDate) < moment() &&
                                  purchase.due_amount !== "0" &&
                                  "Make payment"}
                              </span>
                              <div class="profile__key"> Status</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              ${purchase.amount_after_discount}
                              <div class="profile__key">Total</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              {moment(purchase.added_at).format("DD/MM/YYYY")}
                              <div class="profile__key">Date</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              {purchase.qty}
                              <div class="profile__key">Quantity</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                {" "}
                {parseInt(profile.orders.current_page) > 1 && (
                  <Button
                    text="Previous"
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  />
                )}
                {profile.orders.has_next_page && (
                  <Button
                    text="Next"
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="orders-container">
              <p>No Orders History.</p>
            </div>
          )
        ) : (
          <Spinner2 />
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(PreOrders);
