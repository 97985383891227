import React from "react";
import { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import RateForm from "../../components/Rate/RateForm";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { Link, Navigate } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import Spinner2 from "../../components/spinner/Spinner2";
import { Badge, Drawer, Tag, Button as Buttons, Tooltip } from "antd";
import { Modal } from "antd";
import QRCode from "react-qr-code";
import { getCookie } from "../../FrontendHelper";
import Moment from "react-moment";
import { BiPurchaseTag } from "react-icons/bi";
import { BsCalendarEvent } from "react-icons/bs";
import { DownloadOutlined } from "@ant-design/icons";
import { GrLocation } from "react-icons/gr";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import axios from "axios";
import moment from "moment";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Purchase = ({ profile, review }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState("AllOrder");
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [productId, setProductId] = useState("");
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
  const [currentPurchase, setCurrentPurchase] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [cavaibla, setAvailable] = useState(1);
  const [redeemed, setRedemmed] = useState(1);
  const [deal, setDeal] = useState();
  const [deal_slug, set_deal_slug] = useState(currentPurchase.deal_slug);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const [actives, setActives] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentPurchase.deal_slug) {
      const getProductDetails = async () => {
        const response = await axios
          .get(
            // `https://classibazaar.com.au/api/deal/detail/${currentPurchase.deal_slug}`
            `/deal/detail/${currentPurchase.deal_slug}`
          )
          .catch((err) => {
            "";
          });

        setDeal(response.data.deal);
      };

      getProductDetails();
    }
    if (currentPurchase.id) {
      const getProductDetailss = async () => {
        const response = await axios
          .get(
            // `https://classibazaar.com.au/api/deal/product_order_detail/${currentPurchase.id}`,
            `/deal/product_order_detail/${currentPurchase.id}`,
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          )
          .catch((err) => {
            "";
          });

        setOrderStatus(response.data.order_history);
      };

      getProductDetailss();
    }
    if (currentPurchase.current_status === "Pending") {
      setActives(0);
    }
    if (
      currentPurchase.current_status === "Pending" &&
      orderStatus.length > 4
    ) {
      setActives(1);
    }

    if (currentPurchase.current_status == "Processing") {
      setActives(2);
    }
    if (
      currentPurchase.current_status === "Processing" &&
      orderStatus.length === 4
    ) {
      setActives(1);
    }
    if (currentPurchase.current_status == "Ready to Dispatch") {
      setActives(3);
    }
    if (
      currentPurchase.current_status === "Ready to Dispatch" &&
      orderStatus.length === 4
    ) {
      setActives(2);
    }
    if (currentPurchase.current_status == "Dispatched") {
      setActives(4);
    }
    if (
      currentPurchase.current_status === "Dispatched" &&
      orderStatus.length === 4
    ) {
      setActives(3);
    }
    if (currentPurchase.current_status == "Delivered") {
      setActives(5);
    }
    if (
      currentPurchase.current_status === "Delivered" &&
      orderStatus.length === 4
    ) {
      setActives(4);
    }
  }, [currentPurchase]);
  // }, [currentPurchase, orderStatus]);

  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: "GET_MY_ORDERS_REQUEST",
      payload: {
        status: currentStatus,
        page: currentStatus == "Redeemed" ? redeemed : currentPage,
      },
    });
    window.scrollTo(0, 0);
  }, [currentStatus, currentPage]);

  const reviewHandler = (review, rating) => {};
  const submitHandler = (id) => {
    setProductId(id);
    setIsReviewOpen(true);
  };
  const viewHandler = (purchase) => {
    setCurrentPurchase(purchase);
    setVisible(true);
  };

  if (isOrderDetailsOpen) {
    return (
      <OrderDetails
        currentPurchase={currentPurchase}
        setIsOrderDetailsOpen={setIsOrderDetailsOpen}
      />
    );
  }
  const redeemHandler = () => {
    setActive("Redemmed");
    setCurrentStatus("Redeemed");
  };
  const availableHandler = () => {
    setActive("Completed");
    setCurrentStatus("Available");
  };
  const allHandler = () => {
    setActive("AllOrder");
    setCurrentStatus("");
  };
  const onClose = () => {
    setVisible(false);
  };
  const navigateHandler = (deal_slug, rating) => {
    navigate(`/details/${deal_slug}/${rating}`);
  };

  if (profile.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "-webkit-fill-available",
        }}
      >
        <Spinner />;
      </div>
    );
  }

  return (
    <div className="purchase-container">
      {isReviewOpen && (
        <RateForm
          productId={productId}
          setIsReviewOpen={setIsReviewOpen}
          reviewHandler={reviewHandler}
        />
      )}
      <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>My Order History</h1>
      <Drawer
        title="Order Details"
        placement={"right"}
        className="drawer--container"
        // placement={"top"}
        closable={true}
        onClose={onClose}
        visible={visible}
        height={"100vh"}
        width={"100vw"}
        key={"top"}
      >
        <div className="order-detail-container" style={{ margin: "auto" }}>
          <div className="middle">
            <div>
              <span>
                {" "}
                <BiPurchaseTag className="icon" /> ORDER NO
              </span>
              <span className="value">{currentPurchase.order_number}</span>
            </div>
            <div>
              <span>
                {" "}
                <BsCalendarEvent className="icon" /> ORDER DATE
              </span>
              <span className="value">
                {moment(currentPurchase.order_date).format("DD/MM/YYYY")}
              </span>
            </div>
            {currentPurchase.shipping_address1 && (
              <div>
                <span>
                  {" "}
                  <GrLocation className="icon" /> DELIVERY ADDRESS
                </span>
                <span className="value">
                  {currentPurchase.shipping_address1}
                </span>
              </div>
            )}
          </div>
          {currentPurchase.deal_type === "Service" ? (
            <Button
              onClick={showModal}
              text="View Voucher"
              style={{ margin: "auto", marginTop: "2rem" }}
            />
          ) : (
            <Box sx={{ width: "100%", margin: "auto", marginTop: "1rem" }}>
              <Stepper activeStep={actives} alternativeLabel>
                {orderStatus &&
                  orderStatus.map((label, index) => (
                    // <Step key={label.status}>
                    //   <StepLabel>
                    //     <div
                    //       style={{ display: "flex", flexDirection: "column" }}
                    //     >
                    //       <span style={{ fontWeight: "bolder" }}>
                    //         {label.status}
                    //       </span>
                    //       {label.added_at && (
                    //         <span className="date">
                    //           {" "}
                    //           <Moment
                    //             format="YYYY/MM/DD"
                    //             date={label.added_at}
                    //           />
                    //         </span>
                    //       )}
                    //     </div>
                    //   </StepLabel>
                    // </Step>
                    <section class="step-indicator">
                      <div
                        class={
                          actives >= index + 1
                            ? "step step1 active"
                            : "step step1"
                        }
                      >
                        <p style={{ width: "200px" }}>
                          {label.status}
                          {label.added_at && (
                            <span className="date" style={{ color: "black" }}>
                              {" "}
                              <Moment
                                format="YYYY/MM/DD"
                                date={label.added_at}
                              />
                            </span>
                          )}
                        </p>

                        <div class="step-icon">{index + 1}</div>
                      </div>
                      {orderStatus.length > index + 1 && (
                        <div
                          class={
                            actives >= index + 2
                              ? "indicator-line  active"
                              : "indicator-line "
                          }
                        ></div>
                      )}
                    </section>
                  ))}
              </Stepper>
            </Box>
          )}
          <Modal
            title="Voucher"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <QRCode value={currentPurchase.payment_pdf} />
              <Tooltip placement="topLeft" title="Download">
                <a href={currentPurchase.payment_pdf} target="_blank">
                  <Buttons
                    type="primary"
                    icon={<DownloadOutlined />}
                    // size={size}
                  />
                </a>
              </Tooltip>
            </div>

            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Deal: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                {currentPurchase.dealstitle}
              </span>
            </div>

            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Product: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                {currentPurchase.product_name}
              </span>
            </div>
            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Quantity: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                {currentPurchase.qty}
              </span>
            </div>
            {currentPurchase.code && (
              <div
                className="inner"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                <span className="attribute">Securty Code: </span>
                <span className="value" style={{ fontWeight: "bolder" }}>
                  {currentPurchase.code}
                </span>
              </div>
            )}

            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Customer Name: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                {currentPurchase.first_name}
              </span>
            </div>
            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Original Price: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                ${currentPurchase.product_price}
              </span>
            </div>
            <div
              className="inner"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <span className="attribute">Classideal Price: </span>
              <span className="value" style={{ fontWeight: "bolder" }}>
                ${currentPurchase.product_discounted_price}
              </span>
            </div>
            {currentPurchase.coupon_code && (
              <div
                className="inner"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                <span className="attribute">Coupon code: </span>
                <span className="value" style={{ fontWeight: "bolder" }}>
                  {currentPurchase.coupon_code}
                </span>
              </div>
            )}

            {currentPurchase.is_paid && (
              <div
                className="inner"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                <span className="attribute">Amount Paid: </span>
                <span className="value" style={{ fontWeight: "bolder" }}>
                  $
                  {(
                    currentPurchase.product_discounted_price *
                    currentPurchase.qty
                  ).toFixed(2)}
                </span>
              </div>
            )}
          </Modal>

          <div
            className="order-information-container"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
          >
            <div className="middles">
              <p style={{ fontSize: "19px", fontWeight: "bold" }}>
                {currentPurchase.dealstitle}
              </p>
              <p>{currentPurchase.subtitle}</p>
              <div className="inner">
                <span className="attribute">Product Name: </span>
                <span className="value">{currentPurchase.product_name}</span>
              </div>
              <div className="inner">
                <span className="attribute">Product Color: </span>
                <span className="value">
                  {currentPurchase.product_color
                    ? currentPurchase.product_color
                    : "Green"}
                </span>
              </div>
              <div className="inner">
                <span className="attribute">Quantity: </span>
                <span className="value">{currentPurchase.qty}</span>
              </div>
              <div className="inner">
                <span className="attribute">Per Product Price: </span>
                <span className="value">$ {currentPurchase.price}</span>
              </div>
              {currentPurchase.coupon_code && (
                <div className="inner">
                  <span className="attribute">Coupon Used: </span>
                  <span className="value"> {currentPurchase.coupon_code}</span>
                </div>
              )}
              {currentPurchase.coupon_code && (
                <div className="inner">
                  <span className="attribute">Discount: </span>
                  <span className="value"> ${currentPurchase.discount}</span>
                </div>
              )}
              {currentPurchase.coupon_code ? (
                <div className="inner">
                  <span className="attribute">Gross Total: </span>
                  <span className="value">
                    ${" "}
                    {(
                      currentPurchase.product_discounted_price *
                        currentPurchase.qty -
                      currentPurchase.discount
                    ).toFixed(2)}
                  </span>
                </div>
              ) : (
                <div className="inner">
                  <span className="attribute">Gross Total: </span>
                  <span className="value">
                    ${" "}
                    {(
                      currentPurchase.product_discounted_price *
                      currentPurchase.qty
                    ).toFixed(2)}
                  </span>
                </div>
              )}
            </div>
            {deal && (
              <>
                <div className="middles">
                  <p style={{ fontSize: "19px", fontWeight: "bold" }}>
                    Highlights
                  </p>
                  <div
                    style={{ lineHeight: "1.5" }}
                    dangerouslySetInnerHTML={{ __html: deal.highlights }}
                  ></div>
                </div>
                <div className="middles">
                  <p style={{ fontSize: "19px", fontWeight: "bold" }}>
                    Fine Print
                  </p>
                  <div
                    style={{ lineHeight: "1.5" }}
                    dangerouslySetInnerHTML={{ __html: deal.find_print }}
                  ></div>
                </div>
                <div className="middles">
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: "bold",
                    }}
                  >
                    How to Redeem
                  </p>
                  <div
                    style={{ lineHeight: "1.5" }}
                    dangerouslySetInnerHTML={{ __html: deal.how_to_redeem }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      </Drawer>
      <div className="order-tab-container">
        <div onClick={() => allHandler()}>
          <span className={active == "AllOrder" ? "active--status" : ""}>
            All order
          </span>
          {/* <div className={active == "AllOrder" ? "line active" : "line"}></div> */}
        </div>
        <div onClick={() => availableHandler()}>
          <span className={active == "Completed" ? "active--status" : ""}>
            Available
          </span>
          {/* <div className={active == "Completed" ? "line active" : "line"}></div> */}
        </div>
        <div onClick={() => redeemHandler()}>
          <span className={active == "Redemmed" ? "active--status" : ""}>
            Redeemed
          </span>
          {/* <div className={active == "Redemmed" ? "active line" : ""}></div> */}
        </div>
      </div>
      {active === "AllOrder" && (
        <>
          {profile.myOrders ? (
            profile.myOrders.length > 0 ? (
              <div className="orders-container">
                {profile.myOrders.map((purchase) => {
                  return (
                    <>
                      <div class="layout">
                        <div class="profile">
                          {purchase.is_gift && (
                            <Badge.Ribbon
                              text={`Gifted to: ${purchase.is_gift.name}`}
                              color={"red"}
                              style={{
                                marginTop: "-3rem",
                                marginRight: "-1rem",
                              }}
                            ></Badge.Ribbon>
                          )}

                          <div class="profile__picture">
                            <img
                              onClick={() => {
                                navigateHandler(
                                  purchase.deal_slug,
                                  purchase.rating
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              src={purchase.image.image_name}
                              alt="ananddavis"
                            />
                          </div>
                          <div class="profile__header">
                            <div class="profile__account">
                              <h4 class="profile__username">
                                {purchase.product_name}
                              </h4>
                            </div>
                            <div
                              class="profile__edit"
                              onClick={() => viewHandler(purchase)}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                class="profile__button"
                              >
                                View Details
                              </span>
                            </div>
                          </div>
                          <div class="profile__stats">
                            <div class="profile__stat">
                              <div class="profile__icon profile__icon--gold">
                                <i class="fas fa-wallet"></i>
                              </div>
                              <div class="profile__value">
                                <span
                                  style={
                                    purchase.order_status == "Redeemed"
                                      ? { color: "green" }
                                      : { color: "red" }
                                  }
                                >
                                  {purchase.order_status
                                    ? purchase.order_status
                                    : purchase.current_status}
                                </span>
                                <div class="profile__key">Order Status</div>
                              </div>
                            </div>
                            <div class="profile__stat">
                              <div class="profile__icon profile__icon--blue">
                                <i class="fas fa-signal"></i>
                              </div>
                              <div class="profile__value">
                                {purchase.order_number}
                                <div class="profile__key">Order No</div>
                              </div>
                            </div>
                            <div class="profile__stat">
                              <div class="profile__icon profile__icon--blue">
                                <i class="fas fa-signal"></i>
                              </div>
                              <div class="profile__value">
                                {moment(purchase.order_date).format(
                                  "DD/MM/YYYY"
                                )}
                                <div class="profile__key">Date</div>
                              </div>
                            </div>
                            <div class="profile__stat">
                              <div class="profile__icon profile__icon--blue">
                                <i class="fas fa-signal"></i>
                              </div>
                              <div class="profile__value">
                                {purchase.qty}
                                <div class="profile__key">Quantity</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1rem",
                  }}
                >
                  {" "}
                  {parseInt(profile.orders.current_page) > 1 && (
                    <Button
                      text="Previous"
                      onClick={() => {
                        setCurrentPage((prev) => prev - 1);
                      }}
                    />
                  )}
                  {profile.orders.has_next_page && (
                    <Button
                      text="Next"
                      onClick={() => {
                        setCurrentPage((prev) => prev + 1);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="orders-container">
                <p>No Orders History.</p>
              </div>
            )
          ) : (
            <Spinner2 />
          )}
        </>
      )}
      {active === "Completed" && (
        <>
          {profile.myOrders.length > 0 ? (
            <div className="orders-container">
              {profile.myOrders.map((purchase) => {
                return (
                  <div class="layout">
                    <div class="profile">
                      {purchase.is_gift && (
                        <Badge.Ribbon
                          text={`Gifted to: ${purchase.is_gift.name}`}
                          color={"red"}
                          style={{
                            marginTop: "-3rem",
                            marginRight: "-1rem",
                          }}
                        ></Badge.Ribbon>
                      )}

                      <div class="profile__picture">
                        <img
                          onClick={() => {
                            navigateHandler(
                              purchase.deal_slug,
                              purchase.rating
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          src={purchase.image.image_name}
                          alt="ananddavis"
                        />
                      </div>
                      <div class="profile__header">
                        <div class="profile__account">
                          <h4 class="profile__username">
                            {purchase.product_name}
                          </h4>
                        </div>
                        <div
                          class="profile__edit"
                          onClick={() => viewHandler(purchase)}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            class="profile__button"
                          >
                            View Details
                          </span>
                        </div>
                      </div>
                      <div class="profile__stats">
                        <div class="profile__stat">
                          <div class="profile__icon profile__icon--gold">
                            <i class="fas fa-wallet"></i>
                          </div>
                          <div class="profile__value">
                            <span
                              style={
                                purchase.order_status == "Redeemed"
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            >
                              {purchase.order_status
                                ? purchase.order_status
                                : purchase.current_status}
                            </span>
                            <div class="profile__key">Order Status</div>
                          </div>
                        </div>
                        <div class="profile__stat">
                          <div class="profile__icon profile__icon--blue">
                            <i class="fas fa-signal"></i>
                          </div>
                          <div class="profile__value">
                            {purchase.order_number}
                            <div class="profile__key">Order No</div>
                          </div>
                        </div>
                        <div class="profile__stat">
                          <div class="profile__icon profile__icon--blue">
                            <i class="fas fa-signal"></i>
                          </div>
                          <div class="profile__value">
                            {moment(purchase.order_date).format("DD/MM/YYYY")}
                            <div class="profile__key">Date</div>
                          </div>
                        </div>
                        <div class="profile__stat">
                          <div class="profile__icon profile__icon--blue">
                            <i class="fas fa-signal"></i>
                          </div>
                          <div class="profile__value">
                            {purchase.qty}
                            <div class="profile__key">Quantity</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                {" "}
                {parseInt(profile.orders.current_page) > 1 && (
                  <Button
                    text="Previous"
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  />
                )}
                {profile.orders.has_next_page && (
                  <Button
                    text="Next"
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="orders-container">
              <p>No Orders History.</p>
            </div>
          )}
        </>
      )}
      {active === "Redemmed" && (
        <>
          {profile.myOrders.length > 0 ? (
            <div className="orders-container">
              {profile.myOrders.map((purchase) => {
                return (
                  <>
                    <div class="layout">
                      <div class="profile">
                        {purchase.is_gift && (
                          <Badge.Ribbon
                            text={`Gifted to: ${purchase.is_gift.name}`}
                            color={"red"}
                            style={{
                              marginTop: "-3rem",
                              marginRight: "-1rem",
                            }}
                          ></Badge.Ribbon>
                        )}

                        <div class="profile__picture">
                          <img
                            onClick={() => {
                              navigateHandler(
                                purchase.deal_slug,
                                purchase.rating
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            src={purchase.image.image_name}
                            alt="ananddavis"
                          />
                        </div>
                        <div class="profile__header">
                          <div class="profile__account">
                            <h4 class="profile__username">
                              {purchase.product_name}
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "1rem",
                              flexWrap: "wrap",
                            }}
                          >
                            {!purchase.has_reviews && (
                              <div
                                class="profile__edit"
                                onClick={() => submitHandler(purchase.deal_id)}
                              >
                                <a class="profile__button" href="#">
                                  Review Order
                                </a>
                              </div>
                            )}
                            <div
                              class="profile__edit"
                              onClick={() => viewHandler(purchase)}
                            >
                              {purchase.has_reviews && (
                                <p>
                                  {
                                    <Rating
                                      style={{
                                        pointerEvents: "none",
                                        fontSize: "24px",
                                      }}
                                      emptySymbol={<AiOutlineStar />}
                                      placeholderSymbol={
                                        <AiFillStar color="yellow" />
                                      }
                                      fullSymbol={<AiFillStar />}
                                      placeholderRating={
                                        purchase.has_reviews.review_rating
                                      }
                                    />
                                  }
                                </p>
                              )}
                              <a class="profile__button" href="#">
                                View Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="profile__stats">
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--gold">
                              <i class="fas fa-wallet"></i>
                            </div>
                            <div class="profile__value">
                              <span
                                style={
                                  purchase.order_status == "Redeemed"
                                    ? { color: "green" }
                                    : { color: "red" }
                                }
                              >
                                {purchase.order_status
                                  ? purchase.order_status
                                  : purchase.current_status}
                              </span>
                              <div class="profile__key">Order Status</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              {purchase.order_number}
                              <div class="profile__key">Order No</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              {moment(purchase.order_date).format("DD/MM/YYYY")}
                              <div class="profile__key">Date</div>
                            </div>
                          </div>
                          <div class="profile__stat">
                            <div class="profile__icon profile__icon--blue">
                              <i class="fas fa-signal"></i>
                            </div>
                            <div class="profile__value">
                              {purchase.qty}
                              <div class="profile__key">Quantity</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                {" "}
                {parseInt(profile.orders.current_page) > 1 && (
                  <Button
                    text="Previous"
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  />
                )}
                {profile.orders.has_next_page && (
                  <Button
                    text="Next"
                    onClick={() => {
                      setRedemmed((prev) => prev + 1);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="orders-container">
              <p>No Orders History.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    review: state.review,
  };
};

export default connect(mapStateToProps, {})(Purchase);
