import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import "./HomeProducts.css";
import ReactStars from "react-rating-stars-component";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Spinner2 from "../spinner/Spinner2";
import { Badge } from "antd";
import { stringTrim } from "../../utils/stringTrim";
import ProductSkeleton from "../skeleton/productSkeleton";
import { connect, useDispatch } from "react-redux";

const mapStateToProps = (store) => {
  const { featureProduct, isFeatureProductLoading } = store.dashboardProduct;
  return { featureProduct, isFeatureProductLoading };
};

const FeaturedProducts = ({ featureProduct, isFeatureProductLoading }) => {
  const [visible, setVisible] = useState(4);
  const [buttonToggle, setButtonToggle] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "FETCH_FEATURE_PRODUCT_REQUEST" });
  }, []);

  useEffect(() => {}, [featureProduct]);

  const showMoreItems = () => {
    setButtonToggle(!buttonToggle);
    setVisible((prevValue) => prevValue + 4);
  };

  const handleSetProductStatus = () => {
    localStorage.setItem("productStatus", "normalProduct");
  };

  const showLessItems = () => {
    setButtonToggle(!buttonToggle);
    setVisible((prevValue) => prevValue - 4);
  };

  if (isFeatureProductLoading) {
    return (
      <div className="product--container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "100%",
            margin: "0 1.5rem",
          }}
        >
          <div className="product--load--header">
            <span>Featured Products</span>
          </div>
          <ProductSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      {featureProduct?.length > 0 ? (
        <>
          <div className="product--container">
            <div className="product--header">
              <span>Featured Products</span>
            </div>
          </div>

          <div className="product--container">
            <div className="featuredProducts">
              {featureProduct?.map((product) => (
                <div
                  className="productCard"
                  key={product?.id}
                  onClick={handleSetProductStatus}
                  // data-aos="fade-up"
                >
                  <Link
                    to={`/details/${product?.deal_slug}/${product?.rating}`}
                    className="link"
                  >
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <p
                        className="discount--rate"
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          left: 0,
                          zIndex: 2,
                        }}
                      >
                        {product.discount}% Off
                      </p>
                      <div className="product--badge">
                        {product?.is_featured === "1" && (
                          <Badge.Ribbon
                            text="Featured"
                            color={"#ff1493"}
                          ></Badge.Ribbon>
                        )}
                        {/* {product.pre_order === "Yes" && (
                          <Badge.Ribbon
                            text="Pre-order"
                            color={"red"}
                          ></Badge.Ribbon>
                        )} */}
                      </div>
                      <div className="image--animate">
                        <img
                          src={product?.image?.thumbnail}
                          style={{ height: "16rem" }}
                          alt=""
                        />
                      </div>
                      <div className="container-below">
                        <div className="product--cart--details">
                          <div className="product--description">
                            {product?.dealstitle &&
                              stringTrim(product?.dealstitle, 35)}
                            {product?.dealstitle?.length > 35 ? "..." : ""}
                          </div>
                          <div className="product--price">
                            <span style={{ flex: 1 }}>
                              <span className="base--offer--price">
                                ${product?.actual_price}
                              </span>{" "}
                              {"-"}{" "}
                              <span className="base--product--price">
                                ${product?.product_price}
                              </span>
                            </span>
                            <ReactStars
                              count={product?.rating}
                              color={"#ffd700"}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                          <div className="product--desc">
                            {product?.subtitle &&
                              stringTrim(product?.subtitle, 60)}
                            {product?.subtitle?.length > 60 ? "..." : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(mapStateToProps)(FeaturedProducts);
