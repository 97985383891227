import { toast } from "react-toastify";
import history from "../../utils/history";

const initialState = {
  reviews: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "GET_REVIEW_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REVIEW_SUCCESS":
      return {
        ...state,
        isLoading: false,
        reviews: payload,
      };
    case "GET_REVIEW_FAILURE":
      return {
        ...state,
        isLoading: false,
        reviews: [],
      };
    case "ADD_REVIEW_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_REVIEW_SUCCESS":
      toast.success(payload.message, {
        toastId: 28,
      });
      setTimeout(() => {
        history.push("/");
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_REVIEW_FAILURE":
      toast.error(payload.data.message, {
        toastId: 29,
      });
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
