import React, { useState } from "react";
import "./Rate.css";
import { Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Button from "../Button/Button";
import Spinner from "../spinner/Spinner";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

const RateForm = (props) => {
  const [review, setReview] = useState("");
  const [rating, setRating] = useState("");
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch({
      type: "ADD_REVIEW_REQUEST",
      payload: {
        productId: props.productId,
        reviews: review,
        review_rating: rating,
      },
    });
    

    // props.setIsReviewOpen(false);
  };
  if (props.review.isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className="rate-form-container"
      style={{ zIndex: "222222222222222222222222222222222222222222" }}
    >
      <div className="overlay">
        <div
          className="form-container"
          style={{ zIndex: "222222222222222222222222222222222222222222" }}
        >
          <div style={{ display: "flex" }} className="h">
            <p className="header">It was wonderful working with you!</p>
            <Button text="X" onClick={() => props.setIsReviewOpen(false)} />
          </div>

          <p style={{ marginBottom: "-0.2rem", fontSize: "15px" }}>
            Rate Your Experience
          </p>
          <Rate
            style={{ fontSize: "40px" }}
            value={rating}
            onChange={(e) => setRating(e)}
          />
          <p style={{ fontSize: "15px", marginTop: "0.8rem" }}>Review:</p>
          <TextArea
            style={{ height: "100px" }}
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
          <Button
            text="Submit"
            style={{ marginTop: "1rem", width: "40%" }}
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    review: state.review,
  };
};

export default connect(mapStateToProps, {})(RateForm);
