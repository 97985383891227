import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  BsFillPersonFill,
  BsFillBagFill,
  BsFillCartFill,
  BsFillPersonVcardFill,
  BsFillUnlockFill,
} from "react-icons/bs";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Personal from "./Personal";
import Purchase from "./Purchase";
import Payment from "./Payment";
import Shipping from "./Shipping";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import UpdatePassword from "./UpdatePassword";
import PreOrders from "./PreOrders";

import "./Profile.css";

const Profile = ({ profile }) => {
  const [active, setActive] = useState(
    localStorage?.getItem("activeState")
      ? localStorage?.getItem("activeState")
      : "personal"
  );

  return (
    <div>
      <Navbar />
      <div className="product--container">
        <div className="profile--layout--container">
          <div className="profile_container">
            <div className="profile_left">
              <div
                className={
                  active == "personal"
                    ? "item profile--active--component"
                    : "item"
                }
                onClick={() => {
                  setActive("personal");
                  localStorage.setItem("activeState", "personal");
                }}
              >
                <BsFillPersonFill
                  className="large--screen--profile--menu"
                  style={{ height: "25px", width: "25px" }}
                />

                <span>Personal Details</span>
              </div>
              <div
                className={
                  active === "preorders"
                    ? "item profile--active--component"
                    : "item"
                }
                onClick={() => {
                  setActive("preorders");
                  localStorage.setItem("activeState", "preorders");
                }}
              >
                <BsFillBagFill
                  className="large--screen--profile--menu"
                  style={{ height: "20px", width: "20px" }}
                />

                <span>My Pre-orders</span>
              </div>
              <div
                className={
                  active == "purchase"
                    ? "item profile--active--component"
                    : "item"
                }
                onClick={() => {
                  setActive("purchase");
                  localStorage.setItem("activeState", "purchase");
                }}
              >
                <BsFillCartFill
                  className="large--screen--profile--menu"
                  style={{ height: "20px", width: "20px" }}
                />

                <span>My Purchase</span>
              </div>
              <div
                className={
                  active == "payment"
                    ? "item profile--active--component"
                    : "item"
                }
                onClick={() => {
                  setActive("payment");
                  localStorage.setItem("activeState", "payment");
                }}
              >
                <BsFillPersonVcardFill
                  className="large--screen--profile--menu"
                  style={{ height: "20px", width: "20px" }}
                />

                <span>Payment Methods</span>
              </div>
              <div
                className={
                  active == "upPass"
                    ? "item profile--active--component"
                    : "item"
                }
                onClick={() => {
                  setActive("upPass");
                  localStorage.setItem("activeState", "upPass");
                }}
              >
                <BsFillUnlockFill
                  className="large--screen--profile--menu"
                  style={{ height: "20px", width: "20px" }}
                />

                <span>Update Password</span>
              </div>
            </div>

            <div className="profile-right">
              {active == "personal" && <Personal />}
              {active === "preorders" && <PreOrders />}

              {active == "purchase" && <Purchase />}
              {active == "payment" && <Payment />}
              {active === "shipping" && <Shipping />}

              {active === "upPass" && <UpdatePassword />}
            </div>
          </div>
        </div>
      </div>
      <FeatureContainer />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(Profile);
