import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  myWishlists: [],
  wishLength: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TO_WISHLIST_REQUEST":
      return { ...state, isLoading: true };

    case "GET_MY_WISHLIST_REQUEST":
      return { ...state, isLoading: true };
    case "DELETE_WISHLIST_REQUEST":
      return { ...state, isLoading: true };
    case "ADD_TO_WISHLIST_SUCCESS":
      toast.success(payload.message, { toastId: 24 });
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_TO_WISHLIST_FAILURE":
      toast.error(payload.data.message);

      return {
        ...state,
        isLoading: false,
      };
    case "GET_MY_WISHLIST_SUCCESS":
      console.log("wishlist wishlist", payload);
      return {
        ...state,
        isLoading: false,
        myWishlists: payload,
        wishLength: payload?.length,
      };
    case "GET_MY_WISHLIST_FAILURE":
      toast.error(payload?.message, { toastId: 50 });
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_WISHLIST_SUCCESS":
      toast.success(payload?.message, { toastId: 51 });
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };

    case "DELETE_WISHLIST_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
}
