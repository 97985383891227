import React, { useState, useEffect, useRef } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./Search.css";
import Button from "../Button/Button";
import { Link, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import AOS from "aos";
import axios from "axios";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import { GoSearch } from "react-icons/go";
import Spinner from "../spinner/Spinner";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import noResultImage from "../../images/no-result.png";
import { Pagination } from "antd";
import { BiSliderAlt } from "react-icons/bi";

import FeatureContainer from "../Feature/FeatureContainer";
import OutsideAlerter from "../UseOutSideAlterter";
import { IoMdArrowDropdown } from "react-icons/io";
import { Badge, Input } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import { stringTrim } from "../../utils/stringTrim";
import { Drawer } from "antd";

const SearchResult = ({ search }) => {
  const [price, setPrice] = useState(0);
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const [searchValue, setSearch] = useState("");
  const dispatch = useDispatch();
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [openDistanceModal, setOpenDistanceModa] = useState(false);
  const [all, setAll] = useState(false);
  const [selectedData, setSelectedData] = useState({
    location: "",
    price: "",
    distance: "",
  });
  const [active, setActive] = useState([]);
  const [locationDrawer, setLocationDrawer] = useState(false);
  const [priceDrawer, setPriceDrawer] = useState(false);
  const [distanceDrawer, setDistanceDrawer] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [FeaturedProducts, setFeaturedProducts] = useState([]);
  const [total_pages, setTotalPages] = useState(1);
  const [cities, setCities] = useState([]);
  const [value, setValue] = React.useState([0, 100]);
  const [distance, setDistance] = useState([0, 0]);
  const [currentPage, setCurrentPage] = useState(1);
  const { category } = useParams();
  const [address, setAddress] = useState("");
  const [latlng, setLatlng] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    setActive([]);
    const getFeaturedProducts = async () => {
      AOS.init({
        duration: 1000,
      });
      const response = await axios
        // .get("https://classibazaar.com.au/api/deal/featured_deals")
        .get("/deal/featured_deals")
        .catch((err) => {
          "";
        });
      setFeaturedProducts(response?.data);
    };
    getFeaturedProducts();
  }, [category]);
  useEffect(async () => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const response = await axios.get(
        // "https://classibazaar.com.au/api/deal/products"
        "/deal/products"
      );
      setCities(response?.data?.cities);
    };
    // dispatch({
    //   type: "SEARCH_PRODUCT_REQUEST",
    //   payload: {
    //     city: active,
    //     minPrice: value[0],
    //     maxPrice: value[1],
    //     query: searchValue,
    //   },
    // });
    getData();
  }, []);

  const submitHandler = () => {
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        c: category,
        city: active,
        minPrice: value[0] * 10,
        maxPrice: value[1] * 10,
        query: searchValue,
      },
    });
  };

  const searchHandlerByLocation = () => {
    setLocationDrawer(false);
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        city: active,
        q: "",
      },
    });
  };

  const searchHandlerByPrice = () => {
    setPriceDrawer(false);
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        // q: "",
        minPrice: value[0] * 10,
        maxPrice: value[1] * 10,
      },
    });
  };

  const searchHandlerByAddress = () => {
    setDistanceDrawer(false);
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        q: "",
        lat: address ? latlng.lat : "",
        lng: address ? latlng.lng : "",
        distance: address ? distance[1] : "",
      },
    });
  };

  const searchHandler = () => {
    if (address) {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          c: category,
          city: active,
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
          query: searchValue,
          lat: address ? latlng.lat : "",
          lng: address ? latlng.lng : "",
          distance: address ? distance[1] : "",
        },
      });
    } else {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          c: category,
          city: active,
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
          query: searchValue,
        },
      });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeDistance = (event, newValue) => {
    if (!address) {
      return toast.error("Please enter your address first!", {
        toastId: 12542,
      });
    }
    setDistance(newValue);
  };

  const onPageChange = (value) => {
    setCurrentPage(value);
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        city: active,
        minPrice: value[0] * 10,
        maxPrice: value[1] * 10,
        query: searchValue,
        page: value,
      },
    });
  };
  const handleChanges = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatlng(latLng))
      .catch((error) => "");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search.results]);

  const searchOptions = {
    componentRestrictions: { country: "au" }, // Restrict results to Australia
  };

  const handleFilterProduct = () => {
    setOpenFilterDrawer(false);
    // dispatch({
    //   type: "SEARCH_PRODUCT_REQUEST",
    //   payload: {
    //     city: active,
    //     q: "",
    //     minPrice: value[0] * 10,
    //     maxPrice: value[1] * 10,
    //     lat: address ? latlng.lat : "",
    //     lng: address ? latlng.lng : "",
    //     distance: address ? distance[1] : "",
    //   },
    // });

    address &&
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: active,
          q: "",
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
          lat: address ? latlng.lat : "",
          lng: address ? latlng.lng : "",
          distance: address ? distance[1] : "",
        },
      });

    !address &&
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: active,
          q: "",
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
        },
      });
  };

  return (
    <>
      {search.isLoading ? (
        <>
          <Navbar
            active={active}
            address={address}
            latlng={latlng}
            distance={distance}
            value={value}
          />
          <Spinner />
        </>
      ) : (
        <>
          <div className="search--result--header">
            <Navbar
              active={active}
              address={address}
              latlng={latlng}
              distance={distance}
              value={value}
            />
          </div>
          <div className="search-result-container">
            <div className="searchContainer on-small">
              <GoSearch
                className="location-icon"
                style={{ marginTop: "1.2rem" }}
              />

              {show ? (
                <OutsideAlerter
                  setShow={setShow}
                  show={show}
                  wrapperRef={wrapperRef}
                >
                  <div className="dropDownMenuLocation" ref={wrapperRef}>
                    {cities?.map((city, key) => {
                      return (
                        <ul
                          key={key}
                          onClick={() => {
                            setSelectedData((prev) => {
                              return { ...prev, location: city.cityName };
                            });
                            setActive((prev) => {
                              return [...prev, city.cityName];
                            });
                          }}
                        >
                          <li className="li" onClick={() => setShow(!show)}>
                            {city.cityName}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </OutsideAlerter>
              ) : null}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <input
                  style={{ marginTop: "1rem" }}
                  value={searchValue}
                  placeholder="Search Here"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Link to={"/search-results"}>
                  <Button
                    className="searchBtn"
                    text="Search"
                    onClick={searchHandler}
                    style={{
                      marginTop: "0.5rem",
                    }}
                    // onClick={() => setOpenModal(!openModal)}
                  ></Button>
                </Link>
              </div>

              {openPriceModal && (
                <div className="modal">
                  <div className="overlay"></div>
                  <div
                    className="modalContent"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="top-container"
                      style={{
                        width: "100%",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "bold", marginLeft: "-10px" }}>
                        Price Filter
                      </span>
                      <Button
                        className="closeModal"
                        onClick={() => setOpenPriceModal(false)}
                      >
                        X
                      </Button>
                    </div>
                    <Box>
                      <Slider
                        size="small"
                        value={value}
                        onChange={handleChange}
                      />
                    </Box>
                    <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                      <span>${value[0] * 10}</span>
                      <span>-</span>
                      <span>${value[1] * 10}</span>
                    </div>
                    <Button
                      text="Filter"
                      style={{ marginTop: "8px" }}
                      onClick={() => setOpenPriceModal(false)}
                    />
                  </div>
                </div>
              )}

              {openDistanceModal && (
                <div className="modal">
                  <div className="overlay"></div>
                  <div
                    className="modalContent"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "bold", marginLeft: "-10px" }}>
                        Distance Filter
                      </span>
                      <Button
                        className="closeModal"
                        onClick={() => setOpenDistanceModa(false)}
                      >
                        X
                      </Button>
                    </div>

                    <PlacesAutocomplete
                      value={address}
                      onChange={handleChanges}
                      onSelect={handleSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <span>Address:</span>
                          <Input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                            value={address}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span
                                    onClick={() =>
                                      setAddress(suggestion.description)
                                    }
                                  >
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <Slider
                      value={distance}
                      onChange={handleChangeDistance}
                      valueLabelDisplay="auto"
                    />
                    <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                      <span>{distance[0] * 10}km</span>
                      <span>-</span>
                      <span>{distance[1]}km</span>
                    </div>

                    <Button
                      text="Filter"
                      style={{ marginTop: "8px" }}
                      onClick={() => {
                        if (!address) {
                          return toast.error("Please enter your address!");
                        }
                        setOpenDistanceModa(false);
                      }}
                    />
                  </div>{" "}
                </div>
              )}
            </div>
            <div
              className="searchContainer below on-small"
              style={{ marginTop: "-0.5rem" }}
            >
              <div className="inner-container">
                <div className="left-container">
                  <div onClick={() => setShow(!show)}>
                    <span>
                      {selectedData.location ? selectedData.location : "City"}
                    </span>
                    <IoMdArrowDropdown className="icon" />
                  </div>
                  <div onClick={() => setOpenPriceModal(true)}>
                    <span>
                      {value[0] == 0 && value[1] === 100
                        ? "Price"
                        : "$" + value[0] * 10 + "-" + "$" + value[1] * 10}{" "}
                    </span>
                    <IoMdArrowDropdown className="icon" />
                  </div>
                  <div onClick={() => setOpenDistanceModa(true)}>
                    <span>
                      {address
                        ? distance[0] + "-" + distance[1] + "km"
                        : "Distance"}{" "}
                    </span>
                    <IoMdArrowDropdown className="icon" />
                  </div>
                </div>
                <div
                  className="right-button"
                  onClick={() => {
                    setValue([0, 100]);
                    setSelectedData({
                      location: "City",
                      price: "",
                      distance: "Distance",
                    });
                    setDistance([0, 100]);
                    setAddress("");
                  }}
                >
                  Clear All
                </div>
              </div>
            </div>
            <div className="mobile--filter--container">
              <div className="mobile--view--filter--section">
                <span
                  className={
                    active.length > 0
                      ? "active--filter--icon filter--icon"
                      : "filter--icon"
                  }
                  onClick={() => setLocationDrawer(true)}
                >
                  Location
                </span>
                <span
                  className={
                    value[1] * 10 > 100
                      ? "active--filter--icon filter--icon"
                      : "filter--icon"
                  }
                  onClick={() => setPriceDrawer(true)}
                >
                  Price
                </span>
                <span
                  className={
                    address
                      ? "active--filter--icon filter--icon"
                      : "filter--icon"
                  }
                  onClick={() => setDistanceDrawer(true)}
                >
                  Address
                </span>
              </div>
              <BiSliderAlt
                style={{
                  width: "1.3rem",
                  height: "1.3rem",
                  // marginRight: "0.6rem",
                }}
                onClick={() => setOpenFilterDrawer(true)}
              />
              {/* <span onClick={handleFilterProduct}>Filter</span> */}
            </div>

            {/* filter */}
            <Drawer
              title="Filter the Product"
              placement="right"
              width={"100%"}
              closable={true}
              onClose={() => setOpenFilterDrawer(false)}
              visible={openFilterDrawer}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div className="location--list--filter">
                  <span
                    onClick={() => {
                      setAll(!all);
                      setActive([]);
                    }}
                    className={all && "active"}
                  >
                    All
                  </span>
                  {cities?.map((city, key) => {
                    return (
                      <span
                        onClick={() => {
                          setAll(false);
                          setActive((prev) =>
                            prev?.includes(city.citycode)
                              ? prev?.filter((el) => el !== city?.citycode)
                              : [...prev, city?.citycode]
                          );
                          dispatch({
                            type: "SAVE_DATA",
                            payload: {
                              active: active,
                              address: address,
                              latlng: latlng,
                            },
                          });
                        }}
                        className={
                          active.includes(city.citycode) ? "active" : ""
                        }
                      >
                        {city?.cityName}
                      </span>
                    );
                  })}
                </div>
                <div>
                  <Box>
                    <Slider value={value} onChange={handleChange} />
                  </Box>
                  <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                    <span>${value[0] * 10}</span>
                    <span>-</span>
                    <span>${value[1] * 10}</span>
                  </div>
                </div>
                <div>
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChanges}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <span>Address:</span>
                        <Input
                          height={"50px"}
                          style={{ height: "50px" }}
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "location-search-input",
                          })}
                          value={address}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span
                                  onClick={() =>
                                    setAddress(suggestion.description)
                                  }
                                >
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={distance}
                    onChange={handleChangeDistance}
                    valueLabelDisplay="auto"
                  />
                  <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                    <span>{distance[0] * 10}km</span>
                    <span>-</span>
                    <span>{distance[1]}km</span>
                  </div>
                </div>
                <span
                  onClick={handleFilterProduct}
                  className="filter--button--span"
                >
                  Done
                </span>
              </div>
            </Drawer>

            {/*locationfilter drawer */}
            <Drawer
              title="Filter by Location"
              placement="right"
              width={"100%"}
              closable={true}
              onClose={() => setLocationDrawer(false)}
              visible={locationDrawer}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div className="location--list--filter">
                  <span
                    onClick={() => {
                      setAll(!all);
                      setActive([]);
                    }}
                    className={all && "active"}
                  >
                    All
                  </span>
                  {cities?.map((city, key) => {
                    return (
                      <span
                        onClick={() => {
                          setAll(false);
                          setActive((prev) =>
                            prev?.includes(city.citycode)
                              ? prev?.filter((el) => el !== city?.citycode)
                              : [...prev, city?.citycode]
                          );
                          dispatch({
                            type: "SAVE_DATA",
                            payload: {
                              active: active,
                              address: address,
                              latlng: latlng,
                            },
                          });
                        }}
                        className={
                          active.includes(city.citycode) ? "active" : ""
                        }
                      >
                        {city?.cityName}
                      </span>
                    );
                  })}
                </div>
                <span
                  onClick={searchHandlerByLocation}
                  className="filter--button--span"
                >
                  Done
                </span>
              </div>
            </Drawer>

            {/* filter by price */}
            <Drawer
              title="Filter by Price"
              placement="right"
              width={"100%"}
              closable={true}
              onClose={() => setPriceDrawer(false)}
              visible={priceDrawer}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Box>
                  <Slider value={value} onChange={handleChange} />
                </Box>
                <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                  <span>${value[0] * 10}</span>
                  <span>-</span>
                  <span>${value[1] * 10}</span>
                </div>
                <span
                  onClick={searchHandlerByPrice}
                  className="filter--button--span"
                >
                  Done
                </span>
              </div>
            </Drawer>

            {/* filter by distance */}
            <Drawer
              title="Filter by Distance"
              placement="right"
              width={"100%"}
              closable={true}
              onClose={() => setDistanceDrawer(false)}
              visible={distanceDrawer}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChanges}
                  onSelect={handleSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <span>Address:</span>
                      <Input
                        height={"50px"}
                        style={{ height: "50px" }}
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        value={address}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span
                                onClick={() =>
                                  setAddress(suggestion.description)
                                }
                              >
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={distance}
                  onChange={handleChangeDistance}
                  valueLabelDisplay="auto"
                />
                <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                  <span>{distance[0] * 10}km</span>
                  <span>-</span>
                  <span>{distance[1]}km</span>
                </div>
                <span
                  onClick={searchHandlerByAddress}
                  className="filter--button--span"
                >
                  Done
                </span>
              </div>
            </Drawer>
            <div className="product--container">
              <div className="product--search--container">
                <div className="below-container">
                  <div className="left-container">
                    <div className="left--top--container">
                      <div className="filter--container--header">
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          Filter by Location
                        </p>
                        <span onClick={handleFilterProduct}>Filter</span>
                      </div>
                      <div className="location-container">
                        <span
                          onClick={() => {
                            setAll(!all);
                            setActive([]);
                          }}
                          className={all && "active"}
                        >
                          All
                        </span>
                        {cities?.map((city, key) => {
                          return (
                            <span
                              onClick={() => {
                                setAll(false);
                                setActive((prev) =>
                                  prev.includes(city.citycode)
                                    ? prev.filter((el) => el !== city.citycode)
                                    : [...prev, city.citycode]
                                );
                                dispatch({
                                  type: "SAVE_DATA",
                                  payload: {
                                    active: active,
                                    address: address,
                                    latlng: latlng,
                                  },
                                });
                              }}
                              className={
                                active.includes(city.citycode) ? "active" : ""
                              }
                            >
                              {city.cityName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <div className="left--bottom--container">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Filter By Price
                      </p>
                      <Box>
                        <Slider value={value} onChange={handleChange} />
                      </Box>
                      <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                        <span>${value[0] * 10}</span>
                        <span>-</span>
                        <span>${value[1] * 10}</span>
                      </div>
                    </div>
                    <div className="left--bottom--container">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Filter By Distance
                      </p>
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChanges}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <span>Address:</span>
                            <Input
                              height={"50px"}
                              style={{ height: "50px" }}
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                              value={address}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span
                                      onClick={() =>
                                        setAddress(suggestion.description)
                                      }
                                    >
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={distance}
                        onChange={handleChangeDistance}
                        valueLabelDisplay="auto"
                      />
                      <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                        <span>{distance[0] * 10}km</span>
                        <span>-</span>
                        <span>{distance[1]}km</span>
                      </div>
                    </div>
                  </div>

                  <div className="right-container">
                    {search.results.length > 0 ? (
                      <>
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "bolder",
                          }}
                        >
                          Found {search.response.total_products_count} Results
                        </p>
                        <div className="searched--product">
                          {search?.results?.map((product) => (
                            <div
                              className="searched--product--cart"
                              key={product.id}
                              data-aos="fade-up"
                            >
                              <Link
                                to={`/details/${product.deal_slug}/${product.rating}`}
                                className="link"
                              >
                                <div
                                  className="image-container"
                                  style={{ position: "relative" }}
                                >
                                  <p
                                    className="discount"
                                    style={{
                                      position: "absolute",
                                      top: "0.5rem",
                                      left: 0,
                                      zIndex: 2,
                                    }}
                                  >
                                    {product.discount}% Off
                                  </p>
                                  <div className="product--badge">
                                    {product.is_featured === "1" && (
                                      <Badge.Ribbon
                                        color={"Blue"}
                                        text="Featured"
                                      ></Badge.Ribbon>
                                    )}
                                    {product.is_popular === "1" && (
                                      <Badge.Ribbon
                                        text="Popular"
                                        color={"#ff1493"}
                                      ></Badge.Ribbon>
                                    )}
                                    {product.pre_order === "Yes" && (
                                      <Badge.Ribbon
                                        text="Pre-order"
                                        color={"red"}
                                      ></Badge.Ribbon>
                                    )}
                                  </div>

                                  <div className="image--animate">
                                    <img
                                      src={product?.image?.thumbnail}
                                      alt=""
                                    />
                                  </div>

                                  <div className="container-below">
                                    <div className="product--cart--details">
                                      <div className="product--description">
                                        {stringTrim(product.dealstitle, 20)}
                                        {product.dealstitle.length > 20
                                          ? "..."
                                          : ""}
                                      </div>
                                      <div className="product--price">
                                        <span style={{ flex: 1 }}>
                                          <span className="base--offer--price">
                                            ${product.actual_price}
                                          </span>{" "}
                                          {"-"}{" "}
                                          <span className="base--product--price">
                                            ${product.product_price}
                                          </span>
                                        </span>
                                        <ReactStars
                                          count={product.rating}
                                          color={"#ffd700"}
                                          isHalf={true}
                                          edit={false}
                                          size={24}
                                          activeColor="#ffd700"
                                        />
                                      </div>
                                      <div className="product--desc">
                                        {stringTrim(product.subtitle, 40)}
                                        {product.subtitle.length > 40
                                          ? "..."
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                          <div className="pagination--container">
                            <Pagination
                              current={currentPage}
                              defaultCurrent={currentPage}
                              total={search.totalPage * 10}
                              onChange={(e) => onPageChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="no-result-container">
                          <img src={noResultImage} alt="" />
                          <p className="title">
                            Opps.. We didn't find anything that matches this
                            search.
                          </p>
                          <p>
                            Try search for something more general, change the
                            filters or check spelling mistake.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <FeatureContainer />
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

export default connect(mapStateToProps, {})(SearchResult);
