import React, { useState } from "react";
import Image1 from "../../components/Image";
import forgotPasswordImage from "../../images/forgot.jpg";
import { Input } from "antd";
import Button from "../../components/Button/Button";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import axios from "axios";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const VerifyCode = ({ ForgotPassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");

  const verifyCode = () => {
    if (!code) {
      return toast.error("Please enter code", { toastId: 106 });
    }
    dispatch({
      type: "VERIFY_USER_REQUEST",
      payload: {
        email: JSON.parse(localStorage.getItem("verifyEmail")),
        code: code,
      },
    });
  };
  const onresendCode = async (emails) => {
    setIsLoading(true);
    await axios
      // .post("https://classibazaar.com.au/api/v2/resend_activation_code", {
      .post("/resend_activation_code", {
        email: emails,
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message, { toastId: 1066 });
      })
      .catch((err) => setIsLoading(false));
  };

  return (
    <div className="login-container">
      <Navbar />
      {ForgotPassword.isLoading || isLoading ? (
        <Spinner />
      ) : (
        <div className="verifycode--container">
          <div className="loginBox forgot-password-container">
            <img src={forgotPasswordImage} alt="" />
            <div className="right-container">
              <p className="title">Verification Code</p>
              <p className="subtitle">
                We have sent the code to your email.Please Check your Email!
              </p>

              <Input
                style={{ height: "50px", marginBottom: "1rem" }}
                placeholder="Enter verification code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <p
                onClick={() =>
                  onresendCode(JSON.parse(localStorage.getItem("email")))
                }
              >
                Resend Code
              </p>
              <Button text="Verify" onClick={verifyCode} />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ForgotPassword: state.ForgotPassword,
  };
};

export default connect(mapStateToProps, {})(VerifyCode);
