import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { FaUserAlt } from "react-icons/fa";
import { GiCube } from "react-icons/gi";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { MdMobileFriendly } from "react-icons/md";
import Personal from "./Personal";
import "./Profile.css";
import Purchase from "./Purchase";
import Payment from "./Payment";
import Shipping from "./Shipping";
import Refer from "./Refer";
import { connect, useDispatch } from "react-redux";
import { MdCancel } from "react-icons/md";
import FeatureContainer from "../../components/Feature/FeatureContainer";

import { FaUser } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import Button from "./../../components/Button/Button";
import Spinner from "../../components/spinner/Spinner";
import { Input } from "antd";
import validator from "validator";
import { toast } from "react-toastify";
import LocationInput from "./LocationInput";
import { RiLockPasswordFill } from "react-icons/ri";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const EditProfile = ({ profile, login }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("personal");
  const [profiles, setProfile] = useState(profile);
  const [address, setAddress] = useState(profile.profile.street_address);
  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);
  const [userData, setUserData] = useState({
    first_name: profiles.profile.first_name,
    last_name: profiles.profile.last_name,
    phone: profile.profile.user_mobile,
    email: profile.profile.user_email,
    address: address,
  });

  const editHandler = () => {
    if (userData.phone && !validatePhoneNumber(userData.phone)) {
      return toast.error("Invalid Phone Number");
    }
    if (!validateEmail(userData.email)) {
      return toast.error("Invalid Email Address");
    }
    dispatch({
      type: "EDIT_PROFILE_REQUEST",
      payload: {
        email: profile.profile.user_email,
        first_name: userData.first_name
          ? userData.first_name
          : profile.profile.first_name,
        last_name: userData.last_name
          ? userData.last_name
          : profile.profile.last_name,
        mobile: userData.phone ? userData.phone : profile.profile.user_mobile,
        address: address ? address : profile.profile.street_address,
      },
    });
  };

  const imageChangeHandler = (e) => {
    const imageFormData = new FormData();
    imageFormData.append("files", e.target.files[0]);
    dispatch({
      type: "CHANGE_PROFILE_REQUEST",
      payload: imageFormData,
    });
  };
  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    return isValidPhoneNumber;
  };
  const validateEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    return isValidEmail;
  };

  if (profile.isLoading) {
    return <Spinner />;
  }
  const handleChange = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => "")
      .catch((error) => "");
  };
  return (
    <div>
      <Navbar />

      <div style={{ marginTop: "-3rem" }}>
        <FeatureContainer />
      </div>

      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    login: state.login,
  };
};

export default connect(mapStateToProps, {})(EditProfile);
