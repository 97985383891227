import { toast } from "react-toastify";
import history from "../../utils/history";

const initialState = {
  featureProduct: [],
  popularProduct: [],
  preOrderProduct: [],
  recentProduct: [],
  isPopularProductLoading: false,
  isFeatureProductLoading: false,
  isPreOrderProductLoading: false,
  isRecentProductLoading: false,
  totalRecentPage: 1,
  totalPreOrderPage: 1,
  isError: false,
};

const DashboardProduct = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_FEATURE_PRODUCT_REQUEST":
      return {
        ...state,
        isFeatureProductLoading: true,
        isError: false,
      };
    case "FETCH_FEATURE_PRODUCT_SUCCESS":
      return {
        ...state,
        isFeatureProductLoading: false,
        featureProduct: payload,
        isError: false,
      };
    case "FETCH_FEATURE_PRODUCT_FAILURE":
      return {
        ...state,
        isFeatureProductLoading: false,
        isError: true,
      };

    case "FETCH_POPULAR_PRODUCT_REQUEST":
      return {
        ...state,
        isPopularProductLoading: true,
        isError: false,
      };
    case "FETCH_POPULAR_PRODUCT_SUCCESS":
      return {
        ...state,
        isPopularProductLoading: false,
        popularProduct: payload,
        isError: false,
      };
    case "FETCH_POPULAR_PRODUCT_FAILURE":
      return {
        ...state,
        isPopularProductLoading: false,
        isError: true,
      };

    case "FETCH_PREORDER_PRODUCT_REQUEST":
      return {
        ...state,
        isPreOrderProductLoading: true,
        isError: false,
      };
    case "FETCH_PREORDER_PRODUCT_SUCCESS":
      return {
        ...state,
        isPreOrderProductLoading: false,
        preOrderProduct: payload?.products,
        totalPreOrderPage: payload?.total_pages,
        isError: false,
      };
    case "FETCH_PREORDER_PRODUCT_FAILURE":
      return {
        ...state,
        isPreOrderProductLoading: false,
        isError: true,
      };

    case "FETCH_RECENT_PRODUCT_REQUEST":
      return {
        ...state,
        isPreOrderProductLoading: true,
        isError: false,
      };
    case "FETCH_RECENT_PRODUCT_SUCCESS":
      return {
        ...state,
        isPreOrderProductLoading: false,
        recentProduct: payload?.products,
        totalPreOrderPage: payload?.total_pages,
        isError: false,
      };
    case "FETCH_RECENT_PRODUCT_FAILURE":
      return {
        ...state,
        isPreOrderProductLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default DashboardProduct;
