import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import Button from "./../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { IoLocationSharp } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { BiEdit } from "react-icons/bi";
import { Drawer } from "antd";
import { Input } from "antd";
import validator from "validator";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const Personal = ({ profile, login }) => {
  const modalVisible = useSelector((state) => state.profile.modalVisible);
  const isProfileChanged = useSelector(
    (state) => state.profile.isProfileChanged
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const [userDatas, setUserDatas] = useState({
    first_name: profile.profile.first_name,
    last_name: profile.profile.last_name,
    phone: profile.profile.user_mobile,
    email: profile.profile.user_email,
    address: profile.profile.street_address,
    image: profile.profile.image,
  });

  const [active, setActive] = useState("personal");
  const [profiles, setProfile] = useState(profile);
  const [address, setAddress] = useState(profile.profile.street_address);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, [isProfileChanged]);
  const [userData, setUserData] = useState({
    first_name: profiles.profile.first_name,
    last_name: profiles.profile.last_name,
    phone: profile.profile.user_mobile,
    email: profile.profile.user_email,
    address: address,
  });

  const editHandler = () => {
    if (userData.phone && !validatePhoneNumber(userData.phone)) {
      return toast.error("Invalid Phone Number");
    }
    if (!validateEmail(userData.email)) {
      return toast.error("Invalid Email Address");
    }
    dispatch({
      type: "EDIT_PROFILE_REQUEST",
      payload: {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        mobile: userData.phone,
        address: address,
        goToBack: location.state && location.state.goToBack,
      },
    });
  };

  const imageChangeHandler = (e) => {
    const imageFormData = new FormData();
    imageFormData.append("files", e.target.files[0]);
    dispatch({
      type: "CHANGE_PROFILE_REQUEST",
      payload: imageFormData,
    });
  };
  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    return isValidPhoneNumber;
  };
  const validateEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    return isValidEmail;
  };

  const handleChange = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => "")
      .catch((error) => "");
  };

  function openModal() {
    setIsOpen(true);
  }

  const onClose = () => {
    setVisible(false);
  };

  if (profile.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "-webkit-fill-available",
        }}
      >
        <Spinner />;
      </div>
    );
  }

  return (
    <div className="personal-container">
      <div className="personal--top">
        <h1>Personal Details</h1>
        <span>Credits: ${profile.profile.classi_credit}</span>
      </div>
      <div class="card">
        <div class="img">
          <img
            className="customer--profile"
            src={
              profile.profile.image
                ? profile.profile.image
                : profile.profile.company_logo
            }
            alt=""
          />
        </div>
        <div class="infos">
          <div className="details">
            <div className="item">
              <FaUserAlt className="icon" />
              <span>
                {profile.profile.first_name} {profile.profile.last_name}
              </span>
            </div>
            <div className="item">
              <IoIosCall className="icon" />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {profile.profile.user_mobile ? (
                  profile.profile.user_mobile
                ) : (
                  <>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span> Add contact </span>{" "}
                      <BiEdit
                        onClick={() =>
                          dispatch({
                            type: "OPEN_MODAL",
                          })
                        }
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          marginLeft: "0.5rem",
                          color: "blue",
                        }}
                      />
                    </span>
                  </>
                )}
              </span>
            </div>
            <div className="item">
              <MdEmail className="icon" />
              <span>
                {profile.profile.user_email ? (
                  profile.profile.user_email
                ) : (
                  <>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span> Add Email </span>{" "}
                      <BiEdit
                        onClick={() =>
                          dispatch({
                            type: "OPEN_MODAL",
                          })
                        }
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          marginLeft: "0.5rem",
                          color: "blue",
                        }}
                      />
                    </span>
                  </>
                )}
              </span>
            </div>
            <div className="item">
              <IoLocationSharp className="icon" />
              <span>
                {profile.profile.street_address ? (
                  profile.profile.street_address
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <span> Add address </span>

                    <BiEdit
                      onClick={() =>
                        dispatch({
                          type: "OPEN_MODAL",
                        })
                      }
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "blue",
                      }}
                    />
                  </span>
                )}
              </span>
            </div>
            <Drawer
              title="Edit Your Profile"
              placement={"right"}
              closable={true}
              onClose={() => {
                dispatch({ type: "CLOSE_MODAL" });
              }}
              visible={modalVisible}
              height={"100vh"}
              // width={"78vw"}
              width={"100vw"}
              key={"top"}
            >
              <div className="profile_container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {" "}
                  <div
                    className="profile-edit-container"
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      padding: "30px",
                      marginTop: "25px",
                      height: "95%",
                      borderRadius: "1rem",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <div
                      className="change-profile"
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "center",
                      }}
                    >
                      {profile.isProfileChanged ? (
                        <img
                          src={profile.newImage}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            borderRadius: "0.5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={
                            profile.profile.image
                              ? profile.profile.image
                              : profile.profile.company_logo
                          }
                          alt=""
                          style={{
                            height: "5rem",
                            width: "5rem",
                            borderRadius: "0.5rem",
                          }}
                        />
                      )}

                      <div style={{ position: "relative" }}>
                        <button
                          style={{
                            background: "#1ccaaa",
                            borderRadius: "0.5rem",
                            color: "white",
                            cursor: "pointer",
                            outline: "none",
                            border: "none",
                            padding: "0.5rem",
                          }}
                        >
                          Upload Picture
                        </button>
                        <input
                          onChange={(e) => imageChangeHandler(e)}
                          type="file"
                          style={{
                            position: "absolute",
                            height: "40px",
                            zIndex: "2",
                            opacity: "0",
                            left: "0",
                            border: "2px solid red",
                            width: "200px",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <span>First name</span>

                      <Input
                        // value={profile.profile.first_name}
                        onChange={(e) =>
                          setUserData((prev) => {
                            return { ...prev, first_name: e.target.value };
                          })
                        }
                        id="filled-basic"
                        value={userData.first_name}
                        variant="filled"
                        placeholder="Enter Your First Name"
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </div>
                    <div>
                      <span>Last name</span>

                      <Input
                        onChange={(e) =>
                          setUserData((prev) => {
                            return { ...prev, last_name: e.target.value };
                          })
                        }
                        value={userData.last_name}
                        placeholder="Enter Your Last Name"
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </div>
                    <div>
                      <span>Phone</span>

                      <Input
                        onChange={(e) =>
                          setUserData((prev) => {
                            return { ...prev, phone: e.target.value };
                          })
                        }
                        id="filled-basic"
                        value={userData.phone}
                        variant="filled"
                        placeholder="Enter Your Phone Number"
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </div>
                    <div>
                      <span>Email</span>

                      <Input
                        onChange={(e) =>
                          setUserData((prev) => {
                            return { ...prev, email: e.target.value };
                          })
                        }
                        id="filled-basic"
                        value={userData.email}
                        variant="filled"
                        placeholder="Enter Your Email"
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </div>

                    <PlacesAutocomplete
                      value={address}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <span>Address</span>
                          <Input
                            placeholder="Enter Address"
                            style={{
                              width: "100%",
                              height: "2.5rem",
                              borderRadius: "0.5rem",
                            }}
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                            value={address}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span
                                    onClick={() =>
                                      setAddress(suggestion.description)
                                    }
                                  >
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>

                    <div
                      style={{ width: "100%", display: "flex", gap: "2rem" }}
                    >
                      <Button
                        onClick={editHandler}
                        text="Update"
                        style={{ width: "50%" }}
                      >
                        {" "}
                        <TiTick
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>

            <Button
              text="Edit"
              style={{ marginTop: "1.5rem" }}
              onClick={() =>
                dispatch({
                  type: "OPEN_MODAL",
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    login: state.login,
  };
};

export default connect(mapStateToProps, {})(Personal);
