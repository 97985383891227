import { toast } from "react-toastify";
import history from "../../utils/history";
import {
  getCookie,
  removeCookie,
  setCookie,
  setLocalStorage,
} from "../../FrontendHelper";

const initialState = {
  token: null,
  profile: {},
  loading: false,
  isLoggedIn: getCookie("token") ? true : false,
  newImage: JSON.parse(localStorage.getItem("profile"))
    ? JSON.parse(localStorage.getItem("profile")).company_logo
    : "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "Authenticate_User_Request":
      return {
        ...state,
        loading: true,
      };
    case "Authenticate_User_Success":
      window.location.replace("/");

      setCookie("token", payload.access_token);
      setLocalStorage("profile", {
        first_name: payload.profile.first_name,
        last_name: payload.profile.last_name,
        company_logo: payload.profile.image,
      });

      return {
        ...state,
        token: payload.access_token,
        isLoggedIn: true,
        profile: payload.profile,
        loading: false,
      };
    case "LOGOUT_USER":
      removeCookie("token");
      toast.success("Logout Successful", { toastId: 5 });
      localStorage.removeItem("profile");

      return {
        ...state,
        isLoggedIn: false,
        loading: false,
      };
    case "Authenticate_User_Failure":
      toast.error(payload.error.data.message, { toastId: 6 });
      setTimeout(() => {
        if (
          payload.error.data.message ===
          "Your account is not activated yet. Please enter the code sent in email to verify your email."
        ) {
          setLocalStorage("email", payload.email);
          history.push("/VERIFY_CODE");
        }
      }, [2000]);
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    case "LOGIN_WITH_GOOGLE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_WITH_GOOGLE_SUCCESS":
      window.location.replace("/");

      setLocalStorage("profile", {
        first_name: payload.profile.first_name,
        last_name: payload.profile.last_name,
        company_logo: payload.profile.image,
      });
      setCookie("token", payload.data.access_token);

      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        profile: {
          first_name: payload.profile.first_name,
          last_name: payload.profile.last_name,
          company_logo: payload.profile.image,
        },
        newImage: payload.profile.image,
      };
    case "LOGIN_WITH_GOOGLE_FAILURE":
      toast.error(payload.data.error, { toastId: 8 });
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
