import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BiPurchaseTag } from "react-icons/bi";
import { BsCalendarEvent } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import Spinner from "../../components/spinner/Spinner";
import Button from "../../components/Button/Button";
import { Modal } from "antd";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { getCookie } from "../../FrontendHelper";
import Moment from "react-moment";

const OrderDetails = (props) => {
  const [deal, setDeal] = useState();
  const [deal_slug, set_deal_slug] = useState(props.currentPurchase.deal_slug);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const [active, setActive] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (props.currentPurchase.deal_slug) {
      const getProductDetails = async () => {
        const response = await axios
          .get(
            // `https://classibazaar.com.au/api/deal/detail/${props.currentPurchase.deal_slug}`
            `/deal/detail/${props.currentPurchase.deal_slug}`
          )
          .catch((err) => {
            "";
          });

        setDeal(response.data.deal);
      };

      getProductDetails();
    }
    if (props.currentPurchase.id) {
      const getProductDetailss = async () => {
        const response = await axios
          .get(
            // `https://classibazaar.com.au/api/deal/product_order_detail/${props.currentPurchase.id}`,
            `https://classibazaar.com/api/deal/product_order_detail/${props.currentPurchase.id}`,
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          )
          .catch((err) => {
            "";
          });

        setOrderStatus(response.data.order_history);
      };

      getProductDetailss();
    }
    if (props.currentPurchase.current_status === "Pending") {
      setActive(0);
    }
    if (
      props.currentPurchase.current_status === "Pending" &&
      orderStatus.length > 4
    ) {
      setActive(1);
    }

    if (props.currentPurchase.current_status == "Processing") {
      setActive(2);
    }
    if (
      props.currentPurchase.current_status === "Processing" &&
      orderStatus.length === 4
    ) {
      setActive(1);
    }
    if (props.currentPurchase.current_status == "Ready to Dispatch") {
      setActive(3);
    }
    if (
      props.currentPurchase.current_status === "Ready to Dispatch" &&
      orderStatus.length === 4
    ) {
      setActive(2);
    }
    if (props.currentPurchase.current_status == "Dispatched") {
      setActive(4);
    }
    if (
      props.currentPurchase.current_status === "Dispatched" &&
      orderStatus.length === 4
    ) {
      setActive(3);
    }
    if (props.currentPurchase.current_status == "Delivered") {
      setActive(5);
    }
    if (
      props.currentPurchase.current_status === "Delivered" &&
      orderStatus.length === 4
    ) {
      setActive(4);
    }
  }, [props.currentPurchase, orderStatus]);

  return (
    <div className="order-detail-container">
      <div className="top">
        <BiArrowBack
          onClick={() => {
            props.setIsOrderDetailsOpen(false);
          }}
        />
        <span>Order detail</span>
      </div>
      <div className="middle">
        <div>
          <span>
            {" "}
            <BiPurchaseTag className="icon" /> ORDER NO
          </span>
          <span className="value">{props.currentPurchase.order_number}</span>
        </div>
        <div>
          <span>
            {" "}
            <BsCalendarEvent className="icon" /> ORDER DATE
          </span>
          <span className="value">{props.currentPurchase.order_date}</span>
        </div>
        {props.currentPurchase.shipping_address1 && (
          <div>
            <span>
              {" "}
              <GrLocation className="icon" /> DELIVERY ADDRESS
            </span>
            <span className="value">
              {props.currentPurchase.shipping_address1}
            </span>
          </div>
        )}
      </div>
      {props.currentPurchase.deal_type === "Service" ? (
        <Button
          onClick={showModal}
          text="View Voucher"
          style={{ margin: "auto", marginTop: "2rem" }}
        />
      ) : (
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          <Stepper activeStep={active} alternativeLabel>
            {orderStatus &&
              orderStatus.map((label) => (
                <Step key={label.status}>
                  <StepLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: "bolder" }}>
                        {label.status}
                      </span>
                      {label.added_at && (
                        <span className="date">
                          {" "}
                          <Moment format="YYYY/MM/DD" date={label.added_at} />
                        </span>
                      )}
                    </div>
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
        </Box>
      )}
      <Modal
        title="Voucher"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <QRCode value={props.currentPurchase.payment_pdf} />
        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Deal: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            {props.currentPurchase.dealstitle}
          </span>
        </div>

        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Product: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            {props.currentPurchase.product_name}
          </span>
        </div>
        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Quantity: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            {props.currentPurchase.qty}
          </span>
        </div>
        {props.currentPurchase.code && (
          <div
            className="inner"
            style={{
              display: "flex",
              gap: "2rem",
              justifyContent: "space-between",
            }}
          >
            <span className="attribute">Securty Code: </span>
            <span className="value" style={{ fontWeight: "bolder" }}>
              {props.currentPurchase.code}
            </span>
          </div>
        )}

        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Customer Name: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            {props.currentPurchase.first_name}
          </span>
        </div>
        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Original Price: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            ${props.currentPurchase.product_price}
          </span>
        </div>
        <div
          className="inner"
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <span className="attribute">Classideal Price: </span>
          <span className="value" style={{ fontWeight: "bolder" }}>
            ${props.currentPurchase.product_discounted_price}
          </span>
        </div>
        {props.currentPurchase.coupon_code && (
          <div
            className="inner"
            style={{
              display: "flex",
              gap: "2rem",
              justifyContent: "space-between",
            }}
          >
            <span className="attribute">Coupon code: </span>
            <span className="value" style={{ fontWeight: "bolder" }}>
              {props.currentPurchase.coupon_code}
            </span>
          </div>
        )}

        {props.currentPurchase.is_paid && (
          <div
            className="inner"
            style={{
              display: "flex",
              gap: "2rem",
              justifyContent: "space-between",
            }}
          >
            <span className="attribute">Amount Paid: </span>
            <span className="value" style={{ fontWeight: "bolder" }}>
              {props.currentPurchase.product_discounted_price}
            </span>
          </div>
        )}
      </Modal>

      <div
        className="order-information-container"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
      >
        <div className="middles">
          <p style={{ fontSize: "19px", fontWeight: "bold" }}>
            {props.currentPurchase.dealstitle}
          </p>
          <p>{props.currentPurchase.subtitle}</p>
          <div className="inner">
            <span className="attribute">Product Name: </span>
            <span className="value">{props.currentPurchase.product_name}</span>
          </div>
          <div className="inner">
            <span className="attribute">Product Color: </span>
            <span className="value">
              {props.currentPurchase.product_color
                ? props.currentPurchase.product_color
                : "Green"}
            </span>
          </div>
          <div className="inner">
            <span className="attribute">Quantity: </span>
            <span className="value">{props.currentPurchase.qty}</span>
          </div>
          <div className="inner">
            <span className="attribute">Per Product Price: </span>
            <span className="value">$ {props.currentPurchase.price}</span>
          </div>
          <div className="inner">
            <span className="attribute">Gross Total: </span>
            <span className="value">
              $ {props.currentPurchase.product_discounted_price}
            </span>
          </div>
        </div>
        {deal && (
          <>
            <div className="middles">
              <p style={{ fontSize: "19px", fontWeight: "bold" }}>Highlights</p>
              <li dangerouslySetInnerHTML={{ __html: deal.highlights }}></li>
            </div>
            <div className="middles">
              <p style={{ fontSize: "19px", fontWeight: "bold" }}>Fine Print</p>
              <li dangerouslySetInnerHTML={{ __html: deal.find_print }}></li>
            </div>
            <div className="middles">
              <p style={{ fontSize: "19px", fontWeight: "bold" }}>
                How to Redeem
              </p>
              <li dangerouslySetInnerHTML={{ __html: deal.how_to_redeem }}></li>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
