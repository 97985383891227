import { toast } from "react-toastify";
import history from "../../utils/history";

const initialState = {
  isLoading: false,
  preOrderPayload: {},
  preOrderPayment: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "STRIPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "STRIPE_REQUEST_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "STRIPE_REQUEST_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "MAKE_PAYMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "MAKE_PAYMENT_SUCCESS":
      setTimeout(() => {
        history.push("/result", {
          message: "Your order has been placed successfully.",
        });
      }, [500]);

      return {
        ...state,
        isLoading: false,
      };
    case "MAKE_PAYMENT_FAILURE":
      for (const key in payload.data.errors) {
        toast.error(payload.data.errors[key], { toastId: 9 });
      }
      return {
        ...state,
        isLoading: false,
      };
    case "SAVE_PRORDER_INFORMATION":
      return {
        ...state,
        preOrderPayload: payload,
      };
    case "MAKE_PREORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "MAKE_PREORDER_SUCCESS":
      // setTimeout(() => {
      //   history.push("/result", {
      //     message: "Deal has been booked successfully!.",
      //   });
      // }, [500]);
      return {
        ...state,
        isLoading: false,
      };
    case "MAKE_PREORDER_FAILURE":
      for (const key in payload.data.errors) {
        toast.error(payload.data.errors[key], { toastId: 9 });
      }
      return {
        ...state,
        isLoading: false,
      };
    case "SAVE_PREORDER_PAYMENT_INFO":
      return {
        ...state,
        preOrderPayment: payload,
      };
    case "MAKE_PREORDER_PAYMENT":
      return {
        ...state,
        isLoading: true,
      };
    case "MAKE_PREORDER_SUCCESS":
      toast.success("Your payment completed successfully.", { toastId: 90 });
      return {
        ...state,
        isLoading: false,
      };
    case "MAKE_PREORDER_FAILURE":
      toast.error(payload.message, { toastId: 89 });
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
