import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "./Search.css";
import OutsideAlerter from "../UseOutSideAlterter";
import Button from "../Button/Button";
import { GoSearch } from "react-icons/go";
import { Input } from "antd";
import "rc-slider/assets/index.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import { Button as But, Drawer, Tooltip } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import filterImage from "../../images/filter.png";

const Search = (props) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [all, setAll] = useState(false);
  const [cities, setCities] = useState([]);
  const [searchProduct, setSearchProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [openDistanceModal, setOpenDistanceModa] = useState(false);
  const [value, setValue] = React.useState([0, 100]);
  const [distance, setDistance] = useState([0, 0]);
  const [address, setAddress] = useState("");
  const wrapperRef = useRef(null);
  const [latlng, setLatlng] = useState();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState({
    location: "",
    price: "",
    distance: "",
  });
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
    const getData = async () => {
      const response = await axios.get(
        // "https://classibazaar.com.au/api/deal/products"
        "/deal/products"
      );
      setCities(response.data.cities);
      setSearchProduct(response.data.products);
    };
    getData();
  }, []);
  useEffect(() => {}, []);

  const searchHandler = () => {
    if (address) {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: active,
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
          query: search,
          lat: address ? latlng.lat : "",
          lng: address ? latlng.lng : "",
          lat: latlng.lat,
          lng: latlng.lng,
          distance: address ? distance[1] : "",
          distance: distance[1],
        },
      });
    } else {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: active,
          minPrice: value[0] * 10,
          maxPrice: value[1] * 10,
          query: search,
        },
      });
    }
  };

  const searchHandlerWithFilter = () => {
    if (props.address) {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: props.active,
          minPrice: props.value[0] * 10,
          maxPrice: props.value[1] * 10,
          query: search,

          lat: props.latlng.lat,
          lng: props.latlng.lng,

          distance: props.distance[1],
        },
      });
    } else {
      dispatch({
        type: "SEARCH_PRODUCT_REQUEST",
        payload: {
          city: props.active,
          minPrice: props.value[0] * 10,
          maxPrice: props.value[1] * 10,
          query: search,
        },
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeDistance = (event, newValue) => {
    if (!address) {
      return toast.error("Please enter your address first!", {
        toastId: 12542,
      });
    }
    setDistance(newValue);
  };

  const handleChanges = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatlng(latLng))
      .catch((error) => "");
  };

  return (
    <div
      className={
        props.onDesktop
          ? "searchBtns on-mobile-search remove-on-phone"
          : "searchBtns on-mobile-search"
      }
    >
      <div className="searchContainer">
        <GoSearch className="location-icon" />

        {show ? (
          <OutsideAlerter setShow={setShow} show={show} wrapperRef={wrapperRef}>
            <div className="dropDownMenuLocation" ref={wrapperRef}>
              {cities?.map((city, key) => {
                return (
                  <ul
                    key={key}
                    onClick={() =>
                      setSelectedData((prev) => {
                        return { ...prev, location: city.cityName };
                      })
                    }
                  >
                    <li className="li" onClick={() => setShow(!show)}>
                      {city.cityName}
                    </li>
                  </ul>
                );
              })}
            </div>
          </OutsideAlerter>
        ) : null}

        <input
          value={search}
          placeholder="Search Deals"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Drawer
          title="Filter your search results."
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <div className="left-container">
            <div className="top">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Filter by Locations
              </p>
              <div className="location-container">
                <p
                  onClick={() => {
                    setAll(!all);
                    setActive([]);
                  }}
                  className={all && "active"}
                >
                  All
                </p>
                {cities?.map((city, key) => {
                  return (
                    <p
                      onClick={() => {
                        setAll(false);
                        setActive((prev) =>
                          prev.includes(city.citycode)
                            ? prev.filter((el) => el !== city.citycode)
                            : [...prev, city.citycode]
                        );
                      }}
                      className={active.includes(city.citycode) ? "active" : ""}
                    >
                      {city.cityName}
                    </p>
                  );
                })}
                {/* <p className="active">Brisbane</p>
                <p>Sydney</p>
                <p>Melbourne</p>
                <p>Perth</p>
                <p>South Wales</p> */}
              </div>
            </div>
            <div className="middle">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                Filter By Price
              </p>
              {/* <Slider
                ariaLabelForHandle={"$faf"}
                value={price}
                onChange={(e) =>
                  setSelectedData((prev) => {
                    return { ...prev, price: e * 10 + "$" };
                  }, setPrice(e))
                }
              /> */}
              <Box>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                />
              </Box>
              <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                <span>${value[0] * 10}</span>
                <span>-</span>
                <span>${value[1] * 10}</span>
              </div>

              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                Filter By Distance
              </p>
              <PlacesAutocomplete
                value={address}
                onChange={handleChanges}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <span>Address:</span>
                    <Input
                      height={"50px"}
                      style={{ height: "50px" }}
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                      value={address}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions?.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              onClick={() => setAddress(suggestion.description)}
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <Slider
                value={distance}
                onChange={handleChangeDistance}
                valueLabelDisplay="auto"
              />
              <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                <span>{distance[0] * 10}km</span>
                <span>-</span>
                <span>{distance[1]}km</span>
              </div>
            </div>

            <Button text="Filter" onClick={onClose} />
          </div>
        </Drawer>

        <div style={{ display: "flex" }}>
          {" "}
          <Button
            className="searchBtn-mobile"
            onClick={showDrawer}
            style={{
              height: "40px",
              width: "20px",
              backgroundColor: "white",
              color: "black",
            }}
          >
            {" "}
            <img src={filterImage} style={{ height: "20px", width: "20px" }} />
          </Button>
          <Link to={"/search-results"}>
            <Button
              className="searchBtn-mobile"
              onClick={searchHandler}
              style={{
                height: "40px",
                width: "20px",
              }}
            >
              {" "}
              <SearchOutlined />
            </Button>
          </Link>
        </div>
        <Link to={"/search-results"}>
          {/* <Button
            className="searchBtn"
            text="Search"
            onClick={
              props.active ||
              props.address ||
              (props.value && props.value[0] !== 0) ||
              (props.value && props.value[1] !== 100)
                ? searchHandlerWithFilter
                : searchHandler
            }
            style={{
              height: "40px",
              marginTop: "1px",
              width: "90px",
              backgroundColor: "red",
              borderRadius: "0 1rem 1rem 0",
            }}
          ></Button> */}
          <button
            className="button--custome"
            onClick={
              props.active ||
              props.address ||
              (props.value && props.value[0] !== 0) ||
              (props.value && props.value[1] !== 100)
                ? searchHandlerWithFilter
                : searchHandler
            }
            style={{
              height: "40px",
              width: "90px",
              backgroundColor: "#e57c23",
              borderRadius: "0 1.5rem 1.5rem 0",
              outline: "none",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            Search{" "}
          </button>
        </Link>

        {openPriceModal && (
          <div className="modal">
            <div className="overlay"></div>
            <div
              className="modalContent"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="top-container"
                style={{
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "0.5rem",
                }}
              >
                <span style={{ fontWeight: "bold", marginLeft: "-10px" }}>
                  Price Filter
                </span>
                <Button
                  className="closeModal"
                  onClick={() => setOpenPriceModal(false)}
                >
                  X
                </Button>
              </div>
              <Box>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                />
              </Box>
              <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                <span>${value[0] * 10}</span>
                <span>-</span>
                <span>${value[1] * 10}</span>
              </div>
              <Button
                text="Filter"
                style={{ marginTop: "8px" }}
                onClick={() => setOpenPriceModal(false)}
              />
            </div>
          </div>
        )}
        {openDistanceModal && (
          <div className="modal">
            <div className="overlay"></div>
            <div
              className="modalContent"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="top-container"
                style={{
                  width: "100%",
                  // backgroundColor: "#F2F5FF",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "0.5rem",
                }}
              >
                <span style={{ fontWeight: "bold", marginLeft: "-10px" }}>
                  Distance Filter
                </span>
                <Button
                  className="closeModal"
                  onClick={() => setOpenDistanceModa(false)}
                >
                  X
                </Button>
              </div>
              <PlacesAutocomplete
                value={address}
                onChange={handleChanges}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <span>Address:</span>
                    <Input
                      height={"50px"}
                      style={{ height: "50px" }}
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                      value={address}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions?.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              onClick={() => setAddress(suggestion.description)}
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <Slider
                getAriaLabel={() => "Temperature range"}
                value={distance}
                onChange={handleChangeDistance}
                valueLabelDisplay="auto"
              />
              <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                <span>{distance[0] * 10}km</span>
                <span>-</span>
                <span>{distance[1]}km</span>
              </div>
              <Button
                text="Filter"
                style={{ marginTop: "8px" }}
                onClick={() => {
                  if (!address) {
                    return toast.error("Please enter your address!");
                  }
                  setOpenDistanceModa(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
