import { combineReducers } from "redux";
import login from "./login";
import register from "./register";
import ForgotPassword from "./ForgotPassword";
import search from "./search";
import profile from "./profile";
import wishlist from "./wishlist";
import gift from "./gift";
import review from "./review";
import Notification from "./Notification";
import payment from "./payment";
import DashboardProduct from "./DashboardProduct";

export const rootReducer = combineReducers({
  login: login,
  register: register,
  ForgotPassword: ForgotPassword,
  search: search,
  profile: profile,
  wishlist: wishlist,
  gift: gift,
  review: review,
  Notification: Notification,
  payment: payment,
  dashboardProduct: DashboardProduct,
});
