import React, { useEffect, useState } from "react";
import "./SavedCards.css";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

const SavedCards = ({ profile, setCustomerId }) => {
  const [activeCard, setActiveCard] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_SAVED_CARDS_REQUEST",
    });
  }, []);
  const onClick = (customerId) => {
    setCustomerId(customerId);
    if (activeCard) {
      setActiveCard("");
    } else {
      setActiveCard(customerId);
    }
  };

  return (
    <div style={{ display: "flex", margin: "1rem 0", flexDirection: "column" }}>
      {profile.myCards.length > 0 && <h2>Select Your Cards</h2>}
      {profile.myCards.length > 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {profile.myCards.map((card) => (
            <div
              onClick={() => {
                onClick(card.customer_id);
              }}
              class="credit-card visa selectable"
              style={
                activeCard == card.customer_id
                  ? { border: "5px solid var(--primary-color)" }
                  : {}
              }
            >
              <div class="credit-card-last4">{card.card_no}</div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(SavedCards);
