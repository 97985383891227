import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { DatePicker } from "antd";
import { Input } from "antd";
import { toast } from "react-toastify";
import validator from "validator";
import moment from "moment";
import { getCookie } from "../../FrontendHelper";
import history from "../../utils/history";

const Gift = ({ gift, profile }) => {
  const dispatch = useDispatch();
  const { variant_id } = useParams();
  const [productDetails, setProductDetails] = useState();

  const { productId } = useParams();
  const { PRODUCT_ID } = useParams();

  const [quantity, setQuantity] = useState(localStorage.getItem("qty"));
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [value, setValue] = React.useState(new Date().toLocaleString());

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
    const getDealDetail = async () => {
      const deal = await axios
        // .get(`https://.classibazaarcom.au/ap/deal/detail/${productId}`)
        .get(`/deal/detail/${productId}`)
        .catch((err) => {
          "";
        });
      setProductDetails(deal.data);
    };
    getDealDetail();
  }, []);

  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const { name, email, mobile, message } = userData;

  const submitHandler = () => {
    if (!name || !email || !mobile || !message) {
      return toast.error("Please enter all the fields", { toastId: 200 });
    }
    if (!validator.isEmail(email)) {
      return toast.error("Please enter valid email", { toastId: 20000 });
    }
    if (!validator.isMobilePhone(mobile)) {
      return toast.error("Please enter valid phone number", {
        toastId: 200000000000,
      });
    }

    dispatch({
      type: "SEND_GIFT_DATA_SEND_REQUEST",
      payload: {
        deal_product_id: productDetails.deal.id,
        name: name,
        email: email,
        contact_number: mobile,
        message: message,
        slug: productDetails.deal.deal_slug,
        PRODUCT_ID: PRODUCT_ID,
        variant_id: variant_id,
      },
    });
    // axios
    //   .post(
    //     `https://classibazaar.com/api/deal/send_gift/${productDetails?.deal?.id}`,
    //     {
    //       deal_product_id: productDetails.deal.id,
    //       name: name,
    //       email: email,
    //       contact_number: mobile,
    //       message: message,
    //       slug: productDetails.deal.deal_slug,
    //       PRODUCT_ID: PRODUCT_ID,
    //       variant_id: variant_id,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${getCookie("token")}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log("res res saroj", res);
    //     if (res?.variant_id) {
    //       history.push(
    //         `/payment/${productDetails?.deal?.deal_slug}/${PRODUCT_ID}/${variant_id}`
    //       );
    //     } else {
    //       history.push(
    //         `/payment/${productDetails?.deal?.deal_slug}/${PRODUCT_ID}`
    //       );
    //     }
    //   });
  };
  function onChange(date, dateString) {}

  // if (gift.isLoading) {
  //   return <Spinner />;
  // }

  return (
    <>
      <Navbar />
      {gift.isLoading ? (
        <Spinner />
      ) : productDetails ? (
        <div className="product--container">
          <div className="gift--container--layout">
            <div className="paymentLeft">
              <h2></h2>
              <div className="paymentForms">
                <form class="checkout" style={{ marginTop: "2rem" }}>
                  <div class="checkout-header">
                    <h1 class="checkout-title">Customize Your Gift</h1>
                  </div>
                  <div className="gift--container">
                    <div className="gift--inner--container">
                      <label>
                        <span style={{ color: "black" }}>
                          <span style={{ color: "red" }}>*</span> To
                        </span>
                      </label>
                      <Input
                        name="name"
                        type="text"
                        value={name}
                        placeholder="e.g abc@gmail.com"
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </div>
                    <div className="gift--inner--container">
                      <label>
                        <span style={{ color: "black" }}>
                          <span style={{ color: "red" }}>*</span> Recepient's
                          Email
                        </span>
                      </label>
                      <Input
                        name="email"
                        type="email"
                        placeholder="e.g xyz@gmail.com"
                        value={email}
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </div>
                    <div className="gift--inner--container">
                      <label>
                        <span style={{ color: "black" }}>
                          <span style={{ color: "red" }}>*</span> Recepient's
                          Mobile
                        </span>
                      </label>
                      <Input
                        name="mobile"
                        type="number"
                        placeholder="Enter Your Mobile Number"
                        value={mobile}
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </div>
                    <div className="gift--inner--container">
                      {" "}
                      <label>
                        <span style={{ color: "black" }}>
                          <span style={{ color: "red" }}>*</span> Message
                        </span>
                      </label>
                      <textarea
                        name="message"
                        placeholder="abc....."
                        style={{
                          height: "100px",
                          outline: "none",
                          border: "1px solid rgba(128, 128, 128, 0.294)",
                          padding: "1rem",
                        }}
                        cols="30"
                        rows="10"
                        onChange={(e) => onChangeHandler(e)}
                        value={message}
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="paymentForm" style={{ marginTop: "1rem" }}>
            <form>
              <label>Message</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                style={{ height: "100px" }}
              ></textarea>
            </form>
          </div> */}
            </div>

            <div className="paymentRight">
              <h3 style={{ fontSize: " 18px" }}>Order Summary</h3>
              <div className="reviewBox">
                {/* <h3>Review Order</h3> */}
                <div className="paymentReview">
                  <div className="reviewLeft">
                    <h3 style={{ marginBottom: "-10px" }}>ITEM</h3>
                    <div className="image-row">
                      <img
                        style={{ height: "120px", width: "120px" }}
                        src={productDetails.main_image.thumbnail}
                        alt=""
                        className="paymentImg"
                      />
                      <div className="reviewRight">
                        <div className="paymentQty">
                          <h3>QTY</h3>
                          <p>{quantity}</p>
                        </div>
                      </div>
                      <div className="paymentTotal">
                        <h3>TOTAl</h3>
                        <p>
                          {productDetails.products.filter(
                            (product) => product.id === PRODUCT_ID
                          )[0].price_after_discount * quantity}
                        </p>
                      </div>
                    </div>
                    <h3>{productDetails.deal.dealstitle}</h3>
                    <p>{productDetails.deal.subtitle}</p>
                  </div>
                </div>
                <div className="reviewBottom">
                  {/* <div className="paymentGrandTotal">
                  <h3>Subtotal</h3>
                  <h3>
                    $
                    {productDetails.products.filter(
                      (product) => product.id === PRODUCT_ID
                    )[0].product_price * quantity}
                  </h3>
                </div>
                <div className="paymentGrandTotal">
                  <h3>Discount</h3>
                  <h3>
                    -$
                    {(productDetails.products.filter(
                      (product) => product.id === PRODUCT_ID
                    )[0].product_price *
                      productDetails.products.filter(
                        (product) => product.id === PRODUCT_ID
                      )[0].discount) /
                      100}
                  </h3>
                </div> */}
                  <div className="paymentGrandTotal">
                    <h3 style={{ fontWeight: "bold" }}>Grand Total</h3>
                    <h3>
                      $
                      {productDetails.products.filter(
                        (product) => product.id === PRODUCT_ID
                      )[0].price_after_discount * quantity}
                    </h3>
                  </div>
                </div>
                {/* <div className="paymentReviewBtns">
                <button style={{ backgroundColor: "#40b566" }}>
                  PROCEED ORDER
                </button>
                <button style={{ backgroundColor: "#ea4335" }}>
                  CANCEL ORDER
                </button>
              </div> */}
              </div>
              <div className="paymentReviewBtns">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <h3 style={{ alignSelf: "center" }}>Send Gift</h3>

                  <DatePicker
                    onChange={onChange}
                    showToday
                    defaultValue={moment()}
                  />
                </div>

                <Button
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                    padding: "10px",
                  }}
                  onClick={submitHandler}
                >
                  Continue to confirm purchase
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      <FeatureContainer />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gift: state.gift,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(Gift);
