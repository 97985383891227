import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import StripeAPi from "../payment/StripeAPi";
import { Input } from "antd";
import { toast } from "react-toastify";
import history from "../../utils/history";
import { BsFillCartCheckFill } from "react-icons/bs";
import "./preorder.css";
import validator from "validator";
import { getCookie } from "../../FrontendHelper";

const PreOrder = ({ gift, profile, payment }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!profile.profile.user_mobile) {
    //   dispatch({
    //     type: "OPEN_MODAL",
    //   });
    //   toast.info("Please update your profile first");

    //   return history.push("/your-profile", { goToBack: true });
    // }
    dispatch({
      type: "GET_SAVED_CARDS_REQUEST",
    });
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);

  const [productDetails, setProductDetails] = useState();
  const { productId } = useParams();
  const { variant_id } = useParams();
  const { PRODUCT_ID } = useParams();
  const [quantity, setQuantity] = useState(localStorage.getItem("qty"));
  const [totalAmmount, setTotalAmmount] = useState("");
  const [preorderFee, setPreOrderFee] = useState("");
  const ammountRef = useRef();

  setTimeout(() => {
    setTotalAmmount(ammountRef?.current?.textContent);
  }, [2000]);

  const [cardInfo, setCardInfo] = useState({
    number: "",
    month: "",
    year: "",
    name: "",
    cvv: "",
    months: "",
  });

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    setUserData({
      first_name: profile?.profile?.first_name,
      last_name: profile?.profile?.last_name,
      phone: profile?.profile?.user_mobile,
      email: profile?.profile?.user_email,
    });
  }, [profile?.profile]);
  const [customerId, setCustomerId] = useState("");
  const [value, setValue] = React.useState(new Date().toLocaleString());
  const [productID, setProductID] = useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDealDetail = async () => {
      const deal = await axios
        // .get(`https://classibazaar.com.au/api/deal/detail/${productId}`)
        .get(`/deal/detail/${productId}`)
        .catch((err) => {
          "";
        });
      setProductDetails(deal.data);
      setPreOrderFee(deal.data.deal.pre_order_fee);

      setTotalAmmount((preorderFee / 100) * totalAmmount);
      setProductID(PRODUCT_ID);
    };
    getDealDetail();
  }, []);

  const onChangeHandler2 = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const onChangeHandler = (e) => {
    setCardInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onPayment = async () => {
    try {
      if (customerId) {
        dispatch({
          type: "STRIPE_REQUEST",
        });
        if (totalAmmount > 0) {
          const chargeResponse = await StripeAPi.charges({
            postData: {
              amount: totalAmmount.toString(),
            },
            orderId: {
              first_name: profile.profile.first_name,
              last_name: profile.profile.last_name,
              email: profile.profile.user_email,
            },
            customers: customerId,
          });

          axios
            .post(
              // `https://classibazaar.com.au/api/deal/book`,
              `/deal/book`,
              {
                deal_id: productDetails.deal.id.toString(),
                productid: PRODUCT_ID.toString(),
                deal_variant_id: variant_id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.phone,
                email: userData.email,
                qty: quantity,
                user_id: profile.profile.userid,
                transaction_id: chargeResponse.id,
                coupon_code: "kjkjbkjbjk",
              },
              {
                headers: {
                  Authorization: `Bearer ${getCookie("token")}`,
                },
              }
            )
            .then((res) =>
              history.push("/result", {
                message: "Deal has been booked successfully!.",
              })
            );
        } else {
          await axios
            .post(
              // `https://classibazaar.com.au/api/deal/book`,
              `/deal/book`,
              {
                deal_id: productDetails.deal.id.toString(),
                productid: PRODUCT_ID.toString(),
                deal_variant_id: variant_id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.phone,
                email: userData.email,
                qty: quantity,
                user_id: profile.profile.userid,
                transaction_id: "000001",
                coupon_code: "kjkjbkjbjk",
              },
              {
                headers: {
                  Authorization: `Bearer ${getCookie("token")}`,
                },
              }
            )
            .then((res) =>
              history.push("/result", {
                message: "Deal has been booked successfully!.",
              })
            );
        }
        dispatch({
          type: "STRIPE_REQUEST_SUCCESS",
        });

        // if (!validator.isMobilePhone(userData.phone)) {
        //   return toast.error("Please enter valid phone number!", {
        //     toastId: 1236,
        //   });
        // }

        // dispatch({
        //   type: "MAKE_PREORDER_REQUEST",
        //   payload: {
        //     deal_id: productDetails.deal.id.toString(),
        //     productid: PRODUCT_ID.toString(),
        //     deal_variant_id: variant_id,
        //     first_name: userData.first_name,
        //     last_name: userData.last_name,
        //     phone: userData.phone,
        //     email: userData.email,
        //     qty: quantity,
        //     user_id: profile.profile.userid,
        //     // transaction_id: chargeResponse.id,
        //     transaction_id: chargeResponse ? chargeResponse.id : "000001",
        //     coupon_code: "kjkjbkjbjk",
        //   },
        // });
      } else {
        dispatch({
          type: "STRIPE_REQUEST",
        });
        const stripeResponse = await StripeAPi.tokens({
          card: cardInfo,
        });

        const customerResposne = await StripeAPi.customers({
          id: stripeResponse.id,
          email: profile.profile.user_email,
        });
        let chargeResponse;
        if (totalAmmount > 0) {
          chargeResponse = await StripeAPi.charges({
            postData: {
              amount: totalAmmount.toString(),
            },
            orderId: {
              first_name: profile.profile.first_name,
              last_name: profile.profile.last_name,
              email: profile.profile.user_email,
            },
            customers: customerResposne.id,
          });
        }

        dispatch({
          type: "STRIPE_REQUEST_SUCCESS",
        });

        if (!validator.isMobilePhone(userData.phone)) {
          return toast.error("Please enter valid phone number!", {
            toastId: 1236,
          });
        }

        // dispatch({
        //   type: "MAKE_PREORDER_REQUEST",
        //   payload: {
        //     deal_id: productDetails.deal.id.toString(),
        //     productid: PRODUCT_ID.toString(),
        //     deal_variant_id: variant_id,
        //     first_name: userData.first_name,
        //     last_name: userData.last_name,
        //     phone: userData.phone,
        //     email: userData.email,
        //     qty: quantity,
        //     user_id: profile.profile.userid,
        //     // transaction_id: chargeResponse.id,
        //     transaction_id: chargeResponse ? chargeResponse.id : "000001",
        //     coupon_code: "kjkjbkjbjk",
        //   },
        // });

        axios
          .post(
            // `https://classibazaar.com.au/api/deal/book`,
            `/deal/book`,
            {
              deal_id: productDetails.deal.id.toString(),
              productid: PRODUCT_ID.toString(),
              deal_variant_id: variant_id,
              first_name: userData.first_name,
              last_name: userData.last_name,
              phone: userData.phone,
              email: userData.email,
              qty: quantity,
              user_id: profile.profile.userid,
              transaction_id: chargeResponse ? chargeResponse.id : "000001",
              coupon_code: "kjkjbkjbjk",
            },
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          )
          .then((res) =>
            history.push("/result", {
              message: "Deal has been booked successfully!.",
            })
          );
      }
    } catch (error) {
      dispatch({
        type: "STRIPE_REQUEST_FAILURE",
      });
      toast.error(error.response.data.error.code);
    }
  };

  const { number, year, month, name, cvv, months } = cardInfo;

  return (
    <>
      <Navbar />

      {profile.isLoading || payment.isLoading ? (
        <Spinner />
      ) : productDetails ? (
        <div className="product--container">
          <div className="payment">
            {profile.isLoading ? (
              <Spinner />
            ) : (
              <>
                <div className="paymentLeft">
                  <form class="checkout">
                    <div class="checkout-header">
                      <h1 class="checkout-title">Pre Order Details </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <span>
                          <span style={{ color: "red" }}>*</span> First-Name
                        </span>
                        <Input
                          name="first_name"
                          style={{
                            height: "2.5rem",
                            borderRadius: "0.5rem",
                          }}
                          type="text"
                          value={userData.first_name}
                          onChange={(e) => onChangeHandler2(e)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <span>
                          <span style={{ color: "red" }}>*</span> Last-Name
                        </span>
                        <Input
                          style={{
                            height: "2.5rem",
                            borderRadius: "0.5rem",
                          }}
                          name="last_name"
                          type="text"
                          value={userData.last_name}
                          onChange={(e) => onChangeHandler2(e)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <span>
                          <span style={{ color: "red" }}>*</span> Email
                        </span>
                        <Input
                          style={{
                            height: "2.5rem",
                            borderRadius: "0.5rem",
                          }}
                          name="email"
                          type="email"
                          value={userData.email}
                          onChange={(e) => onChangeHandler2(e)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <span>
                          <span style={{ color: "red" }}>*</span> Contact
                        </span>
                        <Input
                          style={{
                            height: "2.5rem",
                            borderRadius: "0.5rem",
                          }}
                          name="phone"
                          onChange={(e) => onChangeHandler2(e)}
                          value={userData.phone}
                          type="number"
                        />
                      </div>
                    </div>
                  </form>

                  <form class="checkout" style={{ marginTop: "2rem" }}>
                    <div class="checkout-header">
                      <h1 class="checkout-title">
                        Checkout
                        <span class="checkout-price" style={{ width: "70px" }}>
                          $
                          {productDetails.deal.pre_order_fee_type ===
                          "Percentage"
                            ? (
                                (preorderFee *
                                  productDetails.products.filter(
                                    (product) => product.id === PRODUCT_ID
                                  )[0].price_after_discount *
                                  quantity) /
                                100
                              ).toFixed(2)
                            : preorderFee}
                        </span>
                      </h1>
                    </div>

                    <p>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        {profile.myCards.length > 0 && (
                          <h2>Select Your Cards</h2>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          {profile.myCards.length > 0 ? (
                            profile.myCards.map((card) => (
                              <div
                                onClick={() => {
                                  if (customerId) {
                                    setCustomerId("");
                                  } else {
                                    setCustomerId(card.customer_id);
                                  }
                                }}
                                class="credit-card visa selectable"
                                style={
                                  customerId == card.customer_id
                                    ? {
                                        border:
                                          "5px solid var(--primary-color)",
                                      }
                                    : {}
                                }
                              >
                                <div class="credit-card-last4">
                                  {card.card_no}
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {profile.myCards.length > 0 && (
                        <div className="design--or">
                          <div></div>
                          <span>or</span>
                          <div></div>
                        </div>
                      )}
                    </p>

                    <div className="card--detail--container">
                      <div className="card--input--container">
                        <span>Your Name</span>

                        <input
                          type="text"
                          class="checkout-input checkout-name"
                          placeholder="Enter Your name"
                          autofocus
                          name="name"
                          value={name}
                          onChange={(e) => onChangeHandler(e)}
                        />
                      </div>
                      <div className="card--input--container">
                        <span>Card Number</span>
                        <input
                          type="text"
                          class="checkout-input checkout-card"
                          placeholder="e.g 4242 4242 4242 4242"
                          name="number"
                          value={number}
                          onChange={(e) => onChangeHandler(e)}
                        />
                      </div>
                      <div className="card--detail--sub--container">
                        <div className="card--input--container">
                          <span>CVC</span>

                          <input
                            type="password"
                            class="checkout-input checkout-cvc"
                            placeholder="Enter Your CVC"
                            name="cvv"
                            value={cvv}
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                        <div className="card--input--container">
                          <span>Card Date</span>
                          <input
                            type="text"
                            class="checkout-input checkout-exp"
                            placeholder="MM/YY"
                            name="months"
                            value={
                              months.length == 2 ? months.concat("/") : months
                            }
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="paymentReviewBtns"
                      style={{ margin: "1rem 0" }}
                    >
                      <Button
                        onClick={onPayment}
                        style={{
                          width: "100%",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <BsFillCartCheckFill style={{ fontSize: "18px" }} />
                        Pay Pre-Order Fee
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="paymentRight">
                  <h3 style={{ fontSize: " 18px" }}>Order Summary</h3>
                  <div className="reviewBox">
                    {/* <h3>Review Order</h3> */}
                    <div className="paymentReview">
                      <div className="reviewLeft">
                        <h3 style={{ marginBottom: "-10px" }}>ITEM</h3>
                        <div className="image-row">
                          <img
                            onClick={history.back}
                            style={{ height: "120px", width: "120px" }}
                            src={productDetails.main_image.thumbnail}
                            alt=""
                            className="paymentImg"
                          />
                          <div
                            className="reviewRight"
                            style={{ width: "100%" }}
                          >
                            <div className="paymentQty">
                              <h3>QTY</h3>
                              <p>{quantity}</p>
                            </div>
                          </div>
                          <div
                            className="paymentTotal"
                            style={{ marginLeft: "60px" }}
                          >
                            <h3>TOTAL</h3>
                            <p>
                              {(
                                productDetails.products.filter(
                                  (product) => product.id === PRODUCT_ID
                                )[0].price_after_discount * quantity
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                        <h3>{productDetails.deal.dealstitle}</h3>
                        <p>{productDetails.deal.subtitle}</p>
                      </div>
                    </div>
                    <div className="reviewBottom">
                      {/* <div className="paymentGrandTotal">
                  <h3>Subtotal</h3>
                  <h3>
                    $
                    {productDetails.products.filter(
                      (product) => product.id === PRODUCT_ID
                    )[0].product_price * quantity}
                  </h3>
                </div>
                <div className="paymentGrandTotal">
                  <h3>Discount</h3>
                  <h3>
                    -$
                    {(productDetails.products.filter(
                      (product) => product.id === PRODUCT_ID
                    )[0].product_price *
                      productDetails.products.filter(
                        (product) => product.id === PRODUCT_ID
                      )[0].discount) /
                      100}
                  </h3>
                </div> */}
                      <div className="paymentGrandTotal">
                        <h3 style={{ fontWeight: "bold" }}>Grand Total</h3>
                        <h3>
                          $
                          {(
                            productDetails.products.filter(
                              (product) => product.id === PRODUCT_ID
                            )[0].price_after_discount * quantity
                          ).toFixed(2)}
                        </h3>
                      </div>
                      <div className="paymentGrandTotal">
                        <h3 style={{ fontWeight: "bold" }}>Pre-order fee</h3>
                        <h3>
                          $
                          <span ref={ammountRef}>
                            {productDetails.deal.pre_order_fee_type ===
                            "Percentage"
                              ? (
                                  (preorderFee *
                                    productDetails.products.filter(
                                      (product) => product.id === PRODUCT_ID
                                    )[0].price_after_discount *
                                    quantity) /
                                  100
                                ).toFixed(2)
                              : preorderFee}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      <FeatureContainer />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gift: state.gift,
    profile: state.profile,
    payment: state.payment,
  };
};

export default connect(mapStateToProps, {})(PreOrder);
