import React, { useState, useEffect } from "react";
import "./Login.css";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../actions/login";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import Navbar from "../../components/navbar/Navbar";

import Footer from "../../components/footer/Footer";

import NewInput from "../../components/NewInput";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Login = ({ login, error, isLoggedIn, loading, token }) => {
  const dispatch = useDispatch();
  const [loginn, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          dispatch({
            type: "LOGIN_WITH_GOOGLE_REQUEST",
            payload: {
              first_name: res.data.given_name,
              last_name: res.data.family_name,
              token: res.data.id,
              email: res.data.email,
              is_apple: null,
              image: res.data.picture,
            },
          });
        });
    },
  });

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChangeVisible = () => {
    setPasswordVisible((prev) => !prev);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { email, password } = userData;
  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = async () => {
    if (!email || !password) {
      return toast.error("Please enter both email and password");
    }
    dispatch({
      type: "Authenticate_User_Request",
      payload: {
        email,
        password,
      },
    });

    if (error) {
      return toast.error(error);
    }
    setUserData({
      email: "",
      password: "",
    });
  };

  const submitHandlerKey = async (e) => {
    if (e.key === "Enter") {
      if (!email || !password) {
        return toast.error("Please enter both email and password");
      }
      dispatch({
        type: "Authenticate_User_Request",
        payload: {
          email,
          password,
        },
      });
      if (error) {
        return toast.error(error);
      }
      setUserData({
        email: "",
        password: "",
      });
    }
  };

  const onSuccess = (res) => {
    dispatch({
      type: "LOGIN_WITH_GOOGLE_REQUEST",
      payload: {
        first_name: res.profileObj.givenName,
        last_name: res.profileObj.familyName,
        token: res.profileObj.googleId,
        email: res.profileObj.email,
        is_apple: null,
        image: res.profileObj.imageUrl,
      },
    });
  };

  const responseFacebook = (response) => {
    response.status !== "unknown" &&
      dispatch({
        type: "LOGIN_WITH_FACEBOOK_REQUEST",
        payload: {
          first_name: response.name?.split(" ")[0],
          last_name: response.name?.split(" ")[1],
          token: response.id,
          email: response.email,
          is_apple: null,
          image: response.picture.data.url,
        },
      });
  };
  setTimeout(() => {
    setLogin(true);
  }, [2000]);

  return (
    <>
      <div className="login-container">
        <div style={{ width: "100%" }}>
          <Navbar />
        </div>
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="login--container">
                <div className="login--body--container">
                  <span className="sign--in">Welcome Back</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      Email
                    </span>
                    <div className="input--container">
                      <NewInput
                        value={email}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter your email"
                        name="email"
                        className="input--field"
                        required
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      Password
                    </span>
                    <div className="input--container">
                      <NewInput
                        value={password}
                        type={passwordVisible ? "text" : "password"}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter your password"
                        name="password"
                        className="input--field"
                        onKeyPress={submitHandlerKey}
                        required
                      />
                      {passwordVisible ? (
                        <AiOutlineEye
                          className="eye--logo"
                          onClick={handleChangeVisible}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="eye--logo"
                          onClick={handleChangeVisible}
                        />
                      )}
                    </div>
                  </div>

                  <button className="button--style" onClick={submitHandler}>
                    Sign in
                  </button>
                  <Link
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#609966",
                    }}
                    className="forgot--password"
                    to={"/forgot-password"}
                  >
                    Forgot Your Password?
                  </Link>

                  <div className="design--or">
                    <div></div>
                    <span>or</span>
                    <div></div>
                  </div>
                  <div className="social--login--container">
                    <GoogleLogin
                      render={() => (
                        <span
                          onClick={() => googleLogin()}
                          class="social-button"
                          id="google-connect"
                        >
                          {" "}
                          <span>Continue with Google</span>
                        </span>
                      )}
                    ></GoogleLogin>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Don't have an account?
                    <Link to={"/signup"}>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#609966",
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Sign up
                      </span>
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.login.error,
    isLoggedIn: state.login.isLoggedIn,
    loading: state.login.loading,
    token: state.login.token,
    isLoggedIn: state.login.isLoggedIn,
  };
};

export default connect(mapStateToProps, { login })(Login);
