import React, { useEffect, useState } from "react";
import "../login/Login.css";
import { Link, Navigate } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import Button from "../../components/Button/Button";
import otpImage from "../../images/otp.jpg";
import "./Signup.css";
import { Input as AntInput } from "antd";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import NewInput from "../../components/NewInput";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Image1 from "../../components/Image";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Signup = ({ register, login, error, isLoggedIn, loading, token }) => {
  const clientId =
    "484800453002-c50t3gka26sols4g2pva4alunj8hcpve.apps.googleusercontent.com";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChangeVisible = () => {
    setPasswordVisible((prev) => !prev);
  };
  const { email, password, firstName, lastName } = userData;
  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = () => {
    dispatch({
      type: "REGISTER_USER_REQUEST",
      payload: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      },
    });
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
  };
  const verifyOTP = () => {
    if (!OTP) {
      return toast.error("Please enter OTP Code", { toastId: 101 });
    }
    dispatch({
      type: "VERIFY_OTP_REQUEST",
      payload: {
        email: JSON.parse(localStorage.getItem("email")),
        code: OTP,
      },
    });
  };

  const onresendCode = async (emails) => {
    setIsLoading(true);
    await axios
      // .post("https://classibazaar.com.au/api/v2/resend_activation_code", {
      .post("/resend_activation_code", {
        email: emails,
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message, { toastId: 1066 });
      })
      .catch((err) => setIsLoading(false));
  };

  const onSuccess = (res) => {
    dispatch({
      type: "LOGIN_WITH_GOOGLE_REQUEST",
      payload: {
        first_name: res.profileObj.givenName,
        last_name: res.profileObj.familyName,
        token: res.profileObj.googleId,
        email: res.profileObj.email,
        is_apple: "null",
        image: res.profileObj.imageUrl,
      },
    });
  };

  const onFailure = (res) => {};

  const responseFacebook = (response) => {
    dispatch({
      type: "LOGIN_WITH_FACEBOOK_REQUEST",
      payload: {
        first_name: response.name.split(" ")[0],
        last_name: response.name.split(" ")[1],
        token: response.id,
        email: response.email,
        is_apple: null,
        image: response.picture.data.url,
      },
    });
  };
  const componentClicked = () => {};

  // if (token) {
  //   setCookie("token", token, { path: "/" });
  // }
  window.onhashchange = function () {
    if (window.innerDocClick) {
      //Your own in-page mechanism triggered the hash change
    } else {
    }
  };
  window.addEventListener("popstate", function (event) {
    dispatch({
      type: "CLEAR_OTP",
    });
  });

  return (
    <div className="login-container">
      <div style={{ width: "100%" }}>
        <Navbar />
      </div>
      {loading || isLoading || register.isLoading ? (
        <Spinner />
      ) : (
        <div className="login-container">
          {register.isOTPSent ? (
            <div className="otp--container--layout">
              {/* <div className="login--header">
                <Image1 />
              </div> */}
              <div className="OTP-container">
                <img src={otpImage} alt="" className="otp--image" />

                <div>
                  <p className="title">Enter Verification Code</p>
                  <p className="subtitle">
                    We have just sent verification code to{" "}
                    <span style={{ fontWeight: "bold", color: "#0A4D68" }}>
                      {JSON.parse(localStorage.getItem("email"))}
                    </span>
                  </p>
                  <AntInput
                    placeholder="Enter your OTP"
                    type="number"
                    value={OTP}
                    style={{ height: "50px" }}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  <p
                    style={{
                      marginTop: "0.5rem",
                      color: "#0A4D68",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    onClick={() =>
                      onresendCode(JSON.parse(localStorage.getItem("email")))
                    }
                  >
                    Resend Code
                  </p>
                  <Button
                    text="Verify"
                    onClick={verifyOTP}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "18px",
                      padding: "15px 5px",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="login--container">
                {/* <div className="login--header">
                <Image1 />
              </div> */}
                <div className="login--body--container">
                  <span className="sign--in"> Register to Classideals</span>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      First Name
                    </span>
                    <div className="input--container">
                      <NewInput
                        value={firstName}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter Your First Name"
                        name="firstName"
                        className="input--field"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      Last Name
                    </span>
                    <div className="input--container">
                      <NewInput
                        value={lastName}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter Your Last Name"
                        name="lastName"
                        className="input--field"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      Email
                    </span>
                    <div className="input--container">
                      <NewInput
                        value={email}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter Your Email"
                        name="email"
                        className="input--field"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        paddingLeft: "2px",
                      }}
                    >
                      Password
                    </span>
                    <div className="input--container">
                      <NewInput
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter Your Password"
                        className="input--field"
                      />
                      {passwordVisible ? (
                        <AiOutlineEye
                          className="eye--logo"
                          onClick={handleChangeVisible}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="eye--logo"
                          onClick={handleChangeVisible}
                        />
                      )}
                    </div>
                  </div>

                  <button
                    className="button--style"
                    type="button"
                    value={"Login"}
                    onClick={submitHandler}
                  >
                    Register
                  </button>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Already have an account?{" "}
                    <Link to={"/login"}>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#609966",
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Sign in
                      </span>
                    </Link>{" "}
                  </p>
                  <div className="loginButton">
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      By signing up I agree to ClassiDeals{" "}
                      <a href="https://classibazaar.com.au/pd-terms-of-use.html">
                        {" "}
                        <span
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#609966",
                          }}
                        >
                          Terms of Use{" "}
                        </span>
                      </a>{" "}
                      and
                      <a href="https://classibazaar.com.au/pd-posting-policy.html">
                        <span
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#609966",
                          }}
                        >
                          {" "}
                          Privacy Policy
                        </span>
                      </a>{" "}
                      and I consent to receiving marketing from ClassiDeals and
                      third party offers.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    register: state.register,
    error: state.login.error,
    isLoggedIn: state.login.isLoggedIn,
    loading: state.login.loading,
    token: state.login.token,
    isLoggedIn: state.login.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Signup);
