import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import "./Footer.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoImage from "../../images/logo.png";
import { toast } from "react-toastify";
import androidImage from "../../images/Android.png";
import validator from "validator";
import appStore from "../../images/appStore.png";

const Footer = () => {
  const [categories, setCategories] = useState([]);
  const [newLetter, setNewsLetter] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const naviage = useNavigate();
  const path = location?.pathname;
  const [length, setLength] = useState("");
  useEffect(() => {
    if (path) {
      const len = path.length;
      setLength(len);
    }
  }, [path]);

  const handleClickLogo = () => {
    if (length > 1) {
      naviage("/");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const getCategories = async () => {
      const response = await axios
        // .get("https://classibazaar.com.au/api/deal/categories")
        .get("/deal/categories")
        .catch((err) => "");
      setCategories(response?.data);
    };
    getCategories();
  }, []);
  const categorySearchHandler = (category) => {
    dispatch({
      type: "SEARCH_CATEGORY_REQUEST",
      payload: {
        c: category,
      },
    });
  };
  const submitHandler = async () => {
    if (!validator.isEmail(newLetter)) {
      return toast.error("Please enter valid email!", { toastId: 5487 });
    }
    if (!newLetter) {
      return toast.error("Please enter email Address", { toastId: 108 });
    }
    await axios
      // .post("https://classibazaar.com.au/api/deal/newsletter", {
      .post("/deal/newsletter", {
        email: newLetter,
      })
      .then((response) => toast.success(response.data.message))
      .catch((err) => toast.error(err.message));
    setNewsLetter("");
  };
  return (
    <div className="footer-container">
      <div className="footer--container">
        <div className="footer--container--layout">
          <div className="footer--logo" style={{ fontSize: "14px" }}>
            <img
              className="footer--classi--logo"
              onClick={handleClickLogo}
              src={logoImage}
              alt=""
            />
            <span style={{ textAlign: "center" }}>
              {" "}
              © Copyright 2022 Classideals.
            </span>
            <p
              style={{
                marginTop: "0.5rem",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {" "}
              All rights reserved.
            </p>
          </div>

          <div className="two twos">
            <p style={{ fontSize: "20px" }}>About</p>

            <div className="innerContainer">
              <a
                className="hover--element"
                href="https://classibazaar.com.au/pd-about-us.html"
                target={"_blank"}
                style={{ fontSize: "14px" }}
              >
                About us
              </a>
              <a
                className="hover--element"
                href="https://classibazaar.com.au/pd-contact.html"
                target={"_blank"}
                style={{ fontSize: "14px" }}
              >
                Contact
              </a>

              <a
                className="hover--element"
                href="https://classibazaar.com.au/pd-posting-policy.html"
                target={"_blank"}
                style={{ fontSize: "14px" }}
              >
                Privacy Policy
              </a>
              <a
                className="hover--element"
                href="https://classibazaar.com.au/pd-terms-of-use.html"
                target={"_blank"}
                style={{ fontSize: "14px" }}
              >
                Terms of condition
              </a>
              <a
                className="hover--element"
                href="https://classibazaar.com.au/pd-cookie-policy.html"
                target={"_blank"}
                style={{ fontSize: "14px" }}
              >
                Cookie policy
              </a>
            </div>
          </div>

          <div className="three threes">
            <p style={{ fontSize: "20px" }}>Category</p>
            <div className="category--list">
              <div className="first--category--list">
                {categories?.map((category, index) => {
                  if (index % 2 == 0) {
                    return (
                      <Link to={"/search-results"} style={{ width: "100%" }}>
                        <a
                          onClick={() =>
                            categorySearchHandler(category.category)
                          }
                        >
                          <span
                            className="hover--element"
                            style={{ color: "white", fontSize: "14px" }}
                          >
                            {" "}
                            {"  "} {category.catname}
                          </span>
                        </a>
                      </Link>
                    );
                  }
                })}
              </div>
              <div className="second--category--list">
                {categories?.map((category, index) => {
                  if (index % 2 != 0) {
                    return (
                      <Link to={"/search-results"} style={{ width: "100%" }}>
                        <a
                          onClick={() =>
                            categorySearchHandler(category.category)
                          }
                        >
                          {" "}
                          <span
                            className="hover--element"
                            style={{ color: "white", fontSize: "14px" }}
                          >
                            {" "}
                            {"  "} {category.catname}
                          </span>
                        </a>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="small">
            <div className="two">
              <p style={{ fontSize: "20px" }}>About</p>

              <div className="innerContainer">
                <a
                  className="hover--element"
                  style={{ fontSize: "14px" }}
                  href="https://classibazaar.com.au/pd-about-us.html"
                  target={"_blank"}
                >
                  About us
                </a>
                <a
                  className="hover--element"
                  style={{ fontSize: "14px" }}
                  href="https://classibazaar.com.au/pd-contact.html"
                  target={"_blank"}
                >
                  Contact
                </a>

                <a
                  className="hover--element"
                  style={{ fontSize: "14px" }}
                  href="https://classibazaar.com.au/pd-posting-policy.html"
                  target={"_blank"}
                >
                  Privacy Policy
                </a>
                <a
                  className="hover--element"
                  style={{ fontSize: "14px" }}
                  href="https://classibazaar.com.au/pd-terms-of-use.html"
                  target={"_blank"}
                >
                  Terms of condition
                </a>
                <a
                  className="hover--element"
                  style={{ fontSize: "14px" }}
                  href="https://classibazaar.com.au/pd-cookie-policy.html"
                  target={"_blank"}
                >
                  Cookie policy
                </a>
              </div>
            </div>
            <div className="three">
              <p style={{ fontSize: "20px" }}>Category</p>
              <div className="category--list">
                <div className="first--category--list">
                  {categories?.map((category, index) => {
                    return (
                      <Link to={"/search-results"} style={{ width: "100%" }}>
                        <a
                          className="hover--element"
                          onClick={() =>
                            categorySearchHandler(category.category)
                          }
                        >
                          <span style={{ color: "white", fontSize: "14px" }}>
                            {" "}
                            {"  "} {category.catname}
                          </span>
                        </a>
                      </Link>
                    );
                    // }
                  })}
                </div>
              </div>
            </div>
            <div className="four small--container--four">
              <p style={{ fontSize: "20px" }}>Newsletter</p>

              <div className="innerContainer1">
                <span style={{ fontSize: "14px" }}>
                  Subscribe our News Letter
                </span>
                <div className="footer--subscriber--input">
                  <input
                    className="subscribe--input"
                    type="text"
                    style={{ color: "black", fontSize: "14px" }}
                    placeholder="Enter you Email"
                    value={newLetter}
                    onChange={(e) => setNewsLetter(e.target.value)}
                  />
                  <button className="subscribe--button" onClick={submitHandler}>
                    Submit
                  </button>
                </div>
                <p style={{ fontSize: "14px" }}>Download the Classideals app</p>
                <div className="logo-container">
                  <a
                    style={{ display: "flex", alignItems: "center" }}
                    href="https://play.google.com/store/apps/details?id=com.ausnep.classideals&hl=en&gl=US"
                  >
                    <img
                      style={{ height: "2rem", width: "6rem" }}
                      src={androidImage}
                      alt=""
                    />
                  </a>
                  <a href="https://apps.apple.com/np/app/classideals/id1510553776">
                    <img
                      style={{ height: "2rem", width: "6rem" }}
                      src={appStore}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="four large--container--four">
            <p style={{ fontSize: "20px" }}>Newsletter</p>

            <div className="innerContainer1">
              <span style={{ fontSize: "14px" }}>
                Subscribe our News Letter
              </span>
              <div className="footer--subscriber--input">
                <input
                  className="subscribe--input"
                  type="text"
                  style={{ color: "black", fontSize: "14px" }}
                  placeholder="Enter you Email"
                  value={newLetter}
                  onChange={(e) => setNewsLetter(e.target.value)}
                />
                <button className="subscribe--button" onClick={submitHandler}>
                  Submit
                </button>
              </div>
              <p style={{ fontSize: "14px" }}>Download the Classideals app</p>
              <div className="logo-container">
                <a
                  style={{ display: "flex", alignItems: "center" }}
                  href="https://play.google.com/store/apps/details?id=com.ausnep.classideals&hl=en&gl=US"
                >
                  <img
                    style={{ height: "2rem", width: "6rem" }}
                    src={androidImage}
                    alt=""
                  />
                </a>
                <a href="https://apps.apple.com/np/app/classideals/id1510553776">
                  <img
                    style={{ height: "2rem", width: "6rem" }}
                    src={appStore}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
