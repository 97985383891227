import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import ReactStars from "react-rating-stars-component";
import AOS from "aos";
import "aos/dist/aos.css";
import Spinner2 from "../spinner/Spinner2";
import Moment from "react-moment";
import { Pagination, Badge } from "antd";
import { stringTrim } from "../../utils/stringTrim";
import ProductSkeleton from "../skeleton/productSkeleton";
import { connect, useDispatch } from "react-redux";

const mapStateToProps = (store) => {
  const { isPreOrderProductLoading, preOrderProduct, totalPreOrderPage } =
    store.dashboardProduct;
  return { isPreOrderProductLoading, preOrderProduct, totalPreOrderPage };
};

const PreOrderProfucts = ({
  isPreOrderProductLoading,
  preOrderProduct,
  totalPreOrderPage,
}) => {
  // const [PopularProducts, setPopularProducts] = useState([]);
  const [visible, setVisible] = useState(4);
  const [buttonToggle, setButtonToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(totalPreOrderPage);
  const dispatch = useDispatch();

  const handleSetProductStatus = () => {
    localStorage.setItem("productStatus", "preorderProduct");
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_PREORDER_PRODUCT_REQUEST",
      payload: {
        page: currentPage,
      },
    });
  }, [currentPage]);

  useEffect(() => {}, [preOrderProduct]);

  if (isPreOrderProductLoading) {
    return (
      <div className="product--container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "100%",
            margin: "0 1.5rem",
          }}
        >
          <div className="product--load--header">
            <span>Pre-Order Products</span>
          </div>
          <ProductSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      {preOrderProduct?.length > 0 ? (
        <>
          <div className="product--container">
            <div className="product--header">
              <span>Pre-Order Products</span>
            </div>
          </div>
          <div className="product--container">
            <div className="featuredProducts">
              {preOrderProduct?.map((product) => (
                <div
                  className="productCard"
                  key={product?.id}
                  onClick={handleSetProductStatus}
                  // data-aos="fade-up"
                >
                  <div className="overlays"></div>
                  <Link
                    to={`/details/${product?.deal_slug}/${product?.rating}`}
                    className="link"
                  >
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <p
                        className="discount--rate"
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          left: 0,
                          zIndex: 2,
                        }}
                      >
                        {product?.discount}% Off
                      </p>
                      <div className="product--badge">
                        {product?.is_featured === "1" && (
                          <Badge.Ribbon
                            color={"Blue"}
                            text="Featured"
                          ></Badge.Ribbon>
                        )}
                        {product?.is_popular === "1" && (
                          <Badge.Ribbon
                            text="Popular"
                            color={"#ff1493"}
                          ></Badge.Ribbon>
                        )}
                      </div>

                      <div className="image--animate">
                        <img
                          style={{ height: "16rem" }}
                          src={product?.image?.thumbnail}
                          alt=""
                        />
                      </div>
                      <div className="container-below">
                        <div className="product--cart--details">
                          <div className="product--description">
                            {product?.dealstitle &&
                              stringTrim(product.dealstitle, 35)}
                            {product?.dealstitle?.length > 50 ? "..." : ""}
                          </div>
                          <div className="product--price">
                            <span style={{ flex: 1 }}>
                              <span className="base--offer--price">
                                ${product?.actual_price}
                              </span>{" "}
                              {"-"}{" "}
                              <span className="base--product--price">
                                ${product?.product_price}
                              </span>
                            </span>
                            <ReactStars
                              count={product.rating}
                              color={"#ffd700"}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                          <div className="product--desc">
                            {product?.subtitle &&
                              stringTrim(product?.subtitle, 60)}
                            {product?.subtitle?.length > 60 ? "..." : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="product--container">
            <div className="pagination--icons--container">
              <div className="btns" style={{ marginTop: "1rem" }}>
                <Pagination
                  style={{
                    marginRight: "-2.5rem",
                  }}
                  current={currentPage}
                  defaultCurrent={currentPage}
                  total={total_pages * 10}
                  onChange={(e) => setCurrentPage(e)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(mapStateToProps)(PreOrderProfucts);
