import { toast } from "react-toastify";
import history from "../../utils/history";
import { message } from "antd";
import FilterOrder from "../../utils/filterOrder";
import { async } from "rxjs";

const initialState = {
  profile: {},
  error: {},
  isLoading: false,
  newImage: "",
  myCards: [],
  myOrders: [],
  myPreOrders: [],
  isProfileChanged: false,
  active: "personal",
  orders: "",
  modalVisible: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "GET_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PROFILE_SUCCESS":
      return {
        ...state,
        profile: payload,
        isLoading: false,
        newImage: payload?.image,
      };
    case "GET_PROFILE_FAILURE":
      toast.error(payload?.message);
      return {
        ...state,
        error: payload?.message,
        isLoading: false,
      };
    case "EDIT_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "EDIT_PROFILE_SUCCESS":
      if (payload.goToBack) {
        setTimeout(() => {
          history.push("/");
        }, [1000]);
      }
      toast.success(payload.data.message, { toastId: 21 });

      // setTimeout(() => {
      //   history.push("/your-profile");
      //   // window.location.reload();
      // }, [2000]);
      return {
        ...state,
        isLoading: false,
        modalVisible: false,
        isProfileChanged: state.isProfileChanged ? false : true,
      };
    case "EDIT_PROFILE_FAILURE":
      toast.error(payload.data.message, { toastId: 2546 });
      return {
        ...state,
        isLoading: false,
      };
    case "CHANGE_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CHANGE_PROFILE_SUCCESS":
      toast.success(payload.message, { toastId: 22 });

      // setTimeout(() => {
      //   // window.location.replace("/your-profile");
      //   window.location.reload();
      // }, [2000]);
      return {
        ...state,
        isLoading: false,
        isProfileChanged: true,
        newImage: payload.source.src,
      };
    case "CHANGE_PROFILE_FAILURE":
      toast.error(payload.data.message, { toastId: 20 });

      return {
        ...state,
        isLoading: false,
        isProfileChanged: false,
      };
    case "UPDATE_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PASSWORD_SUCCESS":
      if (payload.message === "Validation Failed") {
        toast.error("Please enter all the fields.");
      } else {
        toast.success(payload.message, { toastId: 35 });
      }

      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_PASSWORD_FAILURE":
      for (const key in payload.data.errors) {
        toast.error(payload.data.errors[key], { toastId: 9 });
      }
      return {
        ...state,
        isLoading: false,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        profile: {},
      };
    case "SAVE_CARD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SAVE_CARD_SUCCESS":
      toast.success(payload.message, { toastId: 48 });
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "SAVE_CARD_FAILURE":
      toast.error(payload.data.message, { toastId: 47 });
      return {
        ...state,
        isLoading: false,
      };
    case "GET_SAVED_CARDS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SAVED_CARDS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        myCards: payload,
      };
    case "GET_SAVED_CARDS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_MY_CARD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_MY_CARD_SUCCESS":
      toast.success(payload.message, { toastId: 30 });
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_MY_CARD_FAILURE":
      toast.error(payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_MY_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_MY_ORDERS_SUCCESS":
      // const data = FilterOrder(payload?.orders);
      return {
        ...state,
        isLoading: false,
        // myOrders: data,
        myOrders: payload.orders,
        orders: payload,
      };
    case "GET_MY_ORDERS_FAILURE":
      toast.error(payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "SEND_FEEDBACK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEND_FEEDBACK_SUCCESS":
      toast.success(payload.message, { toastId: 48 });
      setTimeout(() => {
        history.push("/");
      }, [2000]);

      return {
        ...state,
        isLoading: false,
      };
    case "SEND_FEEDBACK_FAILURE":
      // toast.error(payload.data.message, { toastId: 49 });
      toast.error("Feedback Form  is Empty", { toastId: 49 });

      return {
        ...state,
        isLoading: false,
      };
    case "GET_MY_PREORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_MY_PREORDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        myPreOrders: payload.booked_deals,
        orders: payload,
      };
    case "GET_MY_PREORDER_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "CHANGE_ACTIVE":
      return {
        ...state,
        active: payload.active,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        modalVisible: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalVisible: false,
      };
    default:
      return {
        ...state,
      };
  }
}
