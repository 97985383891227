import axios1 from "axios";
import { get } from "lodash";

class StripeApi {
  async tokens(postData) {
    const { months } = postData?.card;
    const tempArray = months.split("/");
    const tempMonth = tempArray[0];
    const tempYear = tempArray[1];

    try {
      const res = await axios1.post(
        "https://api.stripe.com/v1/tokens",
        "card[number]=" +
          encodeURIComponent(postData.card.number) +
          "&" +
          // "card[exp_month]=" +
          // encodeURIComponent(postData.card.month) +
          // "&" +
          // "card[exp_year]=" +
          // encodeURIComponent(postData.card.year) +
          // "&" +
          "card[exp_month]=" +
          encodeURIComponent(tempMonth) +
          "&" +
          "card[exp_year]=" +
          encodeURIComponent(tempYear) +
          "&" +
          // "card[name]=" +
          // encodeURIComponent(postData.card.name) +
          "card[cvc]=" +
          encodeURIComponent(postData.card.cvv),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer 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",

          },
        }
      );
      const response = get(res, "data");

      return response;
    } catch (err) {
      console.log("check check", err);
      throw err;
    }
  }
  async customers({ id, email }) {
    try {
      const res = await axios1.post(
        "https://api.stripe.com/v1/customers",
        "source=" +
          encodeURIComponent(id) +
          "&" +
          "email=" +
          encodeURIComponent(email),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer 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",
 
          },
        }
      );
      const response = get(res, "data");

      return response;
    } catch (err) {
      throw err;
    }
  }
  async charges({ id, postData, orderId, customers = null }) {
    try {
      const AMT =
        postData.amount.indexOf(".") !== -1
          ? postData.amount.split(".").join("")
          : postData.amount + "00";
      const data1 = id
        ? "amount=" +
          encodeURIComponent(AMT) +
          "&" +
          "currency=" +
          encodeURIComponent("USD") +
          "&" +
          "description=" +
          encodeURIComponent(
            "Charge from your order at Classi Deal App. Name: " +
              orderId.first_name +
              " " +
              orderId.last_name +
              " email:" +
              orderId.email +
              " token number: " +
              id
          ) +
          "&" +
          "statement_descriptor=" +
          encodeURIComponent("CHR_CLASSIDEALS") +
          "&" +
          "source=" +
          encodeURIComponent(id)
        : "amount=" +
          encodeURIComponent(AMT) +
          "&" +
          "currency=" +
          encodeURIComponent("USD") +
          "&" +
          "description=" +
          encodeURIComponent(
            "Charge from your order at Classi Deal App. Name: " +
              orderId.first_name +
              " " +
              orderId.last_name +
              " email:" +
              orderId.email +
              " token customers: " +
              customers
          ) +
        "&" +
          "statement_descriptor=" +
          encodeURIComponent("CHR_CLASSIDEALS") +
          "&" +
          "customer=" +
          encodeURIComponent(customers);
      const res = await axios1.post(
        "https://api.stripe.com/v1/charges",
        data1,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer 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",

          },
        }
      );
      const response = get(res, "data");

      return response;
    } catch (err) {
      throw err;
    }
  }
}

export default new StripeApi();
