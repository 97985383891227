import React from "react";
import Feature from "./Feature";
import "./Feature.css";

import car from "../../images/feature_car.svg";
import call from "../../images/feature_call.svg";
import dollor from "../../images/feature_doller.svg";
import ticket from "../../images/feature_ticket.svg";

const FeatureContainer = () => {
  return (
    // <div className="product--container">
    <div className="feature--main--container">
      <Feature
        title="Free Delivery"
        imageLink={car}
        subtitle="Classideal, we make easy shopping."
      />
      <Feature
        title="Online 24/7 support"
        imageLink={call}
        subtitle="Get full guidance to order our deals."
      />
      <Feature
        title="Money-Back guarantee"
        imageLink={dollor}
        subtitle="Guarantee to get on money back on damage product."
      />
      <Feature
        title="Win shopping Voucher"
        imageLink={ticket}
        subtitle="Get a chance to win a Shopping Voucher."
      />
    </div>
    // </div>
  );
};

export default FeatureContainer;
