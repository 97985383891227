import React, { useState } from "react";
import Image1 from "../../components/Image";
import forgotPasswordImage from "../../images/forgot.jpg";
import { Input } from "antd";
import Button from "../../components/Button/Button";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

const ResetPassword = ({ ForgotPassword }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const sendCodeToEmailHandler = () => {
    if (!email) {
      return toast.error("Please enter Email", { toastId: 105 });
    }
    dispatch({
      type: "SEND_CODE_TO_EMAIL_REQUEST",
      payload: { email: email },
    });
  };

  return (
    <div className="login-container">
      <Navbar />
      {ForgotPassword.isLoading ? (
        <Spinner />
      ) : (
        <div className="sub--container">
          {/* <div className="login--header">
            <Image1 />
          </div> */}
          <div className="forgot--password--container">
            <img
              className="fpassword--image--logo"
              src={forgotPasswordImage}
              alt=""
            />
            <div className="fpassowrd--right-container">
              <p className="title">Forgot Your Password?</p>
              <p className="subtitle">
                Enter your Email and We'll send you code to reset your password
              </p>
              <Input
                style={{ height: "50px", width: "95%", marginBottom: "1rem" }}
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                text="Send Code to Email"
                onClick={sendCodeToEmailHandler}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ForgotPassword: state.ForgotPassword,
  };
};

export default connect(mapStateToProps, {})(ResetPassword);
