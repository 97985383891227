import React, { useCallback, useEffect } from "react";
import "./HomePage.css";
import Navbar from "../../components/navbar/Navbar";

import FeaturedProducts from "../../components/products/FeaturedProducts";
import PopularProducts from "../../components/products/PopularProducts";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import Footer from "../../components/footer/Footer";
import PreOrderProfucts from "../../components/products/PreOrder";
import RecentProducts from "../../components/products/RecentProducts";
import { getCookie } from "../../FrontendHelper";
import { useDispatch } from "react-redux";

const HomePage = () => {
  const dispatch = useDispatch();
  localStorage.setItem("activeState", "personal");
  useEffect(() => {
    const token = getCookie("token");

    token &&
      dispatch({
        type: "GET_MY_WISHLIST_REQUEST",
      });
  }, []);
  return (
    <>
      <Navbar />

      {/* <Slider slides={SliderData} /> */}
      <FeaturedProducts />
      <PopularProducts />
      <PreOrderProfucts />
      <RecentProducts />
      <FeatureContainer />
      <Footer />
    </>
  );
};

export default HomePage;
