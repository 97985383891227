import React, { useEffect, useState } from "react";
import "./Payment.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import StripeAPi from "./StripeAPi";
import { toast } from "react-toastify";
import { useRef } from "react";
import { Input } from "antd";
import SavedCards from "./SavedCards";
import history from "../../utils/history";
import { Checkbox } from "antd";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getCookie } from "../../FrontendHelper";

const Payment = ({ gift, profile, payment }) => {
  const [isPromoChecked, setIsPromoChecked] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [quantity, setQuantity] = useState(localStorage.getItem("qty"));
  const { productId } = useParams();
  const { PRODUCT_ID } = useParams();
  const { variant_id } = useParams();
  const [totalAmmount, setTotalAmmount] = useState("");
  const [porductID, setProductID] = useState();
  const dispatch = useDispatch();
  const [isSaveCard, setIsSaveCard] = useState(true);
  const [userProfile, setUserProfile] = useState(profile.profile);
  const [address, setAddress] = useState("");
  const [customerId, setCustomerId] = useState("");
  // const [discount, setDiscount] = useState("");
  const [discount, setDiscount] = useState(0);

  const [shippingData, setShippingData] = useState({
    names: "",
    contact: "",
    address: "",
  });
  const [cardInfo, setCardInfo] = useState({
    number: "",
    month: "",
    year: "",
    name: "",
    cvv: "",
    months: "",
  });

  const [promoCode, setPromoCode] = useState(gift.promoPayload.code);
  const [giftId, setGiftId] = useState(gift.giftId);

  const ammountRef = useRef();
  const discountRef = useRef();
  // setTimeout(() => {
  //   setTotalAmmount(ammountRef?.current?.textContent);
  //   setTotalAmmount((prev) =>
  //     ammountRef?.current?.textContent > 0
  //       ? ammountRef?.current?.textContent
  //       : prev
  //   );

  //   setDiscount(discountRef?.current?.textContent);
  // }, [2000]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
    dispatch({
      type: "GET_SAVED_CARDS_REQUEST",
    });
  }, [productId]);

  // promocode--update-code

  useEffect(() => {
    let tempDisc;
    if (gift.promoPayload.is_percentage == "1") {
      tempDisc = gift?.promoPayload?.credit
        ? (gift.promoPayload.credit *
            productDetails.products.filter(
              (product) => product.id === PRODUCT_ID
            )[0].price_after_discount *
            quantity) /
          100
        : 0;

      let temp =
        productDetails?.products?.filter(
          (product) => product.id === PRODUCT_ID
        )[0].price_after_discount *
          quantity -
        tempDisc;
      temp = temp.toFixed(2);

      setDiscount(tempDisc);
      setTotalAmmount(temp);
    } else if (gift?.promoPayload?.discount_amount > 0) {
      tempDisc = gift?.promoPayload?.discount_amount
        ? gift.promoPayload.discount_amount * quantity
        : 0;

      let temp =
        productDetails?.products?.filter(
          (product) => product.id === PRODUCT_ID
        )[0].price_after_discount *
          quantity -
        tempDisc;

      temp = temp.toFixed(2);

      if (temp > 0) {
        setDiscount(tempDisc);
        setTotalAmmount(temp);
      } else {
        let tempValue =
          productDetails?.products?.filter(
            (product) => product.id === PRODUCT_ID
          )[0].price_after_discount * quantity;

        tempValue = tempValue.toFixed(2);
        setDiscount(0);
        setTotalAmmount(tempValue);
      }
    }
  }, [gift.promoPayload]);

  useEffect(() => {
    const tempData =
      productDetails?.products?.filter(
        (product) => product.id === PRODUCT_ID
      )[0].price_after_discount * quantity;

    setTotalAmmount(tempData);
  }, [productDetails]);

  const applyPromoHandler = () => {
    dispatch({
      type: "APPLY_PROMO_REQUEST",
      payload: {
        code: promoCode,
        dealid: productDetails.deal.id,
        subtotal: productDetails.products[0].product_price,
        quantity: quantity,
      },
    });
  };

  useEffect(() => {
    if (!profile.profile.user_mobile) {
      dispatch({
        type: "OPEN_MODAL",
      });
      toast.info("Please update your profile first.");
      return history.push("/your-profile", { goToBack: true });
    }
    const getDealDetail = async () => {
      const deal = await axios
        // .get(`https://classibazaar.com.au/api/deal/detail/${productId}`)
        .get(`/deal/detail/${productId}`)
        .catch((err) => {
          "";
        });
      setProductDetails(deal.data);
      setProductID(PRODUCT_ID);
    };
    getDealDetail();
  }, []);

  const onPayment = async () => {
    try {
      if (customerId) {
        dispatch({
          type: "STRIPE_REQUEST",
        });

        const chargeResponse = await StripeAPi.charges({
          postData: {
            amount: totalAmmount.toString(),
            // amount: Math.abs(totalAmmount).toString(),
          },
          orderId: {
            first_name: profile.profile.first_name,
            last_name: profile.profile.last_name,
            email: profile.profile.user_email,
          },
          customers: customerId,
        });
        dispatch({
          type: "STRIPE_REQUEST_SUCCESS",
        });

        axios
          .post(
            // `https://classibazaar.com.au/api/deal/payment`,
            `/deal/payment`,
            {
              deal_id: productDetails.deal.id.toString(),
              productid: PRODUCT_ID.toString(),
              deal_variant_id:
                productDetails.deal.deal_type === "Product" ? variant_id : null,
              first_name: profile.profile.first_name,
              last_name: profile.profile.last_name,
              phone: profile.profile.user_mobile,
              email: profile.profile.user_email,
              payment_method: "Stripe",
              qty: quantity,
              amount: totalAmmount.toString(),
              user_id: profile.profile.userid,
              gift_id: giftId,
              txt_code: promoCode,
              shipping_name: shippingData.names,
              shipping_address1: address,
              shipping_phone: shippingData.contact,
              shipping_suburb: address,
              shipping_postcode: "2000",
              shipping_state: "nsw",
            },
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          )
          .then((res) =>
            history.push("/result", {
              message: "Deal has been booked successfully!.",
            })
          );

        // dispatch({
        //   type: "MAKE_PAYMENT_REQUEST",
        //   payload: {
        //     deal_id: productDetails.deal.id.toString(),
        //     productid: PRODUCT_ID.toString(),
        //     deal_variant_id:
        //       productDetails.deal.deal_type === "Product" ? variant_id : null,
        //     first_name: profile.profile.first_name,
        //     last_name: profile.profile.last_name,
        //     phone: profile.profile.user_mobile,
        //     email: profile.profile.user_email,
        //     payment_method: "Stripe",
        //     qty: quantity,
        //     amount: totalAmmount.toString(),
        //     user_id: profile.profile.userid,
        //     gift_id: giftId,
        //     txt_code: promoCode,
        //     shipping_name: shippingData.names,
        //     shipping_address1: address,
        //     shipping_phone: shippingData.contact,
        //     shipping_suburb: address,
        //     shipping_postcode: "2000",
        //     shipping_state: "nsw",
        //   },
        // });
      } else {
        dispatch({
          type: "STRIPE_REQUEST",
        });
        const stripeResponse = await StripeAPi.tokens({
          card: cardInfo,
        });

        const customerResposne = await StripeAPi.customers({
          id: stripeResponse.id,
          email: profile.profile.user_email,
        });

        const chargeResponse = await StripeAPi.charges({
          postData: {
            // amount: Math.abs(totalAmmount).toString(),
            amount: totalAmmount.toString(),
          },
          orderId: {
            first_name: profile.profile.first_name,
            last_name: profile.profile.last_name,
            email: profile.profile.user_email,
          },
          customers: customerResposne.id,
        });
        dispatch({
          type: "STRIPE_REQUEST_SUCCESS",
        });

        axios
          .post(
            // `https://classibazaar.com.au/api/deal/payment`,
            `/deal/payment`,
            {
              deal_id: productDetails.deal.id.toString(),
              productid: PRODUCT_ID.toString(),
              deal_variant_id:
                productDetails.deal.deal_type === "Product" ? variant_id : null,
              first_name: profile.profile.first_name,
              last_name: profile.profile.last_name,
              phone: profile.profile.user_mobile,
              email: profile.profile.user_email,
              payment_method: "Stripe",
              qty: quantity,
              amount: totalAmmount.toString(),
              user_id: profile.profile.userid,
              gift_id: giftId,
              txt_code: promoCode,
              shipping_name: shippingData.names,
              shipping_address1: address,
              shipping_phone: shippingData.contact,
              shipping_suburb: address,
              shipping_postcode: "2000",
              shipping_state: "nsw",
            },
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          )
          .then((res) =>
            history.push("/result", {
              message: "Deal has been booked successfully!.",
            })
          );

        // dispatch({
        //   type: "MAKE_PAYMENT_REQUEST",
        //   payload: {
        //     deal_id: productDetails.deal.id.toString(),
        //     productid: PRODUCT_ID.toString(),
        //     deal_variant_id:
        //       productDetails.deal.deal_type === "Product" ? variant_id : null,
        //     first_name: profile.profile.first_name,
        //     last_name: profile.profile.last_name,
        //     phone: profile.profile.user_mobile,
        //     email: profile.profile.user_email,
        //     payment_method: "Stripe",
        //     qty: quantity,
        //     amount: totalAmmount.toString(),
        //     user_id: profile.profile.userid,
        //     gift_id: giftId,
        //     txt_code: promoCode,
        //     shipping_name: shippingData.names,
        //     shipping_address1: address,
        //     shipping_phone: shippingData.contact,
        //     shipping_suburb: address,
        //     shipping_postcode: "2000",
        //     shipping_state: "nsw",
        //   },
        // });
      }
    } catch (error) {
      dispatch({
        type: "STRIPE_REQUEST_FAILURE",
      });
      toast.error(error.response.data.error.code);
    }

    if (isSaveCard) {
      // const customer = yield call(myAPI.saveCard, customerResposne);
      // const chargeStripeResponse = yield call(myStripeAPI.charges, {
      //   orderId: action.payload,
      //   postData: action.payload,
      //   customers: customerResposne.id,
      //   id: null,
      // });
      // const stripePayload = get(chargeStripeResponse, "data");
      // console.log(stripeResponse, chargeStripeResponse, stripePayload);
      //Toast.show("Stripe payment successful");
    }
    // else {
    //   const chargeStripeResponse = yield call(myStripeAPI.charges, {
    //     orderId: action.payload,
    //     postData: action.payload,
    //     id: stripeResponse.id,
    //   });
    //   const stripePayload = get(chargeStripeResponse, "data");
    //   console.log(stripeResponse, chargeStripeResponse, stripePayload);
    //   //Toast.show("Stripe payment successful");
    // }
  };

  const { number, year, month, name, cvv, months } = cardInfo;

  const onChangeHandler = (e) => {
    setCardInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const { names, contact } = shippingData;
  const onChange = (e) => {
    setShippingData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const preOrderPaymentHandler = async () => {
    // try {
    //   if (customerId) {
    //     dispatch({
    //       type: "STRIPE_REQUEST",
    //     });
    //     const chargeResponse = await StripeAPi.charges({
    //       postData: {
    //         amount: parseFloat(payment.preOrderPayment.paid_amount).toFixed(2),
    //       },
    //       orderId: {
    //         first_name: profile.profile.first_name,
    //         last_name: profile.profile.last_name,
    //         email: profile.profile.user_email,
    //       },
    //       customers: customerId,
    //     });
    //     dispatch({
    //       type: "STRIPE_REQUEST_SUCCESS",
    //     });
    //     dispatch({
    //       type: "MAKE_PREORDER_PAYMENT",
    //       payload: {
    //         id: payment.preOrderPayment.id,
    //         transaction_id: chargeResponse.id,
    //         paid_amount: payment.preOrderPayment.paid_amount,
    //         shipping_name: shippingData.names,
    //         shipping_address1: address,
    //         shipping_phone: shippingData.contact,
    //         shipping_suburb: address,
    //         shipping_postcode: "2000",
    //         shipping_state: "nsw",
    //       },
    //     });
    //   } else {
    //     dispatch({
    //       type: "STRIPE_REQUEST",
    //     });
    //     const stripeResponse = await StripeAPi.tokens({
    //       card: cardInfo,
    //     });
    //     const customerResposne = await StripeAPi.customers({
    //       id: stripeResponse.id,
    //       email: profile.profile.user_email,
    //     });
    //     const chargeResponse = await StripeAPi.charges({
    //       postData: {
    //         amount: Math.abs(payment.preOrderPayment.paid_amount).toString(),
    //       },
    //       orderId: {
    //         first_name: profile.profile.first_name,
    //         last_name: profile.profile.last_name,
    //         email: profile.profile.user_email,
    //       },
    //       customers: customerResposne.id,
    //     });
    //     dispatch({
    //       type: "STRIPE_REQUEST_SUCCESS",
    //     });
    //     dispatch({
    //       type: "MAKE_PREORDER_PAYMENT",
    //       payload: {
    //         id: payment.preOrderPayment.id,
    //         transaction_id: chargeResponse.id,
    //         paid_amount: payment.preOrderPayment.paid_amount.toString(),
    //         shipping_name: shippingData.names,
    //         shipping_address1: address,
    //         shipping_phone: shippingData.contact,
    //         shipping_suburb: address,
    //         shipping_postcode: "2000",
    //         shipping_state: "nsw",
    //       },
    //     });
    //   }
    // } catch (error) {
    //   dispatch({
    //     type: "STRIPE_REQUEST_FAILURE",
    //   });
    //   toast.error(error.response.data.error.code);
    // }
  };
  const handleChange = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => "");
  };

  const searchOptions = {
    componentRestrictions: { country: "au" }, // Restrict results to Australia
  };

  return (
    <>
      <Navbar />

      {payment.isLoading || gift.isLoading ? (
        <Spinner />
      ) : productDetails ? (
        <div className="product--container">
          <div className="payment" key={productDetails.deal.id}>
            <div className="paymentLeft">
              <div className="paymentForms">
                {console.log("productDetails", productDetails)}
                {productDetails.deal.deal_type === "Product" && (
                  <form
                    class="checkout"
                    className={
                      payment.preOrderPayment.id
                        ? " checkout preorder-checks"
                        : "checkout"
                    }
                  >
                    <div class="checkout-header">
                      <h1 class="checkout-title">Shipping Details</h1>
                    </div>
                    <div className="shipping--container">
                      <div className="shipping--input--field">
                        <label>
                          <span style={{ color: "red" }}>*</span> Shipping Name
                        </label>
                        <Input
                          name="names"
                          style={{ height: "2.5rem" }}
                          type="text"
                          placeholder="Enter Your Name"
                          value={shippingData.names}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                      <div className="shipping--input--field">
                        <label>
                          <span style={{ color: "red" }}>*</span> Shipping
                          Address
                        </label>
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleChange}
                          onSelect={handleSelect}
                          searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <Input
                                height={"50px"}
                                style={{ height: "2.5rem" }}
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "location-search-input",
                                })}
                                value={address}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span
                                        onClick={() =>
                                          setAddress(suggestion.description)
                                        }
                                      >
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      <div className="shipping--input--field">
                        <label>
                          <span style={{ color: "red" }}>*</span> Shipping
                          Contact
                        </label>
                        <Input
                          style={{ height: "2.5rem" }}
                          name="contact"
                          placeholder="Enter Your Contact Number"
                          value={shippingData.contact}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </form>
                )}
                {payment.preOrderPayment.id ? (
                  <form
                    class="checkout preorder-check"
                    style={{ marginTop: "1rem", margin: "auto" }}
                  >
                    <div class="checkout-header">
                      <h1 class="checkout-title">
                        Checkout
                        <span
                          class="checkout-price"
                          style={{
                            width: "80px",
                            fontWeight: "bolder",

                            letterSpacing: "1px",
                          }}
                        >
                          $
                          {payment.preOrderPayment.id
                            ? parseFloat(
                                payment.preOrderPayment.paid_amount
                              ).toFixed(2)
                            : totalAmmount &&
                              parseFloat(totalAmmount).toFixed(2)}
                        </span>
                      </h1>
                    </div>
                    <p>
                      <SavedCards
                        customerId={customerId}
                        setCustomerId={setCustomerId}
                      />
                      {profile.myCards.length > 0 && (
                        <div className="design--or">
                          <div></div>
                          <span>or</span>
                          <div></div>
                        </div>
                      )}
                      <input
                        type="text"
                        class="checkout-input checkout-name"
                        placeholder="Your name"
                        autofocus
                        name="name"
                        value={name}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      <input
                        type="text"
                        class="checkout-input checkout-card"
                        placeholder="4242 4242 4242 4242"
                        name="number"
                        value={number}
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </p>
                    <p style={{ marginTop: "-1.5rem" }}>
                      <input
                        type="text"
                        class="checkout-input checkout-exp"
                        placeholder="MM/YY"
                        name="months"
                        style={{ width: "49%" }}
                        value={months.length == 2 ? month.concat("/") : months}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      <input
                        type="password"
                        class="checkout-input checkout-cvc"
                        placeholder="CVC"
                        name="cvv"
                        value={cvv}
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </p>

                    <Button
                      text="Make Remaing Payment"
                      onClick={preOrderPaymentHandler}
                    />
                  </form>
                ) : (
                  <form class="checkout">
                    <div class="checkout-header">
                      <h1 class="checkout-title">
                        Checkout
                        <span
                          class="checkout-price"
                          style={{
                            width: "80px",
                            fontWeight: "bolder",
                            letterSpacing: "1px",
                          }}
                        >
                          $
                          {payment.preOrderPayment.id
                            ? payment.preOrderPayment.paid_amount
                            : totalAmmount &&
                              parseFloat(totalAmmount).toFixed(2)}
                        </span>
                      </h1>
                    </div>
                    <p>
                      {isPromoChecked && (
                        <Checkbox
                          style={{
                            color: "green",

                            marginBottom: "-1rem",
                          }}
                          defaultChecked={isPromoChecked}
                          checked={isPromoChecked}
                          onChange={() => {
                            setIsPromoChecked(!isPromoChecked);
                          }}
                        />
                      )}
                      {gift.promoPayload.code ? (
                        <>
                          <p>
                            Promo Code :{" "}
                            <span
                              style={{
                                fontWeight: "bolder",
                                fontSize: "20px",
                                textTransform: "uppercase",
                              }}
                            >
                              {gift.promoPayload.code}
                            </span>
                          </p>
                        </>
                      ) : (
                        <div className="bill-and-promo">
                          {isPromoChecked ? (
                            <div className="paymentPromo">
                              <div className="paymentCode">
                                <input
                                  type="text"
                                  placeholder="Enter Your Promo Code"
                                  value={promoCode}
                                  onChange={(e) => setPromoCode(e.target.value)}
                                />
                              </div>

                              <Button
                                onClick={applyPromoHandler}
                                text="Apply"
                              ></Button>
                            </div>
                          ) : (
                            <div style={{ marginBottom: "5px" }}>
                              <Checkbox
                                style={{ color: "green" }}
                                defaultChecked={isPromoChecked}
                                checked={isPromoChecked}
                                onChange={() => {
                                  setIsPromoChecked(true);
                                }}
                              />{" "}
                              <span
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                I have a promo code.
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      <SavedCards
                        customerId={customerId}
                        setCustomerId={setCustomerId}
                      />
                      {profile.myCards.length > 0 && (
                        <div className="design--or">
                          <div></div>
                          <span>or</span>
                          <div></div>
                        </div>
                      )}
                    </p>
                    <div className="card--detail--container">
                      <div className="card--input--container">
                        <span>Your Name</span>

                        <input
                          type="text"
                          class="checkout-input checkout-name"
                          placeholder="Enter Your name"
                          autofocus
                          name="name"
                          value={name}
                          onChange={(e) => onChangeHandler(e)}
                        />
                      </div>
                      <div className="card--input--container">
                        <span>Card Number</span>
                        <input
                          type="text"
                          class="checkout-input checkout-card"
                          placeholder="e.g 4242 4242 4242 4242"
                          name="number"
                          value={number}
                          onChange={(e) => onChangeHandler(e)}
                        />
                      </div>
                      <div className="card--detail--sub--container">
                        <div className="card--input--container">
                          <span>CVC</span>

                          <input
                            type="password"
                            class="checkout-input checkout-cvc"
                            placeholder="Enter Your CVC"
                            name="cvv"
                            value={cvv}
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                        <div className="card--input--container">
                          <span>Card Date</span>
                          <input
                            type="text"
                            class="checkout-input checkout-exp"
                            placeholder="MM/YY"
                            name="months"
                            value={
                              months.length == 2 ? months.concat("/") : months
                            }
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
            {!payment.preOrderPayment.id && (
              <div className="paymentRight">
                <h3 style={{ fontSize: " 18px" }}>Order Summary</h3>
                <div className="reviewBox">
                  <div className="paymentReview">
                    <div className="reviewLeft">
                      <h3 style={{ marginBottom: "-10px" }}>ITEM</h3>
                      <div className="image-row">
                        <img
                          onClick={history.back}
                          style={{ height: "120px", width: "120px" }}
                          src={productDetails.main_image.thumbnail}
                          alt=""
                          className="paymentImg"
                        />

                        <div className="reviewRight">
                          <div className="paymentQty">
                            <h3>QTY</h3>
                            <p>{quantity}</p>
                          </div>
                        </div>
                        <div
                          className="paymentTotal"
                          style={{ marginLeft: "1rem" }}
                        >
                          <h3>TOTAL</h3>
                          <p>
                            $
                            {(
                              productDetails.products.filter(
                                (product) => product.id === PRODUCT_ID
                              )[0].price_after_discount * quantity
                            ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <h3>{productDetails.deal.dealstitle}</h3>
                      <p>{productDetails.deal.subtitle}</p>
                    </div>
                  </div>
                  <div className="reviewBottom">
                    {/* {gift.promoPayload.code && ( */}
                    {/* {discount > 0 && ( */}
                    <div className="paymentGrandTotal">
                      <h3>Promo discount</h3>
                      <h3>
                        $
                        <span
                        // ref={discountRef}
                        >
                          {/* {gift.promoPayload.is_percentage == "1"
                              ? (gift.promoPayload.credit *
                                  productDetails.products.filter(
                                    (product) => product.id === PRODUCT_ID
                                  )[0].price_after_discount *
                                  quantity) /
                                100
                              : gift.promoPayload.discount_amount * quantity} */}
                          {discount}
                        </span>
                      </h3>
                    </div>
                    {/* )} */}
                    <div className="paymentGrandTotal">
                      <h3 style={{ fontWeight: "bold" }}>Grand Total</h3>
                      {gift.promoPayload.discount_amount ? (
                        <>
                          <div style={{ display: "flex" }}>
                            <span>$</span>
                            <h3 ref={ammountRef}>
                              {/* {(
                                productDetails.products.filter(
                                  (product) => product.id === PRODUCT_ID
                                )[0].price_after_discount *
                                  quantity -
                                discount
                              ).toFixed(2)} */}
                              {totalAmmount}
                            </h3>
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ display: "flex" }}>
                            <span>$</span>
                            <h3
                            // ref={ammountRef}
                            >
                              {(
                                productDetails.products.filter(
                                  (product) => product.id === PRODUCT_ID
                                )[0].price_after_discount * quantity
                              ).toFixed(2)}
                            </h3>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="paymentReviewBtns">
                    <Button
                      style={{
                        width: "100%",
                        padding: "8px 0",
                      }}
                      onClick={() => {
                        onPayment();
                      }}
                    >
                      PROCEED ORDER
                    </Button>
                    <Link to={"/"}>
                      <button
                        style={{ backgroundColor: "#ea4335", width: "100%" }}
                      >
                        CANCEL ORDER
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      <FeatureContainer />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gift: state.gift,
    profile: state.profile,
    payment: state.payment,
  };
};
export default connect(mapStateToProps, {})(Payment);
