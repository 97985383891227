import { AiOutlineDeleteRow } from "react-icons/ai";

const initialState = {
  notifications: [],
  isLoading: false,
  current_page: "",
  has_next_page: "",
  no_of_unseen: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "GET_NOTIFICATIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        // notifications: payload,
        notifications: payload?.notifications,
        current_page: payload?.current_page,
        has_next_page: payload?.has_next_page,
        no_of_unseen: payload?.no_of_unseen,
      };
    case "GET_NOTIFICATIONS_REQUESTS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_NOTIFICATIONS_SUCCESSS":
      return {
        ...state,
        isLoading: false,
        current_page: payload?.current_page,
        has_next_page: payload?.has_next_page,
        no_of_unseen: payload?.no_of_unseen,
        notifications: [...state.notifications, ...payload?.notifications],
      };
    case "GET_NOTIFICATIONS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
