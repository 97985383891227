import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Link, Navigate } from "react-router-dom";
import Button from "../Button/Button";
import { RiLoginBoxFill } from "react-icons/ri";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { connect } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { useCookies } from "react-cookie";
import { logout } from "../../actions/login";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import OutsideAlerter from "../UseOutSideAlterter";
import { useRef } from "react";
import Spinner2 from "../spinner/Spinner2";
import history from "../../utils/history";
import { Menu, Dropdown, Badge } from "antd";
import { BsArrowDownShort } from "react-icons/bs";
import moment from "moment";
import { getCookie } from "../../FrontendHelper";
import Search from "../search/Search";
import newLogo from "../../images/logo.png";
import { useLocation } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { MdContactSupport } from "react-icons/md";
import { BiHomeAlt2 } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";

import { stringUpper } from "../../utils/stringUppercase";
import { stringTrim } from "../../utils/stringTrim";
import { NavLink } from "react-router-dom";
import { Drawer } from "antd";
import classiDealNewLogo from "../../images/classidealsNewLogo.png";
import { AiFillHome, AiOutlineAppstore } from "react-icons/ai";
import { GrBladesVertical } from "react-icons/gr";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { BadgeRoot } from "@mui/material";

const { SubMenu } = Menu;
const clientId =
  "252151804518-vp24lkthddeje73bjp71kqlls8nfirks.apps.googleusercontent.com";

const Navbar = ({
  isLoggedIn,
  profile,
  logout,
  Notification,
  login,
  profiles,
  active,
  latlng,
  address,
  distance,
  value,
  wishlist,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [categories, setCategories] = useState([]);
  const [slider, setSlider] = useState(false);
  const [checkLogout, setCheckLogout] = useState(localStorage.getItem("token"));
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const Navigate = useNavigate();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const dropdownRef = useRef();
  const notificationRef = useRef();
  const [pageNo, setPageNo] = useState(1);
  const [newNotification, setNewNotificaion] = useState();
  const [userName, setUserName] = useState("");
  const [categorySlider, setCategorySlider] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  const { notifications, no_of_unseen } = Notification;
  const [wishlen, setWishlen] = useState(wishlist?.wishLength);
  const [updateNotification, setUpdateNotification] = useState(false);

  const handleMobileSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setWishlen(wishlist?.wishLength);
  }, [wishlist.wishLength]);

  const handleOpenMobileNotification = () => {
    makeNotificationSeen();
    setSlider(false);
    setTimeout(() => {
      setNotificationDrawer(true);
    }, 1000);
  };

  const handleSubmitMobileSearchValue = () => {
    dispatch({
      type: "SEARCH_PRODUCT_REQUEST",
      payload: {
        city: null,
        minPrice: null,
        maxPrice: null,
        query: searchValue,
      },
    });
    setCategorySlider((prev) => !prev);
  };

  useEffect(() => {
    profiles?.profile && setUserName(profiles?.profile?.first_name);
  }, [profiles]);

  useEffect(() => {
    pageNo &&
      dispatch({
        type: "GET_NOTIFICATIONS_REQUEST",
        payload: {
          currentPage: pageNo,
        },
      });
  }, [pageNo]);

  const loadMore = () => {
    setPageNo((prev) => prev + 1);
    dispatch({
      type: "GET_NOTIFICATIONS_REQUESTS",
      payload: {
        currentPage: pageNo + 1,
      },
    });
  };

  const toggleSlider = () => setSlider(!slider);

  const logoutHandler = () => {
    removeCookie("token", { path: "/" });

    dispatch({
      type: "LOGOUT_USER",
    });
    Navigate("/");
  };
  useEffect(() => {
    const getCategories = async () => {
      const response = await axios
        // .get("https://classibazaar.com.au/api/deal/categories")
        .get("/deal/categories")
        .catch((err) => "");
      setCategories(response.data);
      // const notResponse = await axios.get(
      //   "https://classibazaar.com.au/api/v2/me/count_all_unseen_replys",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${getCookie("token")}`,
      //     },
      //   }
      // );
      // const notResponse = await axios.get(
      //   "https://classibazaar.com/api/v2/me/count_all_unseen_replys",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${getCookie("token")}`,
      //     },
      //   }
      // );
      // setNewNotificaion(notResponse.data);
    };
    getCategories();
  }, []);

  // useEffect(async () => {
  //   const notResponse = await axios.get(
  //     "https://classibazaar.com/api/v2/me/count_all_unseen_replys",
  //     {
  //       headers: {
  //         Authorization: `Bearer ${getCookie("token")}`,
  //       },
  //     }
  //   );
  //   setNewNotificaion(notResponse.data);
  // }, [updateNotification]);

  const categorySearchHandler = (category) => {
    dispatch({
      type: "SEARCH_CATEGORY_REQUEST",
      payload: {
        c: category,
      },
    });
  };

  const toggleCategorySlider = () => {
    setCategorySlider((prev) => !prev);
  };

  // const notificationHandler = (notification) => {
  //   if (notification.notification_type === "Wishlist") {
  //     history.push("/your-wishlist");
  //   }
  //   if (
  //     notification.notification_type === "ADD_PRODUCT_DEAL" ||
  //     notification.notification_type === "ADD_DEAL"
  //   ) {
  //     dispatch({
  //       type: "CHANGE_ACTIVE",
  //       payload: {
  //         active: "purchase",
  //       },
  //     });
  //     history.push("/your-profile");
  //   }
  //   if (notification.notification_type === "BOOK_DEAL") {
  //     dispatch({
  //       type: "CHANGE_ACTIVE",
  //       payload: {
  //         active: "preorders",
  //       },
  //     });
  //     history.push("/your-profile");
  //   }
  //   if (notification.notification_type === "BOOK_PRODUCT_DEAL") {
  //     dispatch({
  //       type: "CHANGE_ACTIVE",
  //       payload: {
  //         active: "preorders",
  //       },
  //     });
  //     history.push("/your-profile");
  //   }
  // };

  const makeNotificationSeen = async () => {
    setUpdateNotification((prev) => !prev);
    // const response = await axios.get(
    //   "https://classibazaar.com.au/api/v2/seen_notifications?api_from=classiDeal",
    //   {
    //     headers: {
    //       Authorization: `Bearer ${getCookie("token")}`,
    //     },
    //   }
    // );
    const response = await axios.get(
      "/seen_notifications?api_from=classiDeal",
      {
        headers: {
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
  };

  const menu = (
    <Menu>
      <div
        ref={dropdownRef}
        className={
          isDropDownOpen
            ? "dropdown-old active profile--menu"
            : "dropdown-old profile--menu"
        }
      >
        <Link to={"/your-wishlist"} style={{ marginTop: "5px" }}>
          <div className="content profile--content">
            <AiTwotoneHeart className="icon" style={{ border: "none" }} />
            <span>My Wishlist</span>
          </div>
        </Link>

        <Link to={"/your-profile"}>
          <div className="content profile--content">
            <FaUserAlt className="icon" style={{ border: "none" }} />
            <span>Profile</span>
          </div>
        </Link>

        <div className="content profile--content" onClick={logoutHandler}>
          <IoIosLogOut className="icon" style={{ border: "none" }} />
          <span>Logout</span>
        </div>
      </div>
    </Menu>
  );

  const menu1 = (
    <Menu
      style={{
        padding: "10px",
        maxHeight: "400px",
        width: "440px",
        overflowY: "scroll",
      }}
    >
      <div className="dropdown-old notification">
        <p style={{ alignSelf: "flex-start", fontWeight: "bold" }}>
          Notifications
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {notifications?.length > 0 ? (
            notifications?.map((notification) => {
              return (
                <div
                  onClick={makeNotificationSeen}
                  className="content nav--content"
                  // onClick={() => notificationHandler(notification)}
                >
                  <img
                    className="notification--image"
                    src="https://classibazaar.com.au/assets/images-old/classibazaar-icon.png"
                    alt=""
                  />
                  <div>
                    <span className="title">{notification.title}</span>
                    <p
                      style={{
                        fontSize: "12px",
                        height: "35px",
                        overflow: "hidden",
                      }}
                    >
                      {notification.body}
                    </p>
                    <div className="item">
                      <span></span>
                      <span>
                        {" "}
                        {moment(notification.sent_at).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Recent Notifications</p>
          )}
        </div>
      </div>
      {Notification?.notifications?.length > 9 && (
        <span
          text={
            Notification.isLoading ? "Loading..." : "Load More Notification"
          }
          style={{ margin: "auto" }}
          onClick={loadMore}
        >
          <BsArrowDownShort
            style={{
              width: "2rem",
              height: "2rem",
              border: "1px solid gray",
              borderRadius: "100%",
              cursor: "pointer",
              margin: "1rem 0",
            }}
          />
        </span>
      )}
    </Menu>
  );
  const triggers = document.querySelectorAll(
    ".category--navbar--container > li"
  );

  const background = document.querySelector(".dropdownBackground");
  const nav = document.querySelector(".top");
  function handleEnter() {
    this.classList.add("trigger-enter");
    setTimeout(
      () =>
        this.classList.contains("trigger-enter") &&
        this.classList.add("trigger-enter-active"),
      150
    );
    background.classList.add("open");
    const dropdown = this.querySelector(".dropdown");
    const dropdownCoords = dropdown.getBoundingClientRect();
    const navCoords = nav.getBoundingClientRect();
    const coords = {
      height: dropdownCoords.height,
      width: dropdownCoords.width,
      top: dropdownCoords.top - navCoords.top,
      left: dropdownCoords.left - navCoords.left,
    };
    background.style.setProperty("width", `${coords.width}px`);
    background.style.setProperty("height", `${coords.height}px`);
    background.style.setProperty(
      "transform",
      `translate(${coords.left}px, ${coords.top}px)`
    );
  }
  function handleLeave() {
    this.classList.remove("trigger-enter", "trigger-enter-active");
    background.classList.remove("open");
  }
  triggers.forEach((trigger) =>
    trigger.addEventListener("mouseenter", handleEnter)
  );
  triggers.forEach((trigger) =>
    trigger.addEventListener("mouseleave", handleLeave)
  );

  return (
    <div className="nav--container">
      <div
        className="nav"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* mobile--view--notification--drawer */}
        <Drawer
          title="Notification"
          placement="right"
          width={"100%"}
          closable={true}
          onClose={() => setNotificationDrawer(false)}
          visible={notificationDrawer}
        >
          {notifications?.length > 0 ? (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {notifications?.map((notification) => {
                return (
                  <div
                    className="content"
                    // onClick={() => notificationHandler(notification)}
                    onClick={makeNotificationSeen}
                  >
                    <div className="line"></div>
                    <img
                      className="notification--image"
                      src={classiDealNewLogo}
                      alt=""
                    />
                    <div>
                      <span className="title">{notification?.title}</span>
                      <p
                        style={{
                          fontSize: "12px",
                          height: "35px",
                          overflow: "hidden",
                        }}
                      >
                        {notification.body}
                      </p>
                      <div className="item">
                        <span></span>
                        <span>
                          {" "}
                          {moment(notification.sent_at).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              {Notification?.notifications?.length > 9 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    text={
                      Notification.isLoading
                        ? "Loading..."
                        : "Load More Notification"
                    }
                    style={{ margin: "auto" }}
                    onClick={loadMore}
                  >
                    <BsArrowDownShort
                      style={{
                        width: "2rem",
                        height: "2rem",
                        border: "1px solid gray",
                        borderRadius: "100%",
                        cursor: "pointer",
                        // padding: "1rem",
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <p>No Recent Notifications</p>
          )}
        </Drawer>
        <div className="navUp">
          <span className="category--slider--menu">
            <GrBladesVertical
              // AiOutlineAppstore
              style={{
                width: "1.2rem",
                height: "1.2rem",
                color: "black",
              }}
              className="menuIcon"
              onClick={toggleSlider}
            />
          </span>
          <div
            className="navLeft"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "1rem",
              position: "sticky",
            }}
          >
            <Link to="/" className="link">
              <img
                src={classiDealNewLogo}
                alt="Not Found"
                style={{ height: "60px" }}
              />
            </Link>
          </div>
          {/* <div className="navbar--category">
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "1px solid gray",
                padding: "0 0.1rem 0 0.5rem",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              className="header--category--title"
            >
              Category{" "}
              <IoMdArrowDropdown style={{ height: "2rem", width: "2rem" }} />{" "}
            </span>
            <div className="header--category--list"></div>
          </div> */}

          <Search
            onDesktop={true}
            address={address}
            latlng={latlng}
            active={active}
            distance={distance}
            value={value}
          />

          {/* <span className="mobile-view">
            <Search />
          </span> */}

          <div className="navRight">
            <AiOutlineAppstore
              // GrBladesVertical
              className="menuIcon"
              style={{
                width: "1.4rem",
                height: "1.4rem",
                color: "black",
              }}
              onClick={toggleCategorySlider}
            />

            <div
              className={
                categorySlider
                  ? "mobile--category--menu open--drawer"
                  : "mobile--category--menu"
              }
            >
              <IoMdClose
                onClick={() => setCategorySlider((prev) => !prev)}
                style={{
                  position: "absolute",
                  left: "0.5rem",
                  top: "0.5rem",
                  color: "black",
                  height: "1.5rem",
                  width: "1.5rem",
                  cursor: "pointer",
                }}
              />
              <div className="category--menu--header">
                <form className="search--bar--form">
                  <input
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleMobileSearchChange}
                    className="mobile--search--input"
                    type="text"
                  />
                  <Link to="/search-results">
                    <FiSearch
                      onClick={handleSubmitMobileSearchValue}
                      className="search--icon--logo"
                    />
                  </Link>
                </form>
                <h2 className="category--header">All Category</h2>
              </div>
              <div className="category--body">
                {categories?.length > 0 ? (
                  categories.map((category) => (
                    <li
                      onClick={() => {
                        categorySearchHandler(category.category);
                      }}
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "active--menu hover--element mobile--category--name"
                            : "non--active--mobile--menu hover--element mobile--category--name"
                        }
                        to={`/search-results/${category.category}`}
                        style={{ fontSize: "1rem", fontWeight: "600" }}
                        onClick={() => setCategorySlider(false)}
                      >
                        {category.catname}
                      </NavLink>
                    </li>
                  ))
                ) : (
                  <Spinner2 navbar={true} />
                )}
              </div>
            </div>

            <div className={slider ? "navMenu active" : "navMenu"}>
              <CloseOutlined
                className="closeIcon"
                style={{ color: "black" }}
                onClick={toggleSlider}
              />
              <div className="menuItems">
                <div className="menuBtns"></div>
                <div className="navItems">
                  <div
                    className="menuBtns active-menuBtns"
                    style={{ marginTop: "3rem" }}
                  >
                    {isLoggedIn ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "0.5rem",
                            flexDirection: "column",
                            width: "100%",
                            fontSize: "20px",
                            fontWeight: "bolder",
                          }}
                        >
                          {localStorage.getItem("profile") && (
                            <img
                              style={{ alignSelf: "center" }}
                              src={
                                JSON.parse(localStorage.getItem("profile"))
                                  .company_logo
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <div className="mobile--menu--list">
                          <Link to={"/your-profile"}>
                            <div className="mobile--content">
                              <FaUserAlt className="icon" />
                              <span>Profile</span>
                            </div>
                          </Link>
                          <Link to={"/your-wishlist"}>
                            <div className="mobile--content">
                              <AiTwotoneHeart className="icon" />
                              <span>Wishlist</span>
                            </div>
                          </Link>

                          <div
                            className="mobile--content"
                            onClick={handleOpenMobileNotification}
                          >
                            <IoIosNotifications className="icon"></IoIosNotifications>

                            <span style={{ color: "black" }}>Notification</span>
                          </div>

                          <div className="mobile--content">
                            <MdContactSupport className="icon" />
                            <Link to={"/help"}>
                              <p>Help</p>
                            </Link>
                          </div>

                          <div
                            className="mobile--content"
                            onClick={logoutHandler}
                          >
                            <IoIosLogOut className="icon" />
                            <span>Logout</span>
                          </div>
                        </div>
                        {isNotificationOpen && (
                          <OutsideAlerter>
                            <div
                              className="dropdown-old active notification"
                              ref={notificationRef}
                              style={{
                                right: "0rem",
                              }}
                            >
                              <p>Notifications</p>
                              {notifications?.length > 0 ? (
                                notifications.map((notification) => {
                                  return (
                                    <div
                                      className="content"
                                      onClick={makeNotificationSeen}
                                      // onClick={() => {
                                      //   notificationHandler(notification);
                                      // }}
                                    >
                                      <div className="line"></div>
                                      <img
                                        src="https://blog.sourcefabric.org/images/cms-image-000004039.jpg"
                                        alt=""
                                      />
                                      <div>
                                        <span className="title">
                                          {notification.title}
                                        </span>
                                        <p>{notification.body}</p>
                                        <div className="item">
                                          <span></span>
                                          <span>
                                            {moment(
                                              notification.sent_at
                                            ).format("DD/MM/YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <p>No Recent Notifications</p>
                              )}
                            </div>
                          </OutsideAlerter>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: "1rem",
                        }}
                      >
                        <Link to="/login">
                          <Button text="Login">
                            <RiLoginBoxFill />
                          </Button>
                        </Link>
                        <Link to="/signup">
                          <Button text="Signup">
                            <AiOutlineUserAdd />
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="menuBtns">
              {isLoggedIn ? (
                <>
                  {" "}
                  <NavLink
                    to={"/your-wishlist"}
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "active--menu hover--element"
                        : "non--active--menu hover--element"
                    }
                  >
                    <Badge placement="start" count={wishlen}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <AiTwotoneHeart
                          className={({ isActive }) =>
                            isActive
                              ? "active--menu hover--element"
                              : "non--active--menu hover--element"
                          }
                        />
                        Wishlist
                      </div>
                    </Badge>
                  </NavLink>
                  <Dropdown overlay={menu1} placement="bottomLeft" arrow>
                    <Badge placement=" " count={no_of_unseen}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={makeNotificationSeen}
                        className="hover--element"
                      >
                        <IoIosNotifications
                          className="hover--element"
                          onClick={() => {
                            setIsNotificationOpen(!isNotificationOpen);
                            setIsDropDownOpen(false);
                          }}
                        ></IoIosNotifications>
                        Notification
                      </div>
                    </Badge>
                  </Dropdown>
                  <NavLink
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "2px",
                      alignItems: "center",
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "active--menu hover--element"
                        : "non--active--menu hover--element"
                    }
                    to={"/help"}
                  >
                    <MdContactSupport
                      className={({ isActive }) =>
                        isActive
                          ? "active--menu hover--element"
                          : "non--active--menu hover--element"
                      }
                    />{" "}
                    Help
                  </NavLink>
                  <Dropdown overlay={menu} placement="bottomLeft" arrow>
                    <div className="image-and-name navlink--menu hover--element">
                      <div className="inner hover--element">
                        <AiOutlineUser className="hover--element" />
                        <span className="hover--element">
                          {userName && stringTrim(stringUpper(userName), 10)}
                        </span>
                        {/* <AiOutlineCaretDown className="iconss hover--element" /> */}
                      </div>
                    </div>
                  </Dropdown>
                </>
              ) : (
                <>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "active--menu hover--element navlink--menu "
                        : "non--active--menu hover--element navlink--menu "
                    }
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                      alignItems: "center",
                    }}
                    to="/login"
                  >
                    <RiLoginBoxFill />
                    SignIn
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "active--menu hover--element navlink--menu "
                        : "non--active--menu hover--element navlink--menu "
                    }
                    to="/signup"
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                    }}
                  >
                    <AiOutlineUserAdd />
                    Signup
                  </NavLink>
                </>
              )}
            </div>
          </div>
        </div>
        <nav class="top on-mobile-nav">
          <div class="dropdownBackground">
            <span class="arrow"></span>
          </div>

          <div class="category--navbar--container">
            {categories?.length > 0 ? (
              categories.map((category) => (
                <li
                  onClick={() => {
                    categorySearchHandler(category.category);
                  }}
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "active--menu hover--element"
                        : "non--active--menu hover--element"
                    }
                    to={`/search-results/${category.category}`}
                    style={{ lineHeight: "1" }}
                  >
                    {category.catname}
                  </NavLink>
                  <ul class="dropdown courses">
                    {category.childs.map((child) => {
                      return (
                        <Link
                          to={`/search-results/${child.category}`}
                          style={{ width: "100%" }}
                        >
                          <li
                            className="sub--category"
                            onClick={() => {
                              categorySearchHandler(child.category);
                            }}
                          >
                            <a
                              className="sub--category--list"
                              href="https://ReactForBeginners.com"
                            >
                              <img
                                src={child.icon}
                                alt=""
                                style={{ height: "30px", width: "30px" }}
                              />{" "}
                              {child.catname}
                            </a>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </li>
              ))
            ) : (
              <Spinner2 navbar={true} />
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    profile: state.login.profile,
    Notification: state.Notification,
    login: state.login,
    profiles: state.profile,
    wishlist: state.wishlist,
  };
};

export default connect(mapStateToProps, { logout })(Navbar);
