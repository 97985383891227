import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/homepage/HomePage";
import ProductDetails from "./pages/product details/ProductDetails";
import Payment from "./pages/payment/Payment";
import Gift from "./pages/gift/Gift";
import Login from "./pages/login/Login";
import Profile from "../src/pages/profile/Profile";
import Wishlist from "./pages/wishlist/Wishlist";
import Help from "./pages/Help/Help";
import VerifyCode from "./pages/login/VerifyCode";
import ChangePassword from "./pages/login/ChangePassword";
import ResetPassword from "./pages/login/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/signup/Signup";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { CookiesProvider } from "react-cookie";
import SearchResult from "./components/search/SearchResult";
import PrivateRoute from "./PrivateRoute";
import EditProfile from "./pages/profile/EditProfile";
import OrderDetails from "./pages/profile/OrderDetails";
import PreOrder from "./pages/pre-order/PreOrder";
import NewVeiryf from "./pages/login/NewVeiryf";
import Result from "./pages/Result/Result";

function App() {
  return (
    <CookiesProvider>
      <Provider store={store()}>
        <div className="App" style={{ color: "#4A5F80 !important" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />

          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route
              path="/details/:productId/:rating"
              element={<ProductDetails />}
            />
            <Route path="/result" element={<Result />} />
            <Route path="/VERIFY_CODE" element={<NewVeiryf />} />
            <Route
              path="/payment/:productId/:PRODUCT_ID"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment/:productId/:PRODUCT_ID/:variant_id"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path="/gift/:productId/:PRODUCT_ID"
              element={
                <PrivateRoute>
                  <Gift />
                </PrivateRoute>
              }
            />
            <Route
              path="/gift/:productId/:PRODUCT_ID/:variant_id"
              element={
                <PrivateRoute>
                  <Gift />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ResetPassword />} />
            <Route path="/verify-code" element={<VerifyCode />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/your-wishlist"
              element={
                <PrivateRoute>
                  <Wishlist />
                </PrivateRoute>
              }
            />
            <Route
              path="/help"
              element={
                <PrivateRoute>
                  <Help />
                </PrivateRoute>
              }
            />
            <Route path="/search-results/" element={<SearchResult />} />
            <Route
              path="/search-results/:category"
              element={<SearchResult />}
            />

            <Route
              path="/your-profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-details"
              element={
                <PrivateRoute>
                  <OrderDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/pre-order/:productId/:PRODUCT_ID"
              element={
                <PrivateRoute>
                  <PreOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="/pre-order/:productId/:PRODUCT_ID/:variant_id"
              element={
                <PrivateRoute>
                  <PreOrder />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
