import React, { useEffect } from "react";
import "./Help.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import customerCareImage from "../../images/IOS.png";
import phoneCall from "../../images/phone-call.png";
import Button from "../../components/Button/Button";
import Input from "../../components/Input";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import { useState } from "react";
import Spinner from "../../components/spinner/Spinner";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import history from "../../utils/history";

const Help = ({ profile }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);
  // const sendFeedback = (msg) => {

  //   dispatch({
  //     type: "SEND_FEEDBACK_REQUEST",
  //     payload: {
  //       first_name: profile.profile.first_name,
  //       last_name: profile.profile.last_name,
  //       msg: msg,
  //       email: profile.profile.user_email,
  //       phone: "15454156",
  //     },
  //   });  };

  const sendFeedback = () => {
    dispatch({
      type: "SEND_FEEDBACK_REQUEST",
      payload: {
        first_name: profile?.profile?.first_name,
        last_name: profile?.profile?.last_name,
        msg: message,
        email: profile?.profile?.user_email,
        contact_from: "15454156",
      },
    });
  };

  // const sendFeedback = () => {
  //   axios
  //     .post(`https://classibazaar.com/api/deal/add_contact`, {
  //       first_name: profile?.profile?.first_name,
  //       last_name: profile?.profile?.last_name,
  //       msg: message,
  //       email: profile?.profile?.user_email,
  //       contact_from: "ClassiDeals",
  //     })
  //     .then((res) =>
  //       history.push("/", {
  //         message: "Inquary send successfully!.",
  //       })
  //     );
  // };

  return (
    <div>
      <Navbar />
      {profile.isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <h1 className="title_customer">Hello! How can we help you?</h1>
          <div className="customer-image-container">
            <img src={customerCareImage} alt="" className="customerImage" />
            <div className="container--help">
              <div className="call-container">
                <p>You can call us.</p>
                <div>
                  <img src={phoneCall} alt="" className="cellphone--image" />
                  <span className="call">1300287637</span>
                </div>
              </div>
              <div className="feedback-container">
                <span className="span">What can we improve?</span>
                <TextArea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Give us feedback"
                  style={{ height: "120px" }}
                />
              </div>
              <Button
                text="Send Feedback"
                onClick={() => sendFeedback(message)}
              />
            </div>
          </div>
        </>
      )}

      <FeatureContainer />
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(Help);
