import React, { useState } from "react";
import creditImage from "../../../src/images/credit.png";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { MdDelete } from "react-icons/md";
import visaImage from "../../images/visa.png";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import StripeAPi from "../payment/StripeAPi";
import { Input } from "antd";
import { async } from "rxjs";

const Payment = ({ profile, payment }) => {
  const [cardInfo, setCardInfo] = useState({
    number: "",
    month: "07",
    year: "2022",
    name: "",
    cvv: "",
    months: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_SAVED_CARDS_REQUEST",
    });
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);

  const deleteMyCardHandler = (id) => {
    dispatch({
      type: "DELETE_MY_CARD_REQUEST",
      payload: {
        card_id: id,
      },
    });
  };
  const saveCardHandler = async () => {
    try {
      dispatch({
        type: "STRIPE_REQUEST",
      });
      const stripeResponse = await StripeAPi.tokens({
        card: cardInfo,
      });

      const customerResposne = await StripeAPi.customers({
        id: stripeResponse.id,
        email: profile.profile.user_email,
      });
      dispatch({
        type: "STRIPE_REQUEST_SUCCESS",
      });

      dispatch({
        type: "SAVE_CARD_REQUEST",
        payload: {
          card_type: "Visa",
          customer_id: customerResposne.id,
          card_no: cardInfo.number.substring(cardInfo.number.length - 4),
        },
      });
    } catch (error) {
      dispatch({
        type: "STRIPE_REQUEST_FAILURE",
      });
      toast.error(error.response.data.error.code);
    }
  };

  const { number, year, month, name, cvv, months } = cardInfo;

  const onChangeHandler = (e) => {
    setCardInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  if (profile.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "-webkit-fill-available",
          zIndex: "999999999",
        }}
      >
        <Spinner />;
      </div>
    );
  }
  return (
    <div className="payment-method-container">
      <h1 className="title">Payment Methods</h1>
      <div className="top-container">
        <div className="containers">
          <div>
            <p
              style={{
                fontWeight: "bolder",
              }}
            >
              Saved Cards
            </p>

            {profile.myCards.length > 0 ? (
              profile.myCards.map((card) => (
                <>
                  <div
                    className="cards"
                    style={{
                      marginBottom: "0.5rem",
                    }}
                  >
                    <div className="left">
                      <img
                        src={visaImage}
                        alt=""
                        style={{ height: "80px", width: "80px" }}
                      />
                      <div className="right">
                        <p>XXXX-XXXX-XXXX-{card.card_no}</p>
                        <p>{card.card_type}</p>
                      </div>
                    </div>
                    <div
                      className="right"
                      style={{ height: "100%", cursor: "pointer" }}
                    >
                      <MdDelete
                        style={{
                          height: "100%",
                          zIndex: "11111111111111111111111111111111111111111",
                          fontSize: "25px",
                          color: "white",
                        }}
                        className="icon"
                        onClick={() => deleteMyCardHandler(card.id)}
                      />
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div>You haven't saved any credit cards</div>
            )}
          </div>
        </div>
      </div>
      <div className="below-container">
        <p className="title">Add New Card</p>
        <div className="image-and-form-container">
          <img src={creditImage} alt="" />
          <div className="form-container">
            {/* <form className="form-container"> */}
            <div
              className="input-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="">Name on the Card</label>
              <Input
                style={{
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                }}
                type="text"
                value={name}
                placeholder="Please Enter Name"
                name="name"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className="input-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="">Credit Card Number</label>
              <Input
                style={{
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                }}
                type="text"
                value={number}
                placeholder="e.g 4242 4242 4242 4242"
                name="number"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className="input-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="">CVV</label>
              <Input
                style={{
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Enter the CVV"
                type="password"
                value={cvv}
                name="cvv"
                // name="cvc"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className="input-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label>Expiration Date</label>
              <Input
                style={{
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                  width: "49%",
                }}
                type="text"
                class="checkout-input checkout-exp"
                placeholder="MM/YY"
                name="months"
                value={months.length == 2 ? months.concat("/") : months}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>

            <Button
              text="Add"
              style={{ width: "96.5%" }}
              onClick={saveCardHandler}
            />
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    payment: state.payment,
  };
};

export default connect(mapStateToProps, {})(Payment);
