import React from "react";
import "./ProductSkeletonStyle.css";

const ProductSkeleton = () => {
  return (
    <div className="product--skeleton--container">
      <div className="product--cart--skeleton">
        <div className="image--skeleton--container"></div>
        <div className="product--skeleton--name"></div>
        <div className="product--price--skeleton">
          <div className="price--skeleton">
            <span></span>
            <span></span>
          </div>
          <div className="rating--skeleton">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="product--skeleton--description"></div>
      </div>
      <div className="product--cart--skeleton">
        <div className="image--skeleton--container"></div>
        <div className="product--skeleton--name"></div>
        <div className="product--price--skeleton">
          <div className="price--skeleton">
            <span></span>
            <span></span>
          </div>
          <div className="rating--skeleton">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="product--skeleton--description"></div>
      </div>
      <div className="product--cart--skeleton">
        <div className="image--skeleton--container"></div>
        <div className="product--skeleton--name"></div>
        <div className="product--price--skeleton">
          <div className="price--skeleton">
            <span></span>
            <span></span>
          </div>
          <div className="rating--skeleton">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="product--skeleton--description"></div>
      </div>
    </div>
  );
};

export default ProductSkeleton;
