import { combineEpics } from "redux-observable";
import {
  verifyOTPEpic,
  sendCodeToEmailEpic,
  verifyUserEpic,
  changePasswordEpic,
  authenticateUserEpic,
  registerUserEpic,
  loginWithGoogleEpic,
  searchProductEpic,
  getProfileEpic,
  editProfileEpic,
  changeProfileEpic,
  addToWishlistEpic,
  getMyWishlistEpic,
  deleteMyWishlistEpic,
  sendGiftDataSendEpic,
  applyPromoEpic,
  getReviewEpic,
  searchCategoryEpic,
  getSavedCardEpic,
  deleteMyCardCardEpic,
  getMyOrdersEpic,
  addReviewEpic,
  updatePasswordEpic,
  getNotificationsEpic,
  makePaymentEpic,
  saveCardEpic,
  sendFeedbackEpic,
  makePreOrderEpic,
  getPreOrderEpic,
  loginWithFacebookEpic,
  makePreOrderPayemnt,
  getNotificationssEpic,
  makePreOrdersEpic,
  getFeatureProduct,
  getPopularProduct,
  getPreOrderProduct,
  getRecentProduct,
} from "./myEpics";

export const rootEpic = combineEpics(
  getNotificationssEpic,
  authenticateUserEpic,
  registerUserEpic,
  verifyOTPEpic,
  sendCodeToEmailEpic,
  verifyUserEpic,
  changePasswordEpic,
  updatePasswordEpic,
  loginWithGoogleEpic,
  searchProductEpic,
  searchCategoryEpic,
  getProfileEpic,
  getNotificationsEpic,
  editProfileEpic,
  changePasswordEpic,
  changeProfileEpic,
  getSavedCardEpic,
  deleteMyCardCardEpic,
  getMyOrdersEpic,
  addToWishlistEpic,
  getMyWishlistEpic,
  deleteMyWishlistEpic,
  sendGiftDataSendEpic,
  applyPromoEpic,
  getReviewEpic,
  addReviewEpic,
  makePaymentEpic,
  saveCardEpic,
  sendFeedbackEpic,
  makePreOrderEpic,
  getPreOrderEpic,
  loginWithFacebookEpic,
  makePreOrderPayemnt,
  getFeatureProduct,
  getPopularProduct,
  getPreOrderProduct,
  getRecentProduct
);
