const NewInput = (props) => {
  return (
    <input
      {...props}
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
    />
  );
};

export default NewInput;
