import { filter, tap, mapTo, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { merge, of, from } from "rxjs";
import { API, API1, API2 } from "../BaseUrlProvider";

export const authenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("Authenticate_User_Request"),
    mergeMap((action) =>
      from(API.post("/v2/authenticate", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "Authenticate_User_Success",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "Authenticate_User_Failure",
            payload: { error: error.response, email: action.payload.email },
          })
        )
      )
    )
  );
export const registerUserEpic = (action$) =>
  action$.pipe(
    ofType("REGISTER_USER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/signup", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "OTP_SEND_SUCCESSFULL",
            payload: {
              data: response.data,
              email: action.payload.email,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "REGISTER_USER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const verifyOTPEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_OTP_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/activate_user", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "VERIFY_OTP_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "VERIFY_OTP_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const sendCodeToEmailEpic = (action$) =>
  action$.pipe(
    ofType("SEND_CODE_TO_EMAIL_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/forgot_password", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "SEND_CODE_TO_EMAIL_SUCCESS",
            payload: {
              data: response.data,
              email: action.payload.email,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "SEND_CODE_TO_EMAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const verifyUserEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_USER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/verify_user", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "VERIFY_USER_SUCCESS",
            payload: {
              data: response.data,
              code: action.payload.code,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "VERIFY_USER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/change_password", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "CHANGE_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const loginWithGoogleEpic = (action$) =>
  action$.pipe(
    ofType("LOGIN_WITH_GOOGLE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/social_authenticate", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "LOGIN_WITH_GOOGLE_SUCCESS",
            payload: {
              data: response.data,
              profile: action.payload,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "LOGIN_WITH_GOOGLE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const loginWithFacebookEpic = (action$) =>
  action$.pipe(
    ofType("LOGIN_WITH_FACEBOOK_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/social_authenticate", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "LOGIN_WITH_GOOGLE_SUCCESS",
            payload: {
              data: response.data,
              profile: action.payload,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "LOGIN_WITH_GOOGLE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const searchProductEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_PRODUCT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/deal/products`, {
          params: {
            c: action.payload.c,
            min_price: action.payload.minPrice,
            max_price: action.payload.maxPrice,
            q: action.payload.query,
            page: action.payload.page,
            cities: action.payload.city,
            lat: action.payload.lat,
            lng: action.payload.lng,
            distance: action.payload.distance,
          },
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "SEARCH_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SEARCH_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const searchCategoryEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/products?c=${action.payload.c}`)).pipe(
        mergeMap((response) =>
          of({
            type: "SEARCH_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SEARCH_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getProfileEpic = (action$) =>
  action$.pipe(
    ofType("GET_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(API.get("/v2/profile", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_PROFILE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_PROFILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// export const getNotificationsEpic = (action$) =>
//   action$.pipe(
//     ofType("GET_NOTIFICATIONS_REQUEST"),
//     mergeMap((action) =>
//       from(
//         API.get(
//           `/v2/user_notifications?page=${
//             action.payload && action.payload.currentPage
//           }&api_from=classiDeal`,
//           action.payload
//         )
//       ).pipe(
//         mergeMap((response) =>
//           of({
//             type: "GET_NOTIFICATIONS_SUCCESS",
//             payload: response.data,
//           })
//         ),
//         catchError((error) =>
//           of({
//             type: "GET_NOTIFICATIONS_FAILURE",
//             payload: error.response,
//           })
//         )
//       )
//     )
//   );

export const getNotificationsEpic = (action$) =>
  action$.pipe(
    ofType("GET_NOTIFICATIONS_REQUEST"),
    mergeMap((action) =>
      from(
        API2.get(
          `/v2/user_notifications?page=${
            action.payload && action.payload.currentPage
          }&api_from=classiDeal`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_NOTIFICATIONS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_NOTIFICATIONS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// export const getNotificationssEpic = (action$) =>
//   action$.pipe(
//     ofType("GET_NOTIFICATIONS_REQUESTS"),
//     mergeMap((action) =>
//       from(
//         API.get(`/v2/user_notifications?api_from=classiDeal`, action.payload)
//       ).pipe(
//         mergeMap((response) =>
//           of({
//             type: "GET_NOTIFICATIONS_SUCCESSS",
//             payload: response.data,
//           })
//         ),
//         catchError((error) =>
//           of({
//             type: "GET_NOTIFICATIONS_FAILURE",
//             payload: error.response,
//           })
//         )
//       )
//     )
//   );

export const getNotificationssEpic = (action$) =>
  action$.pipe(
    ofType("GET_NOTIFICATIONS_REQUESTS"),
    mergeMap((action) =>
      from(
        API2.get(`/v2/user_notifications?api_from=classiDeal`, action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_NOTIFICATIONS_SUCCESSS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_NOTIFICATIONS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const editProfileEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/profile", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "EDIT_PROFILE_SUCCESS",
            payload: { data: response.data, goToBack: action.payload.goToBack },
          })
        ),
        catchError((error) =>
          of({
            type: "EDIT_PROFILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const changeProfileEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/upload_image", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "CHANGE_PROFILE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_PROFILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updatePasswordEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(API.post("/v2/user_update_password", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getSavedCardEpic = (action$) =>
  action$.pipe(
    ofType("GET_SAVED_CARDS_REQUEST"),
    mergeMap((action) =>
      from(API.get("/deal/get_card")).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SAVED_CARDS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SAVED_CARDS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const deleteMyCardCardEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_MY_CARD_REQUEST"),
    mergeMap((action) =>
      from(API.post("/deal/delete_card", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_MY_CARD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_MY_CARD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getMyOrdersEpic = (action$) =>
  action$.pipe(
    ofType("GET_MY_ORDERS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/deal/myorders?status=${action.payload.status}&page=${action.payload.page}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_MY_ORDERS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_MY_ORDERS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addToWishlistEpic = (action$) =>
  action$.pipe(
    ofType("ADD_TO_WISHLIST_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/make_favourite/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_TO_WISHLIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_TO_WISHLIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getMyWishlistEpic = (action$) =>
  action$.pipe(
    ofType("GET_MY_WISHLIST_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/wishlists`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_MY_WISHLIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_MY_WISHLIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteMyWishlistEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_WISHLIST_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/wishlist_delete/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_WISHLIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_WISHLIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const sendGiftDataSendEpic = (action$) =>
  action$.pipe(
    ofType("SEND_GIFT_DATA_SEND_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/deal/send_gift/${action.payload.deal_product_id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "SEND_GIFT_DATA_SEND_SUCCESS",
            payload: {
              id: action.payload.slug,
              data: response.data,
              PRODUCT_ID: action.payload.PRODUCT_ID,
              variant_id: action.payload.variant_id,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "SEND_GIFT_DATA_SEND_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const applyPromoEpic = (action$) =>
  action$.pipe(
    ofType("APPLY_PROMO_REQUEST"),
    mergeMap((action) =>
      from(API.post(`deal/get_couponCredit_new`, action.payload)).pipe(
        // from(API.post(`deal/get_couponCredit`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "APPLY_PROMO_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "APPLY_PROMO_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getReviewEpic = (action$) =>
  action$.pipe(
    ofType("GET_REVIEW_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/review/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_REVIEW_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REVIEW_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addReviewEpic = (action$) =>
  action$.pipe(
    ofType("ADD_REVIEW_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/deal/review/${action.payload.productId}`, action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_REVIEW_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_REVIEW_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const makePaymentEpic = (action$) =>
  action$.pipe(
    ofType("MAKE_PAYMENT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`deal/payment`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "MAKE_PAYMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "MAKE_PAYMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const saveCardEpic = (action$) =>
  action$.pipe(
    ofType("SAVE_CARD_REQUEST"),
    mergeMap((action) =>
      from(API.post(`deal/save_card`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "SAVE_CARD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SAVE_CARD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// export const sendFeedbackEpic = (action$) =>
//   action$.pipe(
//     ofType("SEND_FEEDBACK_REQUEST"),
//     mergeMap((action) =>
//       from(API.post(`deal/contact`, action.payload)).pipe(
//         mergeMap((response) =>
//           of({
//             type: "SEND_FEEDBACK_SUCCESS",
//             payload: response.data,
//           })
//         ),
//         catchError((error) =>
//           of({
//             type: "SEND_FEEDBACK_FAILURE",
//             payload: error.response,
//           })
//         )
//       )
//     )
//   );

export const sendFeedbackEpic = (action$) =>
  action$.pipe(
    ofType("SEND_FEEDBACK_REQUEST"),
    mergeMap((action) =>
      from(API.post(`deal/add_contact`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "SEND_FEEDBACK_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SEND_FEEDBACK_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const makePreOrderEpic = (action$) =>
  action$.pipe(
    ofType("MAKE_PREORDER_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/deal/book`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "MAKE_PREORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "MAKE_PREORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getPreOrderEpic = (action$) =>
  action$.pipe(
    ofType("GET_MY_PREORDER_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/home/booked_deals?page=${action.payload.page}`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_MY_PREORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_MY_PREORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const makePreOrderPayemnt = (action$) =>
  action$.pipe(
    ofType("MAKE_PREORDER_PAYMENT"),
    mergeMap((action) =>
      from(
        API.post(
          `deal/booked_deal_payment/${action.payload.id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "MAKE_PREORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "MAKE_PREORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getFeatureProduct = (action$) =>
  action$.pipe(
    ofType("FETCH_FEATURE_PRODUCT_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/featured_deals`)).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_FEATURE_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_FEATURE_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getPopularProduct = (action$) =>
  action$.pipe(
    ofType("FETCH_POPULAR_PRODUCT_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/deal/popular_deals`)).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_POPULAR_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_POPULAR_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getPreOrderProduct = (action$) =>
  action$.pipe(
    ofType("FETCH_PREORDER_PRODUCT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/deal/products?pre_order=Yes`, {
          params: {
            page: action?.payload?.page,
          },
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_PREORDER_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_PREORDER_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getRecentProduct = (action$) =>
  action$.pipe(
    ofType("FETCH_RECENT_PRODUCT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/deal/products`, {
          params: {
            page: action?.payload?.page,
          },
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_RECENT_PRODUCT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_RECENT_PRODUCT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// console.log(action);
//     if (action.payload.payment_method == "Stripe") {
//       const stripeResponse = yield call(myStripeAPI.tokens, action.state);
//       if (action.state.saveCard) {
//         const customerResposne = yield call(myStripeAPI.customers, {
//           id: stripeResponse.id,
//           postData: action.state,
//         });
//         const customer = yield call(myAPI.saveCard, customerResposne);
//         const chargeStripeResponse = yield call(myStripeAPI.charges, {
//           orderId: action.payload,
//           postData: action.payload,
//           customers: customerResposne.id,
//           id: null,
//         });
//         const stripePayload = get(chargeStripeResponse, "data");
//         console.log(stripeResponse, chargeStripeResponse, stripePayload);
//         //Toast.show("Stripe payment successful");
//       } else {
//         const chargeStripeResponse = yield call(myStripeAPI.charges, {
//           orderId: action.payload,
//           postData: action.payload,
//           id: stripeResponse.id,
//         });
//         const stripePayload = get(chargeStripeResponse, "data");
//         console.log(stripeResponse, chargeStripeResponse, stripePayload);
//         //Toast.show("Stripe payment successful");
//       }
//     } else {
//       //   const response = yield call(myAPI.payment, action.payload);
//       //   const payload = get(response, "data");
//       //   Toast.show("Paypal payment successful");
//     }
//     const response = yield call(myAPI.payment, {
//       data: action.payload,
//       giftId: action.giftId,
//       txt_code: action.txt_code,
//     });
//     const payload = get(response, "data");
//     yield put({
//       type: CHECKOUT_FETCHING_DATA_SUCCESS,
//     });
//     //Toast.show("Order Successful");
//     NavigationService.navigate("ThankYou");
