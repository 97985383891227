import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import React, { useEffect, useState } from "react";
import { BsFillCartCheckFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import { AiOutlineGift, AiTwotoneHeart } from "react-icons/ai";
import {
  TagsOutlined,
  HomeOutlined,
  PlusOutlined,
  MinusOutlined,
  HeartOutlined,
  TagOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";

import "antd/dist/antd.css";
import "./ProductDetails.css";
import "react-awesome-slider/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

import Cookies from "js-cookie";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import Button from "../../components/Button/Button";

import Spinner from "../../components/spinner/Spinner";
import { setLocalStorage } from "../../FrontendHelper";
import Countdown from "react-countdown";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
} from "react-share";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const ProductDetails = ({ wishlist, review, profile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState("");
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [dealVariantId, setDealVariantId] = useState("");
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const [productDetails, setProductDetails] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [productPrice, setProductIPrice] = useState([]);
  const [dealType, setDealType] = useState("");
  const [dealDetails, setDealDetails] = useState();
  const [state, setState] = useState({
    currentPage: 1,
    reviews: review.reviews,
    reviewsPerPage: 3,
  });
  const indexOfLastPosts = state.currentPage * state.reviewsPerPage;
  const indexOfFirstPost = indexOfLastPosts - state.reviewsPerPage;
  const currentReviews = review.reviews.slice(
    indexOfFirstPost,
    indexOfLastPosts
  );

  const productStatus = localStorage.getItem("productStatus");

  // const [defaultChecked, setDefaultChecked] = useState();
  const [defaultChecked, setDefaultChecked] = useState(1);

  const [counter, setCounter] = useState(1);
  const { productId, rating } = useParams();
  const [token, setToken] = useState("");
  const [productContainerNo, setProductContainerNo] = useState(0);

  const handleDefaultValue = (id, index) => {
    setDefaultChecked(id);
    setProductContainerNo(index);
  };

  // map-code
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "16px",
  };

  const mapOptions = {
    zoom: 15,
    // center: {
    //   lat: dealDetails?.locations[0].lat,
    //   lng: dealDetails?.locations[0].lng,
    // },
    center: {
      lat: parseFloat(dealDetails?.locations[0].lat),
      lng: parseFloat(dealDetails?.locations[0].lng),
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU", // Replace with your Google Maps API key
  });

  //offer countdown function
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer--container">
        <div className="timer--container--header">
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        <div className="timer--container--body">
          <span>DAYS</span>
          <span>HOURS</span>
          <span>MINUTES</span>
          <span>SECONDS</span>
        </div>
      </div>
    );
  };

  const incCounter = () => {
    if (dealDetails.deal.maxdeal == counter) {
      return toast.error(`Only ${counter} quantity allowed!`, {
        toastId: 12345,
      });
    }
    setCounter(counter + 1);
  };

  const decCounter = () => {
    if (!(counter == 1 || counter == 0)) {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    const myCookieValue = Cookies.get("token");
    setToken(myCookieValue);
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    setLocalStorage("qty", counter);

    if (selectedColor && selectedSize !== "empty") {
      const response = await axios
        .get(
          // `https://classibazaar.com.au/api/deal/get_deal_variant_stock?deal_id=${dealDetails.deal.id}&color=${selectedColor}&size=${selectedSize}`
          `/deal/get_deal_variant_stock?deal_id=${dealDetails.deal.id}&color=${selectedColor}&size=${selectedSize}`
        )
        .then((response) => {
          setDealVariantId(response.data.variant.id);
        })
        .catch((err) => "");
    }
    //  else if (selectedColor && selectedSize == "empty") {
    //   alert("alert from empty");
    //   const response = await axios
    //     .get(
    //       // `https://classibazaar.com.au/api/deal/get_deal_variant_stock?deal_id=${dealDetails.deal.id}&color=${selectedColor}&size=${selectedSize}`
    //       `https://classibazaar.com/api/deal/get_deal_variant_stock?deal_id=${
    //         dealDetails.deal.id
    //       }&color=${selectedColor}&size=${"null"}`
    //     )
    //     .then((response) => {
    //       setDealVariantId(response.data.variant.id);
    //     })
    //     .catch((err) => "");
    // }
  }, [counter, selectedSize]);

  useEffect(() => {
    const getProductDetails = async () => {
      const response = await axios
        .get(`https://classibazaar.com.au/api/deal/detail/${productId}`)
        // .get(`https://classibazaar.com/api/deal/detail/${productId}`)
        .catch((err) => {
          "";
        });
      setDefaultChecked(response.data.products[0].id);
      setDealDetails(response.data);
      setProductDetails(response.data.deal);
      setProductImage(response.data.images);
      setProductIPrice(response.data.products);
      setDealType(response.data.deal.deal_type);
    };
    getProductDetails();

    dispatch({
      type: "GET_MY_WISHLIST_REQUEST",
    });
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });

    setLocalStorage("qty", counter);
  }, [productId]);

  const wishlistHander = () => {
    token
      ? dispatch({
          type: "ADD_TO_WISHLIST_REQUEST",
          payload: { id: productDetails.id },
        })
      : navigate("/login");
  };

  useEffect(() => {
    productDetails?.id &&
      dispatch({
        type: "GET_REVIEW_REQUEST",
        payload: {
          id: productDetails.id,
        },
      });
    dispatch({
      type: "SAVE_PREORDER_PAYMENT_INFO",
      payload: {},
    });
    dispatch({
      type: "CLEAR_PROMO",
    });
  }, [productDetails.id]);

  const isAlreadyInWishlist = () => {
    const isAlready = wishlist.myWishlists.find(
      (wishlist) => wishlist.id === productDetails.id
    );
    return isAlready;
  };

  const onPageChange = (page) => {
    setState((prev) => {
      return {
        ...prev,
        currentPage: page,
      };
    });
  };

  const onColorChangeHandler = async (color) => {
    setSelectedColor(color);
    const response = await axios
      .get(
        // `https://classibazaar.com.au/api/deal/deal_sizes?deal_id=${dealDetails.deal.id}&color=${color}`
        `/deal/deal_sizes?deal_id=${dealDetails.deal.id}&color=${color}`
      )
      .then((response) => {
        //  setSizes(response.data.product_sizes);
        response?.data?.product_sizes.length > 0
          ? setSizes(response.data.product_sizes)
          : toast.error("Selected product product is Out-of stock");
      })
      .catch((err) => "");
  };

  const validateHandler = async () => {
    if (!selectedColor || !selectedSize) {
      if (productDetails?.deal_type !== "Service") {
        toast.error("Please Select both size and color ");
        return false;
      }
    }
    //  else if (selectedSize != "empty") {
    //   if (productDetails?.deal_type !== "Service") {
    //     toast.error("Please Select the color ");
    //     return false;
    //   }
    // }
    else {
      return true;
    }
  };

  return (
    <>
      <Navbar />
      {wishlist.isLoading ? (
        <Spinner />
      ) : (
        <div className="product--container">
          <div className="productDetails" key={productDetails.id}>
            <div className="productDesc">
              <div className="productUp">
                <div className="productUp--detail--header">
                  <h2>{productDetails.dealstitle}</h2>
                  {rating > 0 && (
                    <span className="productUp--detail--rating">
                      <ReactStars
                        count={rating}
                        color={"#ffd700"}
                        isHalf={true}
                        edit={false}
                        size={24}
                        activeColor="#ffd700"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="productDown">
                <div>
                  <HomeOutlined className="icon" /> Shop 112 Joplin Lane
                  Opposite to Club lime Pool, Kambri ANU, ACTON, 2601
                </div>
                <div>
                  <TagsOutlined className="icon" /> {productDetails.subtitle}
                </div>
              </div>
            </div>
            <div className="productDetailInfo">
              <div className="left--product--container">
                <AutoplaySlider
                  className="auto--play--slider"
                  animation="cubeAnimation"
                  infinite={true}
                  play={true}
                  interval={2000}
                  style={{
                    borderRadius: "1rem",
                  }}
                >
                  {productImage.map((image, key) => (
                    <div className="productImg">
                      <img src={image.thumbnail} alt="" key={key} />
                    </div>
                  ))}
                </AutoplaySlider>

                <div className="productRightDown small-right">
                  <div className="limitedTime">
                    <p
                      style={{
                        fontWeight: 600,
                        color: "#ED2B2A",
                      }}
                    >
                      Offer Time Remaining
                    </p>
                    {dealDetails && (
                      <Countdown
                        date={
                          Date.now() +
                          moment(dealDetails.deal.endDate).diff(
                            moment(Date.now())
                          )
                        }
                        renderer={renderer}
                      />
                    )}
                  </div>
                  <div className="productDownButtons">
                    Quantity:
                    <div
                      // style={{ marginLeft: "0.8rem" }}
                      className="rectangle counter--button"
                    >
                      <MinusOutlined onClick={decCounter} />
                    </div>
                    <div className="rectangle value">{counter}</div>
                    <div className="rectangle counter--button">
                      <PlusOutlined onClick={incCounter} />
                    </div>
                  </div>

                  <div className="addToBtns">
                    {dealDetails && (
                      <div className="right--product--detail--layout--container">
                        {dealDetails.products.map((product, index) => (
                          <div
                            onClick={() =>
                              handleDefaultValue(product.id, index)
                            }
                            style={{
                              cursor: "pointer",
                            }}
                            key={product.id}
                            className={`right--product--detail--container ${
                              productContainerNo === index
                                ? "right-active"
                                : "right-not-active"
                            }`}
                          >
                            <div className="productDetailCart">
                              <div className="productDetailCart--header">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    lineHeight: "1.2",
                                  }}
                                >
                                  {product.product_name}
                                </span>
                                {/* <input
                                  type="radio"
                                  defaultChecked={
                                    product.id == defaultChecked ? true : false
                                  }
                                  checked={
                                    defaultChecked === product.id ? true : false
                                  }
                                  onChange={() => {
                                    setDefaultChecked(product.id);
                                    setProductContainerNo(index);
                                  }}
                                /> */}
                              </div>
                              <div className="productDetailPriceDesc">
                                <div className="product--details--cart--price">
                                  <span
                                    style={{
                                      color: "gray",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    ${" "}
                                    {(product.product_price * counter).toFixed(
                                      2
                                    )}
                                  </span>{" "}
                                  <div className="productDiscount">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        $
                                        {(
                                          product.price_after_discount * counter
                                        ).toFixed(2)}
                                      </span>
                                      <span
                                        style={{
                                          // background: "#40b56535",
                                          // color: "green",
                                          background:
                                            "var(--primary-color-light)",
                                          color: "black",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "2px 0.5rem",
                                          borderRadius: "0.5rem",
                                        }}
                                      >
                                        {product.discount}% off
                                      </span>
                                    </div>
                                    <span className="sold--quantity">
                                      {product.sold}+ Sold
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {dealDetails && (
                    <>
                      {dealDetails?.product_colors.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                          }}
                        >
                          Select Colors:
                          <div
                            style={{
                              marginBottom: "1rem",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                            }}
                          >
                            {dealDetails?.product_colors?.map((color) => {
                              return (
                                <p
                                  style={
                                    selectedColor == color
                                      ? {
                                          border: "2px solid green",
                                          padding: "6px",
                                          cursor: "pointer",
                                        }
                                      : {
                                          border: "none",
                                          padding: "6px",
                                          border: "1px solid gray",
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() => onColorChangeHandler(color)}
                                >
                                  {color}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {
                    sizes?.length > 0 && (
                      // {sizes?.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        Select Size:
                        <div
                          style={{
                            marginBottom: "1rem",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {sizes?.map((size) => {
                            return (
                              <p
                                style={
                                  selectedSize == size
                                    ? {
                                        border: "2px solid green",
                                        padding: "6px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        border: "none",
                                        padding: "6px",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => setSelectedSize(size)}
                              >
                                {size}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    )

                    // : (
                    //   setSelectedSize("empty")
                    // )
                  }
                </div>

                {productDetails.pre_order === "Yes" &&
                productStatus == "preorderProduct" ? (
                  <div className="productDetailsCheckoutBtn small-right small-right-button">
                    {dealType === "Service" ? (
                      <Link
                        className="link"
                        to={`/pre-order/${productDetails.deal_slug}/${defaultChecked}`}
                      >
                        <Button
                          style={{ color: "#fff", padding: "10px 5px" }}
                          onClick={validateHandler}
                        >
                          {" "}
                          <BsFillCartCheckFill
                            style={{ fontSize: "25px" }}
                          />{" "}
                          Pre-Order
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        className="link"
                        to={
                          selectedSize &&
                          `/pre-order/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                        }
                      >
                        <Button
                          style={{ color: "#fff", padding: "10px 5px" }}
                          onClick={validateHandler}
                        >
                          {" "}
                          <BsFillCartCheckFill
                            style={{ fontSize: "25px" }}
                          />{" "}
                          Pre-Order
                        </Button>
                      </Link>
                    )}
                  </div>
                ) : (
                  <div className="productDetailsCheckoutBtn small-right small-right-button">
                    {productDetails.deal_type === "Service" ? (
                      <Link
                        className="link"
                        to={`/payment/${productDetails.deal_slug}/${defaultChecked}`}
                      >
                        <Button
                          style={{ color: "#fff", padding: "10px 5px" }}
                          onClick={validateHandler}
                        >
                          Buy
                          {/* Continue To CheckOut */}
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        className="link"
                        to={
                          selectedSize &&
                          `/payment/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                        }
                      >
                        <Button
                          style={{ color: "#fff", padding: "10px 5px" }}
                          onClick={validateHandler}
                        >
                          Buy
                          {/* Continue To CheckOut */}
                        </Button>
                      </Link>
                    )}
                    {productDetails.deal_type === "Service" ? (
                      <Link
                        to={`/gift/${productDetails.deal_slug}/${defaultChecked}`}
                        className="link gift--style"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <AiOutlineGift />
                        Give as a Gift
                      </Link>
                    ) : (
                      <Link
                        to={
                          selectedSize &&
                          `/gift/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                        }
                        className="link"
                      >
                        {/* <Button
                          onClick={validateHandler}
                          style={{
                            backgroundColor: "#dc8b2e",
                            color: "#fff",
                            padding: "10px 5px",
                            marginTop: "1rem",
                          }}
                        >
                          Give as a Gift
                        </Button> */}

                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            alignItems: "center",
                            fontSize: "16px",
                            margin: "1rem 0 0.5rem",
                            color: "black",
                          }}
                          className="gift--style"
                          onClick={validateHandler}
                        >
                          <AiOutlineGift />
                          Give as a Gift
                        </span>
                      </Link>
                    )}
                  </div>
                )}

                <div className="wishlist--mobile--view">
                  {!isAlreadyInWishlist() ? (
                    <span
                      className="gift--style"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                        color: "black",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={wishlistHander}
                    >
                      <AiTwotoneHeart />
                      Add to wishlist
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                        color: "red",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    >
                      <AiTwotoneHeart />
                      Added to wishlist
                    </span>
                  )}
                </div>

                <div className="productHightlight highlight">
                  <h3>
                    Highlights <div className="header--underline"></div>
                  </h3>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "black",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productDetails.highlights,
                    }}
                  ></div>
                </div>
                <div className="productHightlight">
                  <h3>
                    Recent Reviews [{review.reviews.length}]{" "}
                    <div className="header--underline"></div>
                  </h3>
                  <div className="reviewContainer">
                    {review.reviews.length > 0 ? (
                      currentReviews.map((review, index) => (
                        <div className="review--container--cart">
                          <div className="review--cart--header">
                            <img
                              src={review.thumbnail}
                              style={{
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                              }}
                              alt=""
                            />
                            <div className="reviewer--info">
                              <span className="review--name">
                                {review.first_name + " " + review.last_name}
                              </span>
                              <span className="review--date">
                                {moment(review.created_at).format("DD/MM/YYYY")}
                              </span>
                            </div>
                            <ReactStars
                              count={review.review_rating}
                              color={"#ffd700"}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                          <div className="review--cart--body">
                            <span className="review--review">Review</span>
                            <span className="customer--review">
                              {review.reviews}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                      gap: "1rem",
                      lineHeight: "1.5",
                    }}
                  >
                    {state.currentPage > 1 && (
                      <Button
                        text="Show previous"
                        onClick={() => onPageChange(state.currentPage - 1)}
                      />
                    )}
                    {state.currentPage < review.reviews.length / 3 && (
                      <Button
                        text="Show Next"
                        onClick={() => onPageChange(state.currentPage + 1)}
                        style={{}}
                      />
                    )}
                  </div>
                </div>

                <div className="productHightlight">
                  <h3>
                    About This Deal <div className="header--underline"></div>
                  </h3>
                  <div
                    style={{
                      color: "black",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productDetails.description,
                    }}
                  ></div>
                </div>

                <div className="productHightlight">
                  <h3>
                    Fine Print <div className="header--underline"></div>
                  </h3>
                  <div
                    style={{
                      color: "black",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productDetails.find_print,
                    }}
                  ></div>
                </div>
                <div className="productHightlight hawa-p">
                  <h3>
                    How To Redeem <div className="header--underline"></div>
                  </h3>
                  <div
                    style={{
                      color: "black",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productDetails.how_to_redeem,
                    }}
                  ></div>
                </div>
                <div className="productHightlight">
                  <h3>
                    About The Company <div className="header--underline"></div>
                  </h3>
                  <div
                    style={{
                      color: "black",
                      lineHeight: "1.5",
                    }}
                  >
                    {dealDetails && dealDetails.company.description}
                  </div>
                </div>
              </div>
              <div className="productRight">
                <div className="productRightContainer">
                  <div className="productRightDown big-right">
                    <div className="limitedTime">
                      <p
                        style={{
                          fontWeight: 600,
                          color: "#ED2B2A",
                        }}
                      >
                        Offer Time Remaining
                      </p>
                      {dealDetails && (
                        <Countdown
                          date={
                            Date.now() +
                            moment(dealDetails.deal.endDate).diff(
                              moment(Date.now())
                            )
                          }
                          renderer={renderer}
                        />
                      )}
                    </div>
                    <div className="productDownButtons">
                      <span className="qty"> Quantity:</span>
                      <div className="qty--button">
                        <div className="rectangle counter--button ">
                          <MinusOutlined onClick={decCounter} />
                        </div>
                        <div className="rectangle value">{counter}</div>
                        <div className="rectangle counter--button ">
                          <PlusOutlined onClick={incCounter} />
                        </div>
                      </div>
                    </div>
                    <div className="addToBtns">
                      {/* {!isAlreadyInWishlist() ? (
                        <Button
                          style={{ color: "#fff", padding: "10px 5px" }}
                          onClick={wishlistHander}
                        >
                          Add to wishlist
                        </Button>
                      ) : (
                        <Badge.Ribbon
                          color={"red"}
                          style={{
                            position: "absolute",
                            top: "-5rem",
                            right: "-1.3rem",
                          }}
                          text={<HeartOutlined />}
                        ></Badge.Ribbon>
                      )} */}

                      {/* {dealDetails &&
                        dealDetails.products.map((product) => (
                          <div
                            key={product.id}
                            style={{
                              width: "100%",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              <Checkbox
                                style={{ color: "green" }}
                                defaultChecked={
                                  product.id == defaultChecked ? true : false
                                }
                                checked={
                                  defaultChecked === product.id ? true : false
                                }
                                onChange={() => {
                                  setDefaultChecked(product.id);
                                }}
                              />{" "}
                              <span
                                style={{ fontSize: "16px", fontWeight: 600 }}
                              >
                                {product.product_name}
                              </span>
                            </div>
                            <div className="product--details--cart">
                              <span className="product--details--cart--price">
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  $
                                  {(
                                    product.price_after_discount * counter
                                  ).toFixed(2)}
                                </span>
                                <span> {"-"} </span>
                                <span
                                  style={{
                                    color: "#ED2B2A",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  ${" "}
                                  {(product.product_price * counter).toFixed(2)}
                                </span>{" "}
                              </span>
                              <span
                                style={{
                                  background: "#FFB174",
                                  color: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px",
                                  width: "92px",
                                }}
                              >
                                {product.discount}% off
                              </span>
                              <span>{product.sold}+ Sold</span>
                            </div>
                          </div>
                        ))} */}

                      {dealDetails && (
                        <div className="right--product--detail--layout--container">
                          {dealDetails.products.map((product, index) => (
                            <div
                              key={product.id}
                              onClick={() =>
                                handleDefaultValue(product.id, index)
                              }
                              style={{
                                cursor: "pointer",
                              }}
                              className={`right--product--detail--container ${
                                productContainerNo === index
                                  ? "right-active"
                                  : "right-not-active"
                              }`}
                            >
                              <div className="productDetailCart">
                                <div className="productDetailCart--header">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      lineHeight: "1.2",
                                    }}
                                  >
                                    {product.product_name}
                                  </span>
                                  {/* <input
                                    type="radio"
                                    defaultChecked={
                                      product.id == defaultChecked
                                        ? true
                                        : false
                                    }
                                    checked={6 === product.id ? true : false}
                                    onChange={() => {
                                      setDefaultChecked(product.id);
                                      setProductContainerNo(index);
                                    }}
                                  /> */}
                                </div>
                                <div className="productDetailPriceDesc">
                                  <div className="product--details--cart--price">
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      ${" "}
                                      {(
                                        product.product_price * counter
                                      ).toFixed(2)}
                                    </span>{" "}
                                    <div className="productDiscount">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "0.5rem",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          $
                                          {(
                                            product.price_after_discount *
                                            counter
                                          ).toFixed(2)}
                                        </span>
                                        <span
                                          style={{
                                            // background: "#40b56535",
                                            // color: "green",
                                            background:
                                              "var(--primary-color-light)",
                                            color: "black",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "2px 0.5rem",
                                            borderRadius: "0.5rem",
                                          }}
                                        >
                                          {product.discount}% off
                                        </span>
                                      </div>
                                      <span className="sold--quantity">
                                        {product.sold}+ Sold
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {dealDetails && (
                      <>
                        {dealDetails?.product_colors?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              flexDirection: "column",
                            }}
                          >
                            Select Colors:
                            <div
                              style={{
                                marginBottom: "1rem",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                              }}
                            >
                              {dealDetails?.product_colors?.map((color) => {
                                return (
                                  <p
                                    style={
                                      selectedColor == color
                                        ? {
                                            border: "2px solid green",
                                            padding: "6px",
                                            cursor: "pointer",
                                          }
                                        : {
                                            border: "none",
                                            padding: "6px",
                                            border: "1px solid gray",
                                            cursor: "pointer",
                                          }
                                    }
                                    onClick={() => onColorChangeHandler(color)}
                                  >
                                    {color}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {sizes?.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        Select Size:
                        <div
                          style={{
                            marginBottom: "1rem",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {sizes?.map((size) => {
                            return (
                              <p
                                style={
                                  selectedSize == size
                                    ? {
                                        border: "2px solid green",
                                        padding: "6px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        border: "none",
                                        padding: "6px",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => setSelectedSize(size)}
                              >
                                {size}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {/* {productPrice.map((product) => (
                  <div className="productPriceDescount" key={product.id}>
                    <p className="discount">{product.discount}% off</p>

                    <div>
                      <p>
                        <del>${product.product_price}</del>
                      </p>
                      <p>${product.price_after_discount}</p>
                    </div>
                  </div>
                  ))} */}
                    {/* <div className="productDetailsRadio">
                  <input type="radio" />
                  <label>LG TV</label>
                 </div> */}

                    {/* <div className="limitedTime">
                    <p>Limited time remaining</p>
                    <h2>
                      {" "}
                      {dealDetails && (
                        <Countdown
                          date={
                            Date.now() +
                            moment(dealDetails.deal.endDate).diff(
                              moment(Date.now())
                            )
                          }
                        />
                      )}
                    </h2>
                  </div> */}
                  </div>
                  {productDetails.pre_order === "Yes" &&
                  productStatus == "preorderProduct" ? (
                    <div className="productDetailsCheckoutBtn big-right">
                      {dealType === "Service" ? (
                        <Link
                          className="link"
                          to={`/pre-order/${productDetails.deal_slug}/${defaultChecked}`}
                        >
                          <Button
                            style={{ color: "#fff", padding: "10px 5px" }}
                            onClick={validateHandler}
                          >
                            {" "}
                            <BsFillCartCheckFill
                              style={{ fontSize: "25px" }}
                            />{" "}
                            Pre-Order
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          className="link"
                          to={
                            selectedSize &&
                            `/pre-order/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                          }
                        >
                          <Button
                            style={{ color: "#fff", padding: "10px 5px" }}
                            onClick={validateHandler}
                          >
                            {" "}
                            <BsFillCartCheckFill
                              style={{ fontSize: "25px" }}
                            />{" "}
                            Pre-Order
                          </Button>
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div className="productDetailsCheckoutBtn big-right">
                      {productDetails.deal_type === "Service" ? (
                        <Link
                          className="link"
                          to={`/payment/${productDetails.deal_slug}/${defaultChecked}`}
                        >
                          <Button
                            style={{ color: "#fff", padding: "10px 5px" }}
                            onClick={validateHandler}
                          >
                            Buy
                            {/* Continue To CheckOut */}
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          className="link"
                          to={
                            selectedSize &&
                            `/payment/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                          }
                        >
                          <Button
                            style={{ color: "#fff", padding: "10px 5px" }}
                            onClick={validateHandler}
                          >
                            {/* Continue To CheckOut */}
                            Buy
                          </Button>
                        </Link>
                      )}
                      {productDetails.deal_type === "Service" ? (
                        <Link
                          to={`/gift/${productDetails.deal_slug}/${defaultChecked}`}
                          className="link gift--style"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            alignItems: "center",
                            margin: "1rem 0 0.5rem",
                            color: "black !important",
                            fontSize: "16px",
                          }}
                        >
                          <AiOutlineGift />
                          Give as a Gift
                        </Link>
                      ) : (
                        <Link
                          to={
                            selectedSize &&
                            `/gift/${productDetails.deal_slug}/${defaultChecked}/${dealVariantId}`
                          }
                          className="link"
                        >
                          {/* <Button
                            onClick={validateHandler}
                            style={{
                              backgroundColor: "#dc8b2e",
                              color: "#fff",
                              padding: "10px 5px",
                              marginTop: "1rem",
                            }}
                          >
                            Give as a Gift
                          </Button> */}
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.5rem",
                              alignItems: "center",
                              fontSize: "16px",
                              margin: "1rem 0 0.5rem",
                              color: "black",
                            }}
                            className="gift--style"
                            onClick={validateHandler}
                          >
                            <AiOutlineGift />
                            Give as a Gift
                          </span>
                        </Link>
                      )}
                    </div>
                  )}
                  <div className="wishlist--large--view">
                    {!isAlreadyInWishlist() ? (
                      <span
                        className="gift--style"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                          color: "black",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                        onClick={wishlistHander}
                      >
                        <AiTwotoneHeart />
                        Add to wishlist
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                          color: "red",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        <AiTwotoneHeart />
                        Added to wishlist
                      </span>
                    )}
                  </div>
                  {/* {dealDetails && (
                    <p style={{ marginTop: "25px" }}>
                      <div class="mapouter">
                        <div class="gmap_canvas">
                          <iframe
                            width="100%"
                            height="380"
                            id="gmap_canvas"
                            src={`https://maps.google.com/maps?q=${dealDetails.locations[0].lat},${dealDetails.locations[0].lng}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                          />
                        </div>
                      </div>
                    </p>
                   
                  )} */}
                  {dealDetails && isLoaded ? (
                    <div style={{ margin: "1rem 0" }}>
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        options={mapOptions}
                      >
                        <Marker
                          position={{
                            lat: parseFloat(dealDetails?.locations[0].lat),
                            lng: parseFloat(dealDetails?.locations[0].lng),
                          }}
                        />
                      </GoogleMap>
                    </div>
                  ) : null}

                  <div className="shareDeals">
                    <h2>Share This Deals</h2>
                    <div className="dealsIcon">
                      <FacebookShareButton
                        url={window.location.href}
                        children={<img src="/images/facebook.png" />}
                      />
                      <TelegramShareButton
                        url={window.location.href}
                        children={
                          <img
                            src="https://image.similarpng.com/very-thumbnail/2020/07/Telegram-circular-icon-vector-PNG.png"
                            alt=""
                          />
                        }
                      />
                      <TwitterShareButton
                        url={window.location.href}
                        children={<img src="/images/twitter.png" alt="" />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <FeatureContainer />
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    wishlist: state.wishlist,
    review: state.review,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(ProductDetails);
