import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import history from "./utils/history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <HistoryRouter history={history}>
    <GoogleOAuthProvider clientId="270382075216-fsloufdvgeplh7no1s266oc9h3dehmjb.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </HistoryRouter>,
  document.getElementById("root")
);
