import React, { useState } from "react";
import { Input } from "antd";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import "./UpdatePass.css";

const UpdatePassword = ({ profile }) => {
  const dispatch = useDispatch();
  const [userPassword, setUserPassword] = useState({
    current_password: "",
    password: "",
    password_confirm: "",
  });
  const { current_password, password, password_confirm } = userPassword;
  const onChangeHandler = (e) => {
    setUserPassword((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = () => {
    dispatch({
      type: "UPDATE_PASSWORD_REQUEST",
      payload: {
        current_password,
        password,
        password_confirm,
      },
    });
    setUserPassword({
      current_password: "",
      password: "",
      password_confirm: "",
    });
  };
  if (profile.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="update--password--section">
      <div className="update--password--container">
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>Change Password</p>

        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <span>Current Password:</span>
          <Input
            type="password"
            style={{
              height: "2.5rem",
              borderRadius: "0.5rem",
            }}
            name="current_password"
            value={current_password}
            onChange={(e) => onChangeHandler(e)}
          />
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <span>New Password:</span>
          <Input
            type="password"
            style={{
              height: "2.5rem",
              borderRadius: "0.5rem",
            }}
            name="password"
            value={password}
            placeholder="Enter New Password"
            onChange={(e) => onChangeHandler(e)}
          />
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <span>Confirm New Password:</span>
          <Input
            style={{
              height: "2.5rem",
              borderRadius: "0.5rem",
            }}
            type={"password"}
            name="password_confirm"
            value={password_confirm}
            placeholder="Re-Enter New Password"
            onChange={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="button--container">
          <Button
            className="update--password--button"
            text="Change Password"
            style={{ marginTop: "1rem" }}
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(UpdatePassword);
