import React from "react";
import Image1 from "../../components/Image";
import { Input } from "antd";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import Image from "../../components/Image";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const ChangePassword = ({ ForgotPassword }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = () => {
    if (!password || !confirmPassword) {
      return toast.error("Please fill all the feilds", { toastId: 108 });
    }
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
      payload: {
        email: JSON.parse(localStorage.getItem("verifyEmail")),
        code: JSON.parse(localStorage.getItem("verifyCode")),
        password: password,
        password_confirm: confirmPassword,
      },
    });
  };

  if (ForgotPassword.isPasswordChanged) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className="login-container">
      <Navbar />
      {ForgotPassword.isLoading ? (
        <Spinner />
      ) : (
        <div className="reset--password--sub--container">
          {/* <div className="login--header">
            <Image />
          </div> */}
          <div className="reset--password--container">
            <p className="title" style={{ marginTop: "-0.5rem" }}>
              Create New Password
            </p>
            <p className="subtitle" style={{ marginTop: "-1.5rem" }}>
              Your new password must be different from previous used password
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="">Password:</label>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
                type="password"
                style={{
                  height: "2.5rem",
                  width: "100%",
                  borderRadius: "0.5rem",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label htmlFor="">Confirm Password:</label>
              <Input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="confirm-password"
                type={"password"}
                style={{
                  height: "2.5rem",
                  width: "100%",
                  borderRadius: "0.5rem",
                }}
              />
            </div>
            <button
              className="change--password--button"
              onClick={changePassword}
            >
              Reset Password
            </button>
            {/* <Button text="Reset Password" onClick={changePassword} /> */}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ForgotPassword: state.ForgotPassword,
  };
};

export default connect(mapStateToProps, {})(ChangePassword);
