import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FeatureContainer from "../../components/Feature/FeatureContainer";
import { BiHeart } from "react-icons/bi";
import "./Wishlist.css";
import { ImCross } from "react-icons/im";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Button from "../../components/Button/Button";
import { useEffect } from "react";
import Spinner from "../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import { Result } from "antd";
import { MdDelete } from "react-icons/md";

const Wishlist = ({ wishlist }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_MY_WISHLIST_REQUEST",
    });
  }, []);

  const deleteWishlist = (id) => {
    dispatch({
      type: "DELETE_WISHLIST_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  return (
    <div>
      <Navbar />
      {wishlist.isLoading ? (
        <Spinner />
      ) : (
        <div className="wishlist--main--container">
          <div className="wishlist-container">
            {wishlist.myWishlists.length > 0 ? (
              <>
                {" "}
                <div className="top">
                  <BiHeart className="icon" />
                  <span>My Wishlists</span>
                </div>
                <div className="below">
                  <div className="item item--header">
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#404040",
                        marginLeft: "0.5rem",
                      }}
                      className="twos"
                    >
                      PRODUCT IMAGE{" "}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#404040",
                      }}
                      className="item--small--header"
                    >
                      PRODUCT DESCRIPTION
                    </div>
                    <div
                      className="threes item--large--header"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#000",
                      }}
                    >
                      PRODUCT NAME{" "}
                    </div>

                    <div
                      className="fours item--large--header"
                      style={{ fontWeight: "bold" }}
                    >
                      UNIT PRICE{" "}
                    </div>
                    <div
                      className="fives item--large--header"
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                    >
                      DISCOUNT
                    </div>
                    <div
                      className="sixs item--large--header"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#404040",
                      }}
                    >
                      STOCK STATUS{" "}
                    </div>
                    <div className="ones item--large--header"></div>

                    <div className="sevens"></div>
                  </div>
                  {wishlist.myWishlists.map((wishlist) => {
                    return (
                      <>
                        <div className="item">
                          <div className="twos">
                            <Link
                              to={`/details/${wishlist.deal_slug}/${wishlist.rating}`}
                            >
                              <img
                                className="img"
                                src={wishlist.image.image_name}
                              />
                            </Link>
                          </div>
                          <div className="threes item--large--header">
                            {wishlist.product.product_name}
                          </div>
                          <div className="item--small--header">
                            <span>{wishlist.product.product_name}</span>
                            <span>
                              $
                              {(
                                (wishlist.product.discount *
                                  wishlist.product.product_price) /
                                100
                              ).toFixed(2)}
                            </span>
                            <span>
                              {wishlist.status === "1" ? (
                                <>
                                  <TiTick style={{ fontSize: "20px" }} /> In
                                  Stock
                                </>
                              ) : (
                                <p>Not in Stock</p>
                              )}
                            </span>
                          </div>
                          <div className="fours item--large--header">
                            ${wishlist.product.price_after_discount}
                          </div>
                          <div className="fives item--large--header">
                            $
                            {(
                              (wishlist.product.discount *
                                wishlist.product.product_price) /
                              100
                            ).toFixed(2)}
                          </div>

                          <div className="sixs item--large--header">
                            {" "}
                            {wishlist.status === "1" ? (
                              <>
                                <TiTick style={{ fontSize: "20px" }} /> In Stock
                              </>
                            ) : (
                              <p>Not in Stock</p>
                            )}
                          </div>
                          <div className="ones  item--large--header">
                            <MdDelete
                              className="wishlist--delete"
                              onClick={() => {
                                deleteWishlist(wishlist.id);
                              }}
                            />
                          </div>
                          <div className="sevens">
                            <Link
                              to={`/details/${wishlist.deal_slug}/${wishlist.rating}`}
                            >
                              <Button
                                text="Purchase"
                                style={{ width: "120px" }}
                              >
                                <BsCreditCard2BackFill className="pur" />
                              </Button>
                            </Link>
                            <Button
                              onClick={() => {
                                deleteWishlist(wishlist.id);
                              }}
                              className="on-mob"
                              style={{ marginTop: "10px", width: "120px" }}
                            >
                              <ImCross
                                style={{ color: "red" }}
                                className="icon"
                              />
                              Remove
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <Result
                status="404"
                subTitle="Sorry, there aren't any wishlists associated with this account."
                extra={
                  <Link to={"/"}>
                    {" "}
                    <Button type="primary" style={{ margin: "auto" }}>
                      Back To Home
                    </Button>
                  </Link>
                }
              />
            )}
          </div>
        </div>
      )}

      <FeatureContainer />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wishlist: state.wishlist,
  };
};

export default connect(mapStateToProps, {})(Wishlist);
