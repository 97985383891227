const initialState = {
  results: [],
  isLoading: false,
  error: {},
  response: "",
  totalPage: 1,
  saveData: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SEARCH_PRODUCT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEARCH_CATEGORY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEARCH_PRODUCT_SUCCESS":
      return {
        ...state,
        results: payload.products,
        response: payload,
        isLoading: false,
        totalPage: payload.total_pages,
      };
    case "SEARCH_PRODUCT_FAILURE":
      return {
        ...state,
        error: payload.response,
        isLoading: false,
      };
    case "SAVE_DATA":
      return {
        ...state,
        saveData: payload,
      };
    default:
      return state;
  }
}
