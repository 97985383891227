import React, { useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";
import "./Spinner.css";

const Spinner = (props) => {
  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        height: "40vh",
        width: "100%",
        margin: "0 auto",
      }}
    >
      <div
        className="loader--container"
        style={{
          height: "-webkit-fill-available",
          width: "100%",
          margin: "0 auto",
          // zIndex: "99999",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15%",
            backgroundColor: "tra",
          }}
        >
          <BallTriangle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
            height="80"
            width="80"
            color="#40B566"
            ariaLabel="loading"
          />
        </div>
      </div>
      <div
        style={{
          height: "20vh",
          width: "100%",
        }}
      ></div>
    </div>
  );
};

export default Spinner;
// <div style={{ height: "100vh", width: "100%", background: "none" }}>
//     <BallTriangle
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//       height="100"
//       width="100"
//       color="#40B566"
//       ariaLabel="loading"
//     />
//   </div>
